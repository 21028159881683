import { SET_USER_ROLE, SET_USER_ID, SET_USER_NAME, SET_JWTTOKEN ,SET_O_PHOTO } from "./actionTypes";

const initialState = {
    userRole: null,
    userId:null,
    userName:null,
    jwtToken:null,
    o_photo:null,
  };

  const roleReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_ROLE:
        return {
          ...state,
          userRole: action.payload,
        };

        case SET_USER_ID:
            return {
              ...state,
              userId: action.payload,
            };
    
            case SET_USER_NAME:
              return {
                ...state,
                userName: action.payload,
              };
      
              case SET_JWTTOKEN:
                return {
                  ...state,
                  jwtToken: action.payload,
                };
      
              case SET_O_PHOTO:
                return {
                  ...state,
                  o_photo: action.payload,
                };
      default:
        return state;
    }
  };
  
  export default roleReducer;