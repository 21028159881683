import React, { useEffect, useState } from 'react';

import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import { BASE_URL } from '../../components/Common/AppConfig';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import SetAlert from '../../components/Common/SetAlert';
const RevenueAnalytics = () => {
  const [eventData, setEventData] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userRole = useSelector((state) => state.roleReducer.userRole);
  const userId = useSelector((state) => state.roleReducer.userId);

  useEffect(() => {
    fetchEventCount();
  }, []);

  const fetchEventCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/event-graph`);
      const data = await response.json();
      if (response.ok) {
        if (userRole === 'user') {

          const userEvent = data.data.eventDetails.find((event) => event._id === userId);

          if (userEvent) {
            setEventData(userEvent.event);
          } else {
            // Display some default data or handle the null case as needed
            setEventData([]);
          }
        } else {
          setEventData(data.data.eventDetails);
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const upcomingMonth = (currentMonth + 1) % 12;
  const previousMonth = (currentMonth - 1 + 12) % 12;
  const eventsThisMonth = eventData.filter((event) => {
    const eventDate = new Date(event.eventDate);
    return eventDate.getMonth() === currentMonth && eventDate.getFullYear() === currentYear;
  });
  const eventsUpcomingMonth = eventData.filter((event) => {
    const eventDate = new Date(event.eventDate);
    return eventDate.getMonth() === upcomingMonth && eventDate.getFullYear() === currentYear;
  });
  const eventsPreviousMonth = eventData.filter((event) => {
    const eventDate = new Date(event.eventDate);
    return eventDate.getMonth() === previousMonth && eventDate.getFullYear() === currentYear;
  });

  const eventCountThisMonth = eventsThisMonth.length;
  const eventCountUpcomingMonth = eventsUpcomingMonth.length;
  const eventCountPreviousMonth = eventsPreviousMonth.length;

  const percentageIncrease = ((eventCountThisMonth - eventCountPreviousMonth) / eventCountThisMonth) * 100;
  const percentageChanged = ((eventCountUpcomingMonth - eventCountThisMonth) / eventCountThisMonth) * 100;

  const eventNames = eventData.map((event) => event.eventName);
  const eventCountsByMonth = eventData.reduce((acc, event) => {
    const date = new Date(event.eventDate);
    const monthYear = date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' });
    acc[monthYear] = (acc[monthYear] || 0) + 1;
    return acc;
  }, {});

  const earliestDate = new Date(Math.min(...eventData.map((event) => new Date(event.eventDate))));
  const latestDate = new Date(Math.max(...eventData.map((event) => new Date(event.eventDate))));

  const getMonthsBetweenDates = (startDate, endDate) => {
    const months = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      months.push(currentDate.toLocaleDateString(undefined, { month: 'short', year: 'numeric' }));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return months;
  };

  const labels = getMonthsBetweenDates(earliestDate, latestDate);

  const series = [
    {
      name: 'Total Event',
      type: 'column',
      data: labels.map((label) => eventCountsByMonth[label] || 0),
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
    },
    stroke: {
      width: [0, 3],
      curve: 'smooth',
    },
    xaxis: {
      categories: labels,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: '#004A99', // Color for the bars
            },
            {
              from: 101,
              to: 200,
              color: '#1cbb8c', // Color for the bars
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {userRole === 'admin' ? (
            <h4 className="card-title mb-4">Registered User</h4>
          ) : (
            <h4 className="card-title mb-4">Events</h4>
          )}
          <div>
            <div id="line-column-chart" className="apex-charts" dir="ltr">
              <ReactApexChart options={options} series={series} type="line" height="280" />
            </div>
          </div>
        </CardBody>
        <CardBody className="border-top text-center">
          <Row>
            <Col sm={4}>
              <div className="d-inline-flex">
                <h5 className="me-2">{eventCountThisMonth}</h5>
                <div className={isFinite(percentageIncrease) && !isNaN(percentageIncrease) ? (percentageIncrease >= 0 ? 'text-success' : 'text-danger') : 'text-muted'}>
                  <i className={isFinite(percentageIncrease) && !isNaN(percentageIncrease) ? (percentageIncrease >= 0 ? 'mdi mdi-menu-up font-size-14' : 'mdi mdi-menu-down font-size-14') : ''}> </i>
                  {isFinite(percentageIncrease) && !isNaN(percentageIncrease) ? Math.abs(percentageIncrease).toFixed(1) + ' %' : ''}
                </div>
              </div>

              <p className="text-muted text-truncate mb-0">This month</p>
            </Col>

            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-primary font-size-10 me-1"></i> Upcoming Month :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0 me-2">{eventCountUpcomingMonth}</h5>
                  <div className={isFinite(percentageChanged) && !isNaN(percentageChanged) ? (percentageChanged >= 0 ? 'text-success' : 'text-danger') : 'text-muted'}>
                    <i className={isFinite(percentageChanged) && !isNaN(percentageChanged) ? (percentageChanged >= 0 ? 'mdi mdi-menu-up font-size-14' : 'mdi mdi-menu-down font-size-14') : ''}> </i>
                    {isFinite(percentageChanged) && !isNaN(percentageChanged) ? Math.abs(percentageChanged).toFixed(1) + ' %' : ''}
                  </div>
                </div>

              </div>
            </Col>
            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Month :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0">{eventCountPreviousMonth}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RevenueAnalytics;
