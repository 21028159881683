import { Card, CardHeader, CardBody, CardTitle, Row, Col, Input, Button } from "reactstrap";
import React, { useEffect, useState, useRef, useLayoutEffect, forwardRef } from "react";
import { BASE_URL } from "../../components/Common/AppConfig";
import SetAlert from "../../components/Common/SetAlert";
import "flatpickr/dist/themes/material_blue.css";
import { useSelector } from "react-redux";
import { setConversationMaster, setOrganiserMaster, } from "../../store/Conversation/action";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { debounce } from 'lodash';
import { Scrollbars } from "react-custom-scrollbars";
import EmojiPicker from 'emoji-picker-react';
import pdf from '../../assets/images/pdf.png';
import video from '../../assets/images/video.png';
function ChatMaster() {
  const [userDetails, setUserDetails] = useState([]);
  const [organiserDetails, setOrganiserDetails] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: "", error: "" });
  const [selectedConversationName, setSelectedConversationName] = useState("");
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [message, setMessage] = useState([]);
  const [userConversations, setUserConversations] = useState({});
  const [openConversations, setOpenConversations] = useState([]);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.roleReducer.userId);
  const conversationId = useSelector((state) => state.conversationMasterReducer.conversationMaster);
  const organiserId = useSelector((state) => state.conversationMasterReducer.organiserMaster);
  const [socket, setSocket] = useState(null);
  const messageContainerRef = useRef(null);
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePreview, setSelectedFilePreview] = useState(null);
  const [formData, setFormData] = useState({
    conversationMaster: '',
    sender: '',
    text: '',
    senderFile: '',
    receiver: '',
    socketId: '',
  });
  useEffect(() => {
    const newSocket = io("ws://localhost:5050");
    newSocket.on("connect", () => {
      setSocket(newSocket);
    });
    return () => {
      newSocket.disconnect();
    };
  }, []);


  useEffect(() => {
    if (socket) {

      const handleConversationData = (data) => {

        if (data.status) {
          const masterMessages = data.data.master;
          console.log(masterMessages)
          setConversations(masterMessages);
          scrollToBottom();
        } else {
          console.error('Error fetching conversation:', data.error);
        }
      };

      const handleNewMessage = (message) => {

        const messageExists = conversations.some(
          (existingMessage) => existingMessage._id === message._id
        );

        if (!messageExists) {
          if (message.conversationMaster === selectedConversation) {
            setConversations((prevConversations) => [...prevConversations, message]);
            scrollToBottom(); // Scroll to the bottom after a new message is received
          }
        }
      };

      socket.on('conversation_data', handleConversationData);
      socket.on('new_message', handleNewMessage);

      return () => {
        socket.off('conversation_data', handleConversationData);
        socket.off('new_message', handleNewMessage);
      };
    }
  }, [socket, conversations, selectedConversation]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [conversations]);

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom after conversations are loaded
  }, []);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const container = messageContainerRef.current;
      container.scrollTop = container.scrollHeight - container.clientHeight;

      console.log(container);
    }
  };



  const fetchConversation = (conversationMasterId) => {
    console.log(conversationMasterId);
    if (socket) {
      socket.emit('user_connected', userId);
      socket.emit('get_conversation', conversationMasterId, userId, organiserId);
    }
  };

  useEffect(() => {
    fetchConversation('yourConversationMasterId');
  }, []);
  useEffect(() => {
    fetchAttendeeDetails();
  }, []);

  useEffect(() => {
    fetchOrganiserDetails();
  }, [userDetails]);


  const fetchAttendeeDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/conversation`);
      const data = await response.json();
      if (response.ok) {
        const filteredData = data.data.filter((conversation) => {
          return (
            conversation.members.includes(userId) ||
            (conversation.master &&
              conversation.master.some((event) => event.sender === userId))
          );
        });

        const filteredDataWithIdsAndMembers = filteredData.map(
          (conversation) => ({
            _id: conversation._id,
            members: conversation.members.filter(
              (memberId) => memberId !== userId
            ),
          })
        );
        setUserDetails(filteredDataWithIdsAndMembers);

        fetchOrganiserDetails();
      } else {
        setAlertMessage({
          success: "",
          error: "Failed to fetch event details.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOrganiserDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/organiser`);
      const data = await response.json();

      if (response.ok) {
        const member = userDetails.flatMap((data) => ({
          conversationId: data._id,
          member: data.members,
        }));

        const organiserIds = data.data.map((organiser) => ({
          _id: organiser._id,
          fname: organiser.fname,
          lname: organiser.lname,
        }));

        const commonOrganisers = organiserIds
          .filter((organiser) =>
            member.some((commonId) => commonId.member.includes(organiser._id))
          )
          .map((organiser) => {
            const correspondingMember = member.find((commonId) =>
              commonId.member.includes(organiser._id)
            );
            return {
              _id: organiser._id,
              fname: organiser.fname,
              lname: organiser.lname,
              conversationMaster: correspondingMember
                ? correspondingMember.conversationId
                : null,
            };
          });

        if (commonOrganisers.length > 0) {
          setOrganiserDetails(commonOrganisers);
        } else {
          setOrganiserDetails([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessage = async (text, organiserId, senderFile) => {
    try {
      console.log('senderFile:', senderFile);

      // if (text === null || text.trim() === "") {
      //   console.log("Please enter text");
      //   return;
      // }

      const form = new FormData();
      form.append("conversationMaster", conversationId);
      form.append("sender", userId);
      form.append("text", text);
      form.append("receiver", organiserId);
      form.append("socketId", socket.id);

      if (senderFile) {
        form.append("senderFile", senderFile);
      }

      // Log the form data
      for (const [key, value] of form.entries()) {
        console.log(key, value);
      }

      const response = await fetch(`${BASE_URL}/messages`, {
        method: "POST",
        body: form,
      });

      if (!response.ok) {
        console.error("Failed to send message. Response status:", response.status);
        const errorData = await response.json();
        console.error("Error data:", errorData);
        return;
      }

      if (socket) {
        socket.emit('user_connected', userId);
        socket.emit('new_message', {
          socketId: socket.id,
          conversationMaster: conversationId,
          sender: userId,
          text: text,
          senderFile: senderFile,
          receiver: organiserId,
        });
        console.log('Socket connected:', socket.id);
      }

      setSelectedConversationName("");
    } catch (error) {
      console.error(error);
    }
  };


  const handleOrganiserClick = (organiser) => {
    console.log("Clicked organiser:", organiser);
    const conversationId = organiser.conversationMaster;
    setSelectedConversation(conversationId);
    fetchConversation(conversationId);
    dispatch(setOrganiserMaster(organiser._id));
    dispatch(setConversationMaster(conversationId));
  };
  const toggleEmojiPicker = () => {
    setEmojiPickerVisible((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setSelectedEmoji(emoji);
    setSelectedConversationName(prevName => prevName + emoji); // Append emoji to the input field
    toggleEmojiPicker(); // Close the emoji picker
  };

  const attachFile = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*, .pdf, .doc, .docx, .mp4, .avi, .mov";
    fileInput.style.display = "none";

    fileInput.addEventListener("change", (event) => {
      const selectedFile = event.target.files[0];
      setSelectedFile(selectedFile);

      if (selectedFile) {
        const fileReader = new FileReader();

        if (selectedFile.type.startsWith("image/")) {
          fileReader.onload = (e) => {
            setSelectedFilePreview(e.target.result);
          };
          fileReader.readAsDataURL(selectedFile);
        } else if (selectedFile.type === "application/pdf") {

          fileReader.onload = (e) => {
            setSelectedFilePreview(pdf);
          };
          fileReader.readAsDataURL(selectedFile);
        } else if (selectedFile.type.startsWith("video/")) {

          fileReader.onload = (e) => {
            setSelectedFilePreview(video);
          };
          fileReader.readAsDataURL(selectedFile);
        } else {
          setSelectedFilePreview(null);
        }
        sendMessage(selectedConversationName, organiserId, selectedFile);
      }
    });

    fileInput.click();
  };



  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Attendees Details</CardTitle>
            </CardHeader>
            <CardBody
              style={{
                height: "70vh",
                maxHeight: "100%",
                //  overflow:"auto"
              }}
            >
              <div className="grid-container">
                <div className="row">
                  <div className="col-4" style={{ alignSelf: "flex-start" }}>
                    {organiserDetails.map((organiser, _id) => (
                      <p
                        key={_id}
                        onClick={() => handleOrganiserClick(organiser)}
                        style={{ cursor: "pointer" }}
                      >
                        {organiser.fname+ ' '+organiser.lname}
                      </p>
                    ))}
                  </div>

                  <div className="col-8" style={{ overflow: "hidden", height: "100%" }}>
                    <CardBody style={{ height: "68vh", paddingRight: "0", paddingTop: "0" }}>
                      <div style={{ overflow: "hidden", height: "100%" }}>
                        <div
                          style={{
                            paddingBottom: "10px",
                            overflow: "auto",
                            height: "85%",
                            scrollbarWidth: "thin",
                            scrollbarColor: "#ffffff",
                            scrollMarginRight: "10px",
                          }}
                          ref={messageContainerRef}
                        >
                          <div style={{ marginRight: "15px" }}>
                            {Array.isArray(conversations) && conversations.length > 0 ? (
                              conversations.map((message, _id) => (
                                <div
                                  key={_id}
                                  style={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    display: "inline-block",
                                    maxWidth: "70%",
                                    backgroundColor:
                                      message.sender === userId ? "#007bff" : "#f0f0f0",
                                    color: message.sender === userId ? "white" : "black",
                                    float: message.sender === userId ? "right" : "left",
                                    clear: "both",
                                    paddingBottom: "0",
                                  }}
                                >
                                  
                                    <p>{message.text}</p>
                                  {message.senderFile && (
                                    <div>
                                    <img
                                      src={message.senderFile}
                                      alt="Sender File"
                                      style={{
                                        maxWidth: "100px",
                                        maxHeight: "100px",
                                        marginRight: "10px",
                                        marginBottom: "5px"
                                      }}
                                    />
                                    <a
                                      href={message.senderFile}
                                      download
                                      style={{
                                        display: "block",
                                        fontSize: "12px",
                                        textDecoration: "underline",
                                        color: "#007bff",
                                      }}
                                    >
                                      Download
                                    </a>
                                  </div>
                                  
                                  )}
                                </div>
                              ))
                            ) : (
                              <p>No messages available.</p>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            bottom: 0,
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "#fff",
                            padding: "5px",
                            position: "relative", // Add this positioning
                          }}
                        >

                          <div style={{ position: "relative" }}>
                            {selectedFilePreview && (
                              <div
                                className="image-preview"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginBottom: "10px",
                                  position: "absolute",
                                  zIndex: 2,
                                  top: "-20vh",
                                }}
                              >
                                <img
                                  src={selectedFilePreview}
                                  alt="Attachment Preview"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    marginRight: "10px",
                                  }}
                                />
                                <p>{selectedFile.name}</p>
                                <button
                                  onClick={() => {
                                    setSelectedFile(null);
                                    setSelectedFilePreview(null);
                                    setSelectedConversationName("");

                                  }}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    color: "red",
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                >
                                  &#10005;
                                </button>
                              </div>
                            )}
                          </div>
                          <Input
                            type="text"
                            placeholder={selectedFile ? selectedFile.name : "Type your message..."}
                            value={selectedConversationName}
                            onChange={(e) => setSelectedConversationName(e.target.value)}
                            style={{
                              marginRight: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "5px",
                              flex: 1,
                              zIndex: 1, // Lower z-index for the input
                            }}
                          />


                          <button
                            onClick={attachFile}
                            style={{
                              background: "#007bff",
                              border: "none",
                              color: "#fff",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                          >
                            Attach File
                          </button>
                          <button
                            onClick={toggleEmojiPicker}
                            style={{
                              background: isEmojiPickerVisible ? "#f0f0f0" : "#007bff",
                              border: "none",
                              color: isEmojiPickerVisible ? "#000" : "#fff",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                          >
                            Emoji
                          </button>

                          {isEmojiPickerVisible && (
                            <div
                              style={{
                                position: "absolute",
                                top: "-40vh",
                                right: "10vh",
                                zIndex: 1,
                                height: "40vh",
                                overflow: "hidden",
                              }}
                              autoFocusSearch={false}
                              searchPlaceholder={false}
                            >
                              <EmojiPicker
                                onEmojiClick={handleEmojiClick}
                                disableSkinTonePicker={true}
                                disableFrequentlyUsed={true}
                                pickerStyle={{
                                  height: "100%",
                                  maxHeight: "100%",
                                  overflowY: "auto",
                                }}
                                autoFocusSearch={false}
                                searchPlaceholder={false}
                              />
                            </div>
                          )}
                          <button
                            onClick={() => sendMessage(selectedConversationName, organiserId)}
                            style={{
                              background: "#007bff",
                              border: "none",
                              color: "#fff",
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1="22" y1="2" x2="11" y2="13"></line>
                              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                            </svg>
                          </button>
                        </div>

                      </div>
                    </CardBody>
                  </div>

                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ChatMaster;
