import React from 'react';
import { ButtonDropdown, Button, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ActionsDropdown = ({ row, toggleDropdown, handleAction, isActive, showEdit, showCheckedIn, isScanned }) => {
  return (
    <ButtonDropdown direction="up" isOpen={row.dropdownOpen} toggle={() => toggleDropdown(row)}>
      <Button id="caret" color="primary" size="sm" className="custom-button">
        Actions
      </Button>
      <DropdownToggle caret color="primary" size="sm" className="custom-caret">
        <i className="mdi mdi-chevron-up"></i>
      </DropdownToggle>
      <DropdownMenu className="custom-dropdown-menu">
        {showCheckedIn ? (
          isScanned ? (<DropdownItem onClick={() => handleAction('checkedout', row)}>Checked-Out</DropdownItem>) : (
            <DropdownItem onClick={() => handleAction('checkedin', row)}>Checked-In</DropdownItem>
          )
        ) : null}
    {isActive ? (
          <DropdownItem onClick={() => handleAction('deactive', row)}>Deactivate</DropdownItem>
        ) : (
          <DropdownItem onClick={() => handleAction('active', row)}>Activate</DropdownItem>
        )}
        {showEdit ? (
          <DropdownItem onClick={() => handleAction('edit', row)}>Edit</DropdownItem>
        ) : null}
        <DropdownItem onClick={() => handleAction('delete', row)}>Delete</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default ActionsDropdown;
