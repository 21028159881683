import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import ForgetPwd from "../pages/Authentication/ForgetPassword";


// Dashboard
import Dashboard from "../pages/Dashboard/index";

import UserMaster from "../pages/User Master/UserMaster";

import OrganiserMaster from "../pages/Organiser Master/OrganiserMaster";

import CategoryMaster from "../pages/Category Master/CategoryMaster";

import EventMaster from "../pages/Event Master/EventMaster";

import SpeakersMaster from "../pages/Speaker Master/SpeakerMaster";

import AgendaMaster from "../pages/Agenda Master/AgendaMaster";

import SponsorMaster from "../pages/Sponsor Master/SponsorMaster";

import TagsMaster from "../pages/Tags Master/TagsMaster";

import UserTagMaster from "../pages/UserTag Master/UserTagMaster";

import PollMaster from "../pages/Poll Master/PollMaster";

import DynamicForm from "../pages/Dynamic Form/DynamicForm";

import Notification from "../pages/Notification Master/NotificationMaster";

import EnquriesMaster from "../pages/Enquries Master/EnquriesMaster";


import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";
import PasswordMaster from "../pages/Password Master/PasswordMaster";
import AttendeesMaster from "../pages/Attendees Master/AttendeesMaster";
import ChatMaster from "../pages/Chat Master/ChatMaster";
import WelcomeMaster from "../pages/Welcome Master/WelcomeMaster";


const authProtectedRoutes = [
	{ path: "/icons-remix", component: RemixIcons },
	{ path: "/material-design", component: MaterialDesign },
	{ path: "/dripicons", component: DripiIcons },
	{ path: "/font-awesome-5", component: FontAwesome },
	{ path: "/dashboard", component: Dashboard },

	{ path: "/usermaster", component: UserMaster },

	{ path: "/organiser", component: OrganiserMaster },

	{ path: "/category", component: CategoryMaster },

	{ path: "/events", component: EventMaster },

	{ path: "/speaker", component: SpeakersMaster },

	{ path: "/agenda", component: AgendaMaster },

	{ path: "/sponsor", component: SponsorMaster },

	{ path: "/tags", component: TagsMaster },

	{ path: "/usertags", component: UserTagMaster },

	{ path: "/poll", component: PollMaster },

	{ path: "/dynamicform", component: DynamicForm },

	{ path: "/password", component: PasswordMaster },

	{ path: "/notification", component: Notification },

	{ path: "/enquries", component: EnquriesMaster },

	{ path: "/attendees", component: AttendeesMaster },

	{ path: "/chat", component: ChatMaster },
	{ path: "/welcome", component: WelcomeMaster },

	];

	const authProtectedUserRoutes = [
		{ path: "/dashboard", component: Dashboard },
	
		{ path: "/category", component: CategoryMaster },
	
		{ path: "/events", component: EventMaster },
	
		{ path: "/speaker", component: SpeakersMaster },
	
		{ path: "/agenda", component: AgendaMaster },
	
		{ path: "/sponsor", component: SponsorMaster },
	
		{ path: "/poll", component: PollMaster },
	
		{ path: "/notification", component: Notification },
		{ path: "/welcome", component: WelcomeMaster },

		{ path: "/dynamicform", component: DynamicForm },

		{ path: "/password", component: PasswordMaster },

		{ path: "/attendees", component: AttendeesMaster },

		{ path: "/chat", component: ChatMaster },
	
		];
const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },


	
];

export { authProtectedRoutes, authProtectedUserRoutes, publicRoutes };
