import { SET_CONVERSATION_MASTER, SET_ORGANISER_MASTER} from "./actionTypes";


export const setConversationMaster = (conversationMaster) => ({
    type: SET_CONVERSATION_MASTER,
    payload: conversationMaster,
  });

  export const setOrganiserMaster = (organiserMaster) => ({
    type: SET_ORGANISER_MASTER,
    payload: organiserMaster,
  });
