import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import Select from "react-select";

function PollMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [speakerDetails, setSpeakerDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [eventData, setEventData] = useState([]);
    const [agendaDataFetch, setAgendaDataFetch] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenquestion, setIsModalOpenquestion] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [selectedRowoptionA, setSelectedRowoptionA] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState(null);

    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);
    const token=useJwtToken();

    const [selectedRowquestion, setSelectedRowquestion] = useState('');
    const handleOpenModal = (row) => {
        setSelectedRowoptionA(row.optionA);
        setIsModalOpen(true);
    };

    const handleOpenModalquestion = (row) => {
        setSelectedRowquestion(row.question);
        setIsModalOpenquestion(true);
    };

    const handleCloseModalquestion = () => {
        setIsModalOpenquestion(false);
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            eventMaster: '',
            agendaMaster: '',
            question: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        eventMaster: '',
        agendaMaster: '',
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const requestBody = {
                eventMaster: formData.eventMaster,
                agendaMaster: formData.agendaMaster,
                question: formData.question,
                optionA: formData.optionA,
                optionB: formData.optionB,
                optionC: formData.optionC,
                optionD: formData.optionD,
            };

            const response = await fetch(`${BASE_URL}/poll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    eventMaster: '',
                    agendaMaster: '',
                    question: '',
                    optionA: '',
                    optionB: '',
                    optionC: '',
                    optionD: '',
                });
                toggleAddModal();
                fetchPollDetails();
                history.push('/poll');
                setAlertMessage({ success: 'Poll added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the poll.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the poll.' });
        } finally {
            setSubmitting(false);
        }
    };

    const fetchEventData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/event`);
            if (response.ok) {
                const data = await response.json();
                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    setEventData(filteredData);
                } else {
                    setEventData(data.data);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    const fetchAgendaData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/agenda`);
            if (response.ok) {
                const data = await response.json();

                if (userRole === 'user') {
                    const filteredData = data.data.filter(agenda => {
                        return agenda.eventDetails.some(event => event.organiserMaster === userId);
                    });
                    setAgendaDataFetch(filteredData);
                    filteredData.forEach(item => {
                        item.agendaData.forEach(agendaItem => {
                        });
                    });
                } else {
                    setAgendaDataFetch(data.data);
       }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch category details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching category data' });
            console.error(error);
        }
    };

    useEffect(() => {
        fetchPollDetails();
        fetchEventData();
        fetchAgendaData();
    }, []);

    const fetchPollDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/poll`);
            const data = await response.json();
            if (response.ok) {
                if (userRole === 'user') {
                    const filteredData = data.data.filter(poll => {
                        return poll.eventDetails.some(event => event.organiserMaster === userId);
                    });
                    const dataWithSerialNo = filteredData.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                } else {
                    const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch poll details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching poll data' });
            console.error(error);
        }
    };
    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
        setSpeakerDetails([...speakerDetails]);
    };

    const handleDeleteClick = (_id) => {
        const Page_Url = `${BASE_URL}/poll/${_id}`;
        handleDelete(_id, Page_Url, fetchPollDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/poll/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Poll Successfully';
        const errorMessageProp = 'Failed to Poll Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchPollDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/poll/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Poll Successfully';
        const errorMessageProp = 'Failed to Deactivate Poll';
        handleStatusChange(isActive, _id, Page_Url, fetchPollDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );
    const renderActionsDropdown1 = (row) => (
        <Button color="primary" onClick={() => handleNotification(row._id)}>Send</Button>
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, eventDetails, eventDate, endTime, startTime, fname, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        const eventName = eventDetails && eventDetails[0] && eventDetails[0].eventName;
        const eventNameLowercased = eventName ? eventName.toLowerCase().trim() : '';
      

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            (eventNameLowercased && eventNameLowercased.includes(lowercasedSearchText)) ||
            ((fname && lname) && (fname && lname).toLowerCase().includes(lowercasedSearchText)) ||
            (eventDate && eventDate.toLowerCase().includes(lowercasedSearchText)) ||
            (startTime && startTime.toLowerCase().includes(lowercasedSearchText)) ||
            (endTime && endTime.toLowerCase().includes(lowercasedSearchText))
        );
    });


    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Event Name',
            selector: (row) => {
                const event = eventData.find((event) => event._id === row.eventMaster);
                return event ? event.eventName : '';
            },
            sortable: true,
            width: '300px'
        },
        {
            name: 'Agenda Name',
            selector: (row) => {
                const agenda = agendaDataFetch.find((agenda) => agenda._id === row.agendaMaster);
                return agenda ? agenda.agendaData[0].title : '';
            },
            sortable: true,
        },
        
        { name: 'Question', selector: (row) => row.question, sortable: true, width:'350px' },
        { name: 'Option A', selector: (row) => row.optionA, sortable: true },
        { name: 'Option B', selector: (row) => row.optionB, sortable: true },
        { name: 'Option C', selector: (row) => row.optionC, sortable: true },
        { name: 'Option D', selector: (row) => row.optionD, sortable: true },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Send',
            cell: (row) => renderActionsDropdown1(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const handleNotification = async (_id) => {
        setSubmitting(true);
        try {
            const selectedUser = userDetails.find((user) => user._id === _id);
            const data1 = {
              eventMaster:selectedUser.eventMaster,
              question: selectedUser.question,
              optionA:selectedUser.optionA,
              optionB:selectedUser.optionB,
              optionC:selectedUser.optionC,
              optionD: selectedUser.optionD,
            };
            console.log(data1)
            const response = await fetch(`${BASE_URL}/fcm/poll/eventMaster`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the correct content type
                },
                body: JSON.stringify(data1), // Convert data1 to JSON
            });
            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchPollDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Notification Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the notification.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the notification.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        let selectedValue = value;
        if (e.target.nodeName === 'SELECT') {
            const selectedOption = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOption.length > 0 ? selectedOption : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: selectedValue,
        }));
    };


    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };


    const handleEditUser = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                eventMaster: selectedUser.eventMaster,
                agendaMaster: selectedUser.agendaMaster,
                question: selectedUser.question,
                optionA: selectedUser.optionA,
                optionB: selectedUser.optionB,
                optionC: selectedUser.optionC,
                optionD: selectedUser.optionD,
            };
            const response = await fetch(`${BASE_URL}/poll/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            const responseData = await response.text();
            console.log(responseData);

            const data = JSON.parse(responseData);

            if (response.ok) {
                fetchPollDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Poll Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the poll.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the poll.' });
        } finally {
            setSubmitting(false);
        }
    };

    const selectedEvent = eventData.find((event) => event._id === selectedUser.eventMaster);
   const labelToDisplay = selectedEvent ? selectedEvent.eventName : '';

   const selectedAgenda = agendaDataFetch.find((agenda) => agenda._id === selectedUser.agendaMaster);
   const labelToDisplay1 = selectedAgenda ? selectedAgenda.agendaName : '';
    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Poll Details</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-4'>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='pollData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Poll</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="form-group mb-2">
                            <label htmlFor="eventMaster">Event Name</label>
                            <Select
                                value={formData.eventMaster ? formData.eventMaster.value : null}
                                id="eventMaster"
                                name="eventMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                    setSelectedEventData(selectedValue)
                                }}
                                options={eventData
                                    .filter((event) => event.isActive === 1)
                                    .map((event) => ({
                                        value: event._id,
                                        label: event.eventName,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                                className={isFormSubmitted && !formData.eventMaster ? 'is-invalid' : ''}
                            />
                            {isFormSubmitted && !formData.eventMaster && (
                                <div className="invalid-feedback">Please select an event type</div>
                            )}
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="agendaMaster">Agenda Name</label>
                            <Select
                                value={formData.agendaMaster ? formData.agendaMaster.value : null}
                                id="agendaMaster"
                                name="agendaMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'agendaMaster', value: selectedValue } });
                                }}
                                options={agendaDataFetch
                                    .filter((agenda) => agenda.isActive === 1).filter((agenda)=>agenda.eventMaster === selectedEventData)
                                    .flatMap((agenda) => agenda.agendaData.map((agendaItem) => ({
                                        value: agenda._id,
                                        label: agendaItem.title,
                                    })))
                                }
                                getOptionLabel={(option) => option.label}
                            />
                        </div>



                        <div className="form-group mb-2">
                            <label htmlFor="question">question</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="question"
                                placeholder="question"
                                value={formData.question}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your question' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="optionA">option A</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="optionA"
                                placeholder="optionA"
                                value={formData.optionA}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your optionA' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="optionB">option B</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="optionB"
                                placeholder="option B"
                                value={formData.optionB}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your option B' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="optionC">option C</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="optionC"
                                placeholder="option C"
                                value={formData.optionC}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your option C' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="optionD">option D</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="optionD"
                                placeholder="option D"
                                value={formData.optionD}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your option D' },
                                }}
                            />
                        </div>

                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Poll</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>
             <div className="form-group mb-2">
                                <label htmlFor="eventMaster">Event Name</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="eventMaster"
                                    placeholder="Select Role"
                                    value={{ value: selectedUser.eventMaster, label: labelToDisplay }}
                                    options={eventData
                                        .filter((event) => event.isActive === 1)
                                        .map((event) => ({
                                            value: event._id,
                                            label: event.eventName,
                                        }))
                                    }
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, eventMaster: selectedOption ? selectedOption.value : '' });
                                        setSelectedUser({ ...selectedUser, eventMaster: selectedOption ? selectedOption.value : '' });
                                        setSelectedEventData(selectedUser.eventMaster)
                                    }}
                                />
                            </div>
                            
                      <div className="form-group mb-2">
                            <label htmlFor="agendaMaster">Agenda Name</label>
                            <Select
                                value={formData.agendaMaster ? formData.agendaMaster.value : null}
                                id="agendaMaster"
                                name="agendaMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleEditInputChange({ target: { name: 'agendaMaster', value: selectedValue } });
                                }}
                                options={agendaDataFetch
                                    .filter((agenda) => agenda.isActive === 1).filter((agenda)=>agenda.eventMaster === selectedUser.eventMaster)
                                    .flatMap((agenda) => agenda.agendaData.map((agendaItem) => ({
                                        value: agenda._id,
                                        label: agendaItem.title,
                                    })))
                                }
                                getOptionLabel={(option) => option.label}
                            />
                        </div>

                            <div className="form-group mb-2">
                                <label htmlFor="question">question</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="question"
                                    placeholder="question"
                                    value={selectedUser.question}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your question' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="optionA">option A</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="optionA"
                                    placeholder="optionA"
                                    value={selectedUser.optionA}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your optionA' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="optionB">option B</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="optionB"
                                    placeholder="option B"
                                    value={selectedUser.optionB}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your option B' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="optionC">option C</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="optionC"
                                    placeholder="option C"
                                    value={selectedUser.optionC}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your option C' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="optionD">option D</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="optionD"
                                    placeholder="option D"
                                    value={selectedUser.optionD}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your option D' },
                                    }}
                                />
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>

                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>optionA</ModalHeader>
                <ModalBody>
                    <p>{selectedRowoptionA}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpenquestion} toggle={handleCloseModalquestion}>
                <ModalHeader toggle={handleCloseModalquestion}>optionA</ModalHeader>
                <ModalBody>
                    <p>{selectedRowquestion}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModalquestion}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default PollMaster;
