import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SimpleBar from "simplebar-react";
import SidebarContent from "./SidebarContent";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCondensed: window.innerWidth <= 767, // Set initial state based on the screen width
    };
  
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    // Update the state when the window is resized
    this.setState({ isCondensed: window.innerWidth <= 767 });
  };

  render() {
    const { isCondensed } = this.state;

    const sidebarContainerStyle = isCondensed
      ? {
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          width: "80px",
          zIndex: 999,
          backgroundColor: isCondensed ? "#ffffff" : "", // Adjusted background color based on condensed state
          transition: isCondensed ? "all 0.2s ease-out" : "", // Adjusted transition based on condensed state
          boxShadow: isCondensed ? "0 0 20px rgba(0, 0, 0, 0.1)" : "", // Adjusted box shadow based on condensed state
        }
      : {};

    const sidebarContentStyle = {
      maxHeight: "100%",
    };

    

    return (
      <React.Fragment>
        <div className="vertical-menu" style={sidebarContainerStyle}>
          <div data-simplebar className="h-100" style={sidebarContentStyle}>
            {isCondensed ? (
              <SidebarContent />
            ) : (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarContent />
              </SimpleBar>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStatetoProps, {})(withRouter(Sidebar));
