import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../components/Common/AppConfig';
import { useSelector } from 'react-redux';

const SalesAnalytics = () => {
  const [eventData, setEventData] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userRole = useSelector((state) => state.roleReducer.userRole);
  const userId = useSelector((state) => state.roleReducer.userId);

  useEffect(() => {
    fetchEventCount();
  }, []);

  const fetchEventCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/event-graph`);
      const data = await response.json();
       if (response.ok) {
        if (userRole === 'user') {
          const userEvent = data.data.eventDetails.find((event) => event._id === userId);
          
          if (userEvent) {
            setEventData(userEvent.event);
         } else {
            setEventData([]);
          }
        } else {
          setEventData(data.data.eventDetails);
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  let eventNames = [];
  let userCounts = [];
  
  if (eventData && eventData.length > 0) {
    // If there is real data, map over it
    eventNames = eventData.map((event) => event.eventName);
    userCounts = eventData.map((event) => event.userCount);
  } else {
    // If there is no real data, add a single 'No Data' entry
    eventNames = ['No Data'];
    userCounts = [0];
  }
  
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const labels = eventNames;

  const categoryColors = {};
  const seriesColors = [];
  eventNames.forEach((eventName, index) => {
    const color = getRandomColor();
    categoryColors[eventName] = color;
    seriesColors.push(color);
  });

  const series = userCounts;
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w) {
              const totalUserCount = series.reduce((acc, count) => acc + count, 0);
              return totalUserCount;
            },
          },
        },
      },
    },
    labels: labels,
    colors: seriesColors,
  };

  return (
    <React.Fragment>
      {userRole === 'user' ? (
        <Card style={{ minHeight: '470px' }}>
          <CardBody style={{ minHeight: '350' }}>
            <h4 className="card-title mb-4">Event Overview</h4>
            <div id="donut-chart" className="apex-charts">
              <ReactApexChart options={options} series={series} type="radialBar" height="300" />
            </div>
            <div
              style={{
                maxHeight: '150px',
                overflowY: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {labels.map((category, index) => (
                <div key={index} style={{ flexBasis: '25%', textAlign: 'center', paddingLeft: '3px', marginLeft: '2px' }}>
                  <p className="mb-2 text-truncate">
                    <i className={`mdi mdi-circle font-size-10 me-1`} style={{ color: categoryColors[category] }}></i>
                    {category}
                  </p>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default SalesAnalytics;
