import React from 'react';

const handleStatusChange = async (isActive, _id, Page_URL, fetchDetails, successMessage, errorMessage, setAlertMessage, token) => {
  try {
    

    const response = await fetch(Page_URL, {
      method: 'PUT',
      body: JSON.stringify({ isActive: isActive ? 1 : 0 }),
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      fetchDetails();
     
       setAlertMessage((prevMessage) => ({ ...prevMessage, success: successMessage }));
    } else {
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: errorMessage }));
    }
  } catch (error) {
    console.error('Error saving data:', error);
    setAlertMessage((prevMessage) => ({ ...prevMessage, error: errorMessage }));
  }
};


const ActiveStatusChange = ({ _id, Page_URL, fetchDetails, message, token }) => {
  return null;
};
const handleDelete = async (_id, Page_URL, fetchDetails, setAlertMessage, token) => {
  try {
    await fetch(`${Page_URL}`, {
      method: 'DELETE',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });
    fetchDetails();
    setAlertMessage((prevMessage) => ({ ...prevMessage, success: 'Deleted Successfully' }));
  } catch (error) {
    console.error('Error deleting data:', error);
    if (error.response && error.response.data) {
      console.log('Response data:', error.response.data);
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: error.response.data.error }));
    } else {
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: 'An error occurred while deleting the data.' }));
    }
  }
};

const handleCheckedInStausChange = async (isScanned, _id, Page_URL, fetchDetails, successMessage, errorMessage, setAlertMessage, token) => {
  try {
      const response = await fetch(Page_URL, {
      method: 'PATCH',
      body: JSON.stringify({ isScanned: isScanned ? 1 : 0 }),
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      fetchDetails();
      setAlertMessage((prevMessage) => ({ ...prevMessage, success: successMessage }));
    } else {
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: errorMessage }));
    }
  } catch (error) {
    console.error('Error saving data:', error);
    setAlertMessage((prevMessage) => ({ ...prevMessage, error: errorMessage }));
  }
};

export { ActiveStatusChange, handleStatusChange, handleDelete, handleCheckedInStausChange };