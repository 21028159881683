import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Table, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

function EnquriesMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [speakerDetails, setSpeakerDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenlink, setIsModalOpenlink] = useState(false);
    const [selectedRowDescription, setSelectedRowDescription] = useState('');
    const [selectedRowlink, setSelectedRowlink] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [isModalOpenUser, setIsModalOpenUser] = useState(false);
    const handleOpenModal = (row) => {
        setSelectedRowDescription(row.description);
        setIsModalOpen(true);
    };

    const handleOpenModallink = (row) => {
        setSelectedRowlink(row.link);
        setIsModalOpenlink(true);
    };

    const handleCloseModallink = () => {
        setIsModalOpenlink(false);
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            companyName: '',
            location: '',
            subject: '',
            comment: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        companyName: '',
        location: '',
        subject: '',
        comment: '',
    });



    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const requestBody = {
                fname: formData.fname,
                lname: formData.lname,
                email: formData.email,
                phone: formData.phone,
                companyName: formData.companyName,
                location: formData.location,
                subject: formData.subject,
                comment: formData.comment,

            };
            const response = await fetch(`${BASE_URL}/enquiry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    fname: '',
                    lname: '',
                    email: '',
                    phone: '',
                    companyName: '',
                    location: '',
                    subject: '',
                    comment: '',
                });
                toggleAddModal();
                fetchEnquriesDetails();
                history.push('/enquiry');
                setAlertMessage({ success: 'Enquiry added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the enquiry.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the enquiry.' });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchEnquriesDetails();
    }, []);

    const fetchEnquriesDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/enquiry`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch enquiry details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching enquiry data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
        setSpeakerDetails([...speakerDetails]);
    };
    const handleDeleteClick = (_id) => {
        const Page_Url = `${BASE_URL}/enquiry/${_id}`;
        handleDelete(_id, Page_Url, fetchEnquriesDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/enquiry/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Enquiry Successfully';
        const errorMessageProp = 'Failed to Enquiry Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchEnquriesDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/enquiry/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Enquiry Successfully';
        const errorMessageProp = 'Failed to Deactivate Enquiry';
        handleStatusChange(isActive, _id, Page_Url, fetchEnquriesDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, email, phone, companyName, location, fname, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((email) && (email).toLowerCase().includes(lowercasedSearchText)) ||
            ((fname && lname) && (fname && lname).toLowerCase().includes(lowercasedSearchText)) ||
            (phone && phone.toLowerCase().includes(lowercasedSearchText)) ||
            (companyName && companyName.toLowerCase().includes(lowercasedSearchText)) ||
            (location && location.toLowerCase().includes(lowercasedSearchText))
        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Name', selector: (row) => row.fname + ' ' + row.lname, sortable: true },


        
        { name: 'Phone', selector: (row) => row.phone, sortable: true },
        

        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true, width:'175px' },
        {
            name: 'More Details',
            cell: (row) => (
                <button className='btn btn-primary' onClick={() => handleOpenModalUser(row)}>Details</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '150px',
            style: {
                textAlign: 'center',
            },
        },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        let selectedValue = value;
        if (e.target.nodeName === 'SELECT') {
            const selectedOption = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOption.length > 0 ? selectedOption : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const requestBody = {
                fname: selectedUser.fname,
                lname: selectedUser.lname,
                email: selectedUser.email,
                phone: selectedUser.phone,
                companyName: selectedUser.companyName,
                location: selectedUser.location,
                subject: selectedUser.subject,
                comment: selectedUser.comment,
                createdDate: selectedUser.createdDate, // Include the createdDate field
            };

            console.log(selectedUser);
            const response = await fetch(`${BASE_URL}/enquiry`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                fetchEnquriesDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Enquiry Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the enquiry.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the enquiry.' });
        } finally {
            setSubmitting(false);
        }
    };


    const handleOpenModalUser = (row) => {
        setSelectedUser(row);
        setIsModalOpenUser(true);
    };
    const handleCloseModalUser = () => {
        setIsModalOpenUser(false);
    };
    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Enquiry Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='SponsorData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Enquiry</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="fname">First Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="fname"
                                        placeholder="First Name"
                                        value={formData.fname || ''}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="lname">Last Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="lname"
                                        placeholder="Last Name"
                                        value={formData.lname || ''}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Last name' },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="email">Email</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email || ''}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Email' },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="phone">Phone</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="phone"
                                        placeholder="Phone"
                                        value={formData.phone || ''}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Phone' },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="companyName">Company Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="companyName"
                                placeholder="Company Name"
                                value={formData.companyName || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Company Name' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="location">Location</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="location"
                                placeholder="Location"
                                value={formData.location || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Location' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="subject">Subject</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="subject"
                                placeholder="Subject"
                                value={formData.subject || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Subject' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="comment">Comment</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="comment"
                                placeholder="Comment"
                                value={formData.comment || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Comment' },
                                }}
                            />
                        </div>

                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Enquiry</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>



                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="fname">First Name</label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="fname"
                                            placeholder="First Name"
                                            value={selectedUser.fname || ''}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="lname">Last Name</label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="lname"
                                            placeholder="Last Name"
                                            value={selectedUser.lname || ''}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your Last name' },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="email">Email</label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder="Email"
                                            value={selectedUser.email || ''}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your Email' },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="phone">Phone</label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            placeholder="Phone"
                                            value={selectedUser.phone || ''}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your Phone' },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="companyName">Company Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="companyName"
                                    placeholder="Company Name"
                                    value={selectedUser.companyName || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Company Name' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="location">Location</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="location"
                                    placeholder="Location"
                                    value={selectedUser.location || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Location' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="subject">Subject</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="subject"
                                    placeholder="Subject"
                                    value={selectedUser.subject || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Subject' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="comment">Comment</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="comment"
                                    placeholder="Comment"
                                    value={selectedUser.comment || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Comment' },
                                    }}
                                />
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Description</ModalHeader>
                <ModalBody>
                    <p>{selectedRowDescription}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpenlink} toggle={handleCloseModallink}>
                <ModalHeader toggle={handleCloseModallink}>Link</ModalHeader>
                <ModalBody>
                    <p>{selectedRowlink}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModallink}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
            {isUserSelected && (
                <Modal isOpen={isModalOpenUser} toggle={handleCloseModalUser}>
                    <ModalHeader toggle={handleCloseModalUser}>Enquiry Details</ModalHeader>
                    <ModalBody>
                        <div className="form-group  mb-2">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table striped className="mb-0">
                                            <tbody>

                                                <tr>
                                                    <th>First Name</th>
                                                    <td>
                                                        {selectedUser.fname || 'N/A'}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Last Name</th>
                                                    <td>{selectedUser.lname || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <td>{selectedUser.phone || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{selectedUser.email || 'N/A'}</td>
                                                </tr>

                                               
                                               
                                               
                                                <tr>
                                                    <th>Company Name</th>
                                                    <td>{selectedUser.companyName || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Location</th>
                                                    <td>{selectedUser.location || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Subject</th>
                                                    <td>{selectedUser.subject || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comment</th>
                                                    <td>{selectedUser.comment || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{selectedUser.createdDate || 'N/A'}</td>
                                                </tr>
                                       

                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModalUser}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}

export default EnquriesMaster;

