import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { useSelector } from 'react-redux';
import {handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import ActionsDropdown from '../../components/Common/DropDown';
const DynamicForm = () => {
  const [fields, setFields] = useState([]); // State for storing form fields
  const [searchText, setSearchText] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formName, setFormName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFieldType, setSelectedFieldType] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [speakerDetails, setSpeakerDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userRole = useSelector((state) => state.roleReducer.userRole);
  const userId = useSelector((state) => state.roleReducer.userId);
  const [eventData, setEventData] = useState([]);
  const history = useHistory();
  const [formData, setFormData] = useState({
    eventMaster: '',
    master: ''
  });



  const addField = (type) => {
    let defaultValue = '';
    let options = [];
    switch (type) {
      case 'button':
      case 'reset':
      case 'submit':
        defaultValue = '';
        break;
      case 'checkbox':
        defaultValue = false;
        break;
      case 'radio':
        defaultValue = '';
        options = ['Option 1', 'Option 2']; // Default options for radio buttons
        break;
      default:
        defaultValue = '';
    }
    const newFields = [...fields, { type, value: defaultValue, options, isRequired: false }];
    setFields(newFields);
    setDeleteIndex(newFields.length - 1); 
  };

  const deleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);

    if (deleteIndex !== null && deleteIndex >= index) {
      setDeleteIndex(deleteIndex - 1);
    } else {
      setDeleteIndex(deleteIndex === null)
    }
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...fields];

    if (fieldName === 'isRequired') {
      updatedFields[index][fieldName] = value; // Set 'isRequired' explicitly
    } else {
      updatedFields[index][fieldName] = value;
    }

    setFields(updatedFields);
  };


  const handleSubmit = async (e, values) => {
    e.preventDefault();
    setSubmitting(true);
    const { eventMaster, master } = formData; // Destructure eventMaster and master from formData

    try {
      const requestData = {
        eventMaster: eventMaster, // Include eventName data here
        master: {
          formName: formName,
          fields: fields.map((field) => {
            if (field.type === 'radio' || field.type === 'checkbox') {
              return {
                type: field.type,
                value: field.value,
                options: field.options.filter((option) => option !== ''),
                isRequired: field.isRequired,
              };
            }
            return field;
          }),
        }
      };
      const response = await fetch(`${BASE_URL}/dynamicform`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      const responseData = await response.text();
      console.log(responseData);

      const data = JSON.parse(responseData);

      if (response.ok) {
        toggleAddModal();
        fetchDyanamicFormDetails();
        history.push('/dynamicform');
        setSubmitting(false);
        setSuccessMessage("Created Dynamic Table Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      } else {
        setErrorMessage('Failed to add Dynamic Table.');
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      }
    } catch (error) {
      console.log(error);

      setErrorMessage('Failed to add Dynamic Table.');
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };


  const fetchEventData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/event`);
      if (response.ok) {
        const data = await response.json();
        if (userRole === 'user') {
          const filteredData = data.data.filter(event => event.organiserMaster === userId);
          setEventData(filteredData);
        } else {
          setEventData(data.data);

        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDyanamicFormDetails();
    fetchEventData();
  }, []);

  const fetchDyanamicFormDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/dynamicform`);
      const data = await response.json();
      if (response.ok) {
        setUserDetails(data.data);
        console.log(data.data)
      } else {
        setErrorMessage('Failed to fetch event details.');
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to fetch event details.');
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const toggleDropdown = (row) => {
    row.dropdownOpen = !row.dropdownOpen;
    setDropdownOpen(!dropdownOpen);
    setUserDetails([...userDetails]);
    setSpeakerDetails([...speakerDetails]);
  };
  const handleDelete = async (_id) => {
    try {
      await fetch(`${BASE_URL}/dynamicform/${_id}`, {
        method: 'DELETE',
      });
      fetchDyanamicFormDetails();
    } catch (error) {
      console.log(error);
    }
  };
  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };


  const handleEdit = (user) => {
    setSelectedUser(user);
    toggleEditModal();
  };


  const handleDeleteClick = (_id) => {
    console.log('Delete button clicked for ID:', _id);
    const Page_Url = `${BASE_URL}/agenda/${_id}`;
    handleDelete(_id, Page_Url, fetchDyanamicFormDetails, setAlertMessage);
};


const handleActiveClick = (_id) => {
    const Page_Url = `${BASE_URL}/agenda/status/${_id}`;
    const isActive = 1;
    const successMessageProp = 'Activated Agenda Successfully';
    const errorMessageProp = 'Failed to Activate Agenda';
    handleStatusChange(isActive, _id, Page_Url, fetchDyanamicFormDetails, successMessageProp, errorMessageProp, setAlertMessage);
};

const handleDeactiveClick = (_id) => {
    const Page_Url = `${BASE_URL}/agenda/status/${_id}`;
    const isActive = 0;
    const successMessageProp = 'Deactivated Agenda Successfully';
    const errorMessageProp = 'Failed to Deactivate Agenda';
    handleStatusChange(isActive, _id, Page_Url, fetchDyanamicFormDetails, successMessageProp, errorMessageProp, setAlertMessage);
};


const handleAction = (action, row) => {
    if (action.toLowerCase() === 'edit') {
        handleEdit(row);
    } else if (action.toLowerCase() === 'delete') {
        handleDeleteClick(row._id);
    } else if (action.toLowerCase() === 'active') {
        handleActiveClick(row._id);
    } else if (action.toLowerCase() === 'deactive') {
        handleDeactiveClick(row._id);
    }
};
const renderActionsDropdown = (row) => (
    <ActionsDropdown
        row={row}
        toggleDropdown={toggleDropdown}
        handleAction={handleAction}
        isActive={row.isActive}
        showEdit={true}
    />
);

  const filteredData = userDetails.filter((user) => {
    const { _id, title, eventDate, endTime, startTime, fname, lname } = user;
    const lowercasedSearchText = searchText.toLowerCase();

    return (
      (_id && _id.toString().includes(lowercasedSearchText)) ||
      ((title) && (title).toLowerCase().includes(lowercasedSearchText)) ||
      ((fname && lname) && (fname && lname).toLowerCase().includes(lowercasedSearchText)) ||
      (eventDate && eventDate.toLowerCase().includes(lowercasedSearchText)) ||
      (startTime && startTime.toLowerCase().includes(lowercasedSearchText)) ||
      (endTime && endTime.toLowerCase().includes(lowercasedSearchText))
    );
  });
  // CSS styles
  const styles = {
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      margin: '25px'
    },
    cell: {
      padding: '10px',
      textAlign: 'left',
      border: '1px solid #ddd',
      width: '150px'

    },
    headerCell: {
      padding: '10px',
      textAlign: 'left',
      border: '1px solid #ddd',
      fontWeight: 'bold',
    },
  };

  let serialNumber = 1;
  const columns = [
    {
      name: '#',
      cell: () => serialNumber++,
      sortable: true,
      width:'50px'
    },
    {
      name: 'Event Name',
      selector: (row) => {
        
          const event = eventData.find((event) => event._id === row.eventMaster);
          return event ? event.eventName : '';
      },
      sortable: true,
      width:'200px'
  },

    {
      name: 'Form Name',
      cell: (row) => (
        <span
          className="form-name"
          onClick={() => handleFormNameClick(row)}
        >
          {row.master.formName}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Fields',
      cell: (row) => {
        const fieldsData = row.master.fields.map((field) => {
          const { type, value, options, isRequired } = field;
          return (
            <tr key={value}>
              <td style={{ ...styles.cell, textAlign: 'center' }}>{type}</td>
              <td style={{ ...styles.cell, textAlign: 'center' }}>{value}</td>
              <td style={{ ...styles.cell, textAlign: 'center' }}>{options.join(', ')}</td>
              <td style={{ ...styles.cell, textAlign: 'center' }}>{isRequired ? 'Yes' : 'No'}</td>
            </tr>
          );
        });
        return (
          <div>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={{ ...styles.headerCell, textAlign: 'center' }}>Type</th>
                  <th style={{ ...styles.headerCell, textAlign: 'center' }}>Value</th>
                  <th style={{ ...styles.headerCell, textAlign: 'center' }}>Options</th>
                  <th style={{ ...styles.headerCell, textAlign: 'center' }}>Required</th>
                </tr>
              </thead>
              <tbody>{fieldsData}</tbody>
            </table>
          </div>
        );
      },
      sortable: true,
      width: '700px',
      center: true,
    },

    { name: 'Created Date', selector: 'createdDate', sortable: true },
    {
      name: 'Active',
      cell: (row) => (
        <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
          {row.isActive === 1 ? 'Active' : 'Inactive'}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => renderActionsDropdown(row),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleActive = async (_id) => {

    try {
      const response = await fetch(`${BASE_URL}/dynamicform/status/${_id}`, {
        method: 'PUT',
        body: JSON.stringify({ isActive: 1 }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        fetchDyanamicFormDetails();

        setSuccessMessage('User Activated successfully');
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      if (error.response && error.response.data) {
        console.log('Response data:', error.response.data);
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An error occurred while saving the data.');
      }
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
  };

  const handleDeactive = async (_id) => {
    try {
      const response = await fetch(`${BASE_URL}/dynamicform/status/${_id}`, {
        method: 'PUT',
        body: JSON.stringify({ isActive: 0 }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        fetchDyanamicFormDetails();
        setSuccessMessage('User Deactivated successfully');
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      if (error.response && error.response.data) {
        console.log('Response data:', error.response.data);
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An error occurred while saving the data.');
      }
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
  };
  const handleDeleteUser = async () => {
    if (selectedUser) {
      await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
      setSelectedUser(null);
      toggleEditModal();
    }
  };

  const fieldOptions = [
    { value: 'checkbox', label: 'Checkbox' },
   { value: 'number', label: 'Number' },
    { value: 'radio', label: 'Radio' },
    { value: 'text', label: 'Text' },
  ];
  const toggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };
  const handleAddUser = () => {

    toggleAddModal();
  };


  const handleFormNameClick = (form) => {
    setSelectedForm(form);
    setIsModalOpen(true);
  };


  const renderFormElement = (field) => {
    const { type, value, options } = field;
    switch (type) {
      case 'checkbox':
        if (options && options.length > 0) {
          return (
            <div key={value} style={{ marginBottom: '10px' }}>
              <label htmlFor={value}>
                {value}
              </label>
              <div className="row">
                {options.map((option) => (
                  <div className="col-3" key={option}>
                        <AvForm>
                          <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'inline-block' }} >{option}</div>
                            <AvField type={type} name={option} />
                          </div>
                        </AvForm>
                  </div>
                ))}
              </div>
            </div>
          );
        }
      case 'radio':
        if (options && options.length > 0) {
          return (
            <div key={value} style={{ marginBottom: '10px' }}>
              <label htmlFor={value} style={{ marginBottom: '10px' }}>
                {value}
              </label>
              <div className="row" style={{ marginRight: "10px" }}>
                {options.map((option) => (
                  <div className="col-3" key={option}>
                    <AvForm>
                      <div style={{ marginRight: "10px" }}>
                        <AvForm>
                          <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'inline-block' }} >{option}</div>
                            <AvField type={type} name={option} />
                          </div>
                        </AvForm>
                      </div>
                    </AvForm>
                  </div>
                ))}
              </div>
            </div>
          );
        }
      default:
        return (
          <div key={value} style={{ marginBottom: '10px' }}>
            <AvForm>
              <AvField type={type} name={value} label={value} />
            </AvForm>
          </div>
        );
    }
  };


  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Dyanamic Table</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="8" className="d-flex justify-content-start mb-3">
                  <CSVLink data={[]} filename="dyanamicData" className="btn btn-primary">
                    Export to Excel
                  </CSVLink>
                </Col>
                <Col md="4" className="d-flex justify-content-end mb-3">
                  <Input
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ borderRadius: '20px', marginRight: '10px' }}
                  />
                  <Button color="primary" onClick={handleAddUser}>
                    Add
                  </Button>
                </Col>

              </Row>


              <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
                {selectedForm && selectedForm.master && (
                  <>
                    <ModalHeader toggle={() => setIsModalOpen(false)}>{selectedForm.master.formName}</ModalHeader>
                    <ModalBody>
                      <div>
                        {selectedForm.master.fields.map((field) => (
                          <div className='mb-3' key={field.value}>{renderFormElement(field)}</div>
                        ))}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </Modal>
              {successMessage && <div className="alert alert-primary">{successMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

              <DataTable columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />

              <div>
                <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                  <ModalHeader toggle={toggleAddModal}>Create Form</ModalHeader>
                  <ModalBody>
                    <div className="form-group mb-2">
                      <label htmlFor="eventMaster">Event Name</label>
                      <Select
                        value={formData.eventMaster ? formData.eventMaster.value : null}
                        id="eventMaster"
                        name="eventMaster"
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption ? selectedOption.value : '';
                          handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                        }}
                        options={eventData
                          .filter((event) => event.isActive === 1)
                          .map((event) => ({
                            value: event._id,
                            label: event.eventName,
                          }))
                        }
                        getOptionLabel={(option) => option.label}
                      />

                    </div>

                    <div className="form-group mb-2">
                      <label htmlFor="formName">Form Name</label>
                      <input
                        type="text"
                        id="formName"
                        name="formName"
                        value={formName}
                        onChange={(e) => setFormName(e.target.value)}
                        className="form-control"
                      />
                    </div>

                    <div className="mb-3 mt-3 d-flex align-items-center">
                      <div className="flex-grow-1">
                        <div className="row ">
                          <div className="col-7">    
                          <Select
                            options={fieldOptions}
                            value={selectedFieldType}
                            onChange={(option) => setSelectedFieldType(option)}
                            placeholder="Select Type"
                          />
                          </div>
                        </div>

                        <div className="ml-3 col-5" style={{ marginRight: '10px' }}>
                          <Button
                            color="primary"
                            onClick={() => addField(selectedFieldType ? selectedFieldType.value : '')}
                          >
                            Add
                          </Button>
                        </div>
                        {deleteIndex === null ? null : (
                          <div className="ml-3">
                            <Button
                              type="button"
                              onClick={() => deleteField(deleteIndex)}
                              disabled={deleteIndex === null}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </div>

                    </div>



                    <AvForm onSubmit={handleSubmit}>
                      {fields.map((field, index) => (
                        <div className="mb-4 p-3 border rounded" key={index}>
                          {field.type !== 'checkbox' && field.type !== 'radio' ? (
                            <div className='row'>
                              <div className='col-7 mt-3'>
                                <label htmlFor={`value_${index}`}>Label</label>
                                <AvField
                                  type="text"
                                  name={`value_${index}`}
                                  value={field.value}
                                  placeholder="Field Value"
                                  onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                                  validate={{ required: { value: true, errorMessage: 'Value is required' } }}
                                />
                              </div>

                              <div className="col-3 mt-3">
                                <AvField
                                  type="checkbox"
                                  name={`isRequired_${index}`}
                                  checked={field.isRequired || false}
                                  onChange={(e) => handleFieldChange(index, 'isRequired', e.target.checked)}
                                  label="IsRequired"
                                />
                              </div>

                            </div>

                          ) : (
                            <div className='col-12'>
                              <label className='mt-3' htmlFor="field.value">Label</label>
                              <div className="col-7">
                                <AvField
                                  type="text"
                                  name={`value_${index}`}
                                  value={field.value}
                                  placeholder="Field Value"
                                  onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                                  validate={{ required: { value: true, errorMessage: 'Value is required' } }}
                                />
                              </div>
                              <div className="col-3 mt-3">
                                <AvField
                                  type="checkbox"
                                  name={`isRequired_${index}`}
                                  checked={field.isRequired || false}
                                  onChange={(e) => handleFieldChange(index, 'isRequired', e.target.checked)}
                                  label="IsRequired"
                                />
                              </div>



                              {field.options.map((option, optionIndex) => (
                                <div key={optionIndex}>
                                  <div className="row">
                                    <div className="col-7 mt-3">

                                      <AvField
                                        type="text"
                                        name={`option_${index}_${optionIndex}`}
                                        value={option}
                                        placeholder="Option"
                                        onChange={(e) =>
                                          handleFieldChange(index, 'options', [
                                            ...field.options.slice(0, optionIndex),
                                            e.target.value,
                                            ...field.options.slice(optionIndex + 1),
                                          ])
                                        }
                                        validate={{
                                          required: { value: true, errorMessage: 'Option is required' },
                                        }}
                                      />

                                    </div>
                                    <div className="col-3 mt-3">
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          handleFieldChange(
                                            index,
                                            'options',
                                            field.options.filter((_, i) => i !== optionIndex)
                                          )
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <Button className='mt-3 mb-3'
                                type="button"
                                onClick={() => handleFieldChange(index, 'options', [...field.options, ''])}
                              >
                                Add Option
                              </Button>
                            </div>
                          )}

                        </div>
                      ))}
                      <ModalFooter>
                        <Button color="secondary" onClick={toggleAddModal}>
                          Cancel
                        </Button>
                        <Button color="primary" type="submit" disabled={submitting}>
                          {submitting ? "Adding..." : "Add"}
                        </Button>
                      </ModalFooter>
                    </AvForm>

                  </ModalBody>
                </Modal>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DynamicForm;
