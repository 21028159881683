import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { useSelector } from 'react-redux';

const MiniWidgets = () => {
  const [eventCount, setEventCount] = useState(0); // Initialize with 0
  const [userCount, setUserCount] = useState(0); // Initialize with 0
  const [speakerCount, setSpeakerCount] = useState(0); // Initialize with 0
  const [sponsorCount, setSponsorCount] = useState(0); // Initialize with 0
  const [latestEvent, setLatestEvent] = useState('No Events'); // Initialize with 'No Events'
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

  const userRole = useSelector((state) => state.roleReducer.userRole);
  const userId = useSelector((state) => state.roleReducer.userId);

  useEffect(() => {
    fetchEventCount();
    fetchUserCount();
    fetchSpeakerCount();
    fetchSponsorCount();
    fetchLatestCount();
  }, []);

  const fetchEventCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/event-count`);
      const data = await response.json();

      if (response.ok) {
        if (userRole === 'user') {
          const userEventCount = data.data.eventCountOrganiser.find((event) => event._id === userId);
          if (userEventCount) {
            setEventCount(userEventCount.eventCount);
          } else {
            setEventCount([]);
          }
        } else {
          setEventCount(data.data.eventCount);
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const fetchUserCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user-count`);
      const data = await response.json();
      if (response.ok) {
        setUserCount(data.data);
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const fetchSpeakerCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/speaker-count`);
      const data = await response.json();

      if (response.ok) {
        if (userRole === 'user') {
          const userEventCount = data.data.userSpeakerCount.find((event) => event._id === userId);
          if (userEventCount) {
            setSpeakerCount(userEventCount.speakerCount);
          } else {
            setSpeakerCount([]);
          }
        } else {
          setSpeakerCount(data.data.speakerCount);
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const fetchSponsorCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/sponsor-count`);
      const data = await response.json();

      if (response.ok) {
        if (userRole === 'user') {
          const userEventCount = data.data.userSponsorCount.find((event) => event._id === userId);
          if (userEventCount) {
            setSponsorCount(userEventCount.sponsorCount);
          } else {
            setSponsorCount([]);
          }
        } else {
          setSponsorCount(data.data.sponsorCount);
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const fetchLatestCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/event-latest`);
      const data = await response.json();

      if (response.ok) {
        if (userRole === 'user') {
          const userEventLatestDate = data.data.eventDetails.find((event) => event.organiserMaster === userId);
          if (userEventLatestDate) {
            setLatestEvent(userEventLatestDate.eventName);
          } else {
            setLatestEvent('No Events'); // No event found for the user
          }
        } else {
          if (data.data.eventDetails.length > 0) {
            setLatestEvent(data.data.eventDetails[0].eventName); // Use the first event if available
          } else {
            setLatestEvent('No Events'); // No events available
          }
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-3">
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <p className="text-truncate font-size-14 mb-2">Total Events</p>
                  <h4 className="mb-0">{eventCount}</h4>
                </div>
                <div className="text-primary">
                  <i className="ri-service-fill" style={{ fontSize: '40px' }}></i>
                </div>
              </div>
            </CardBody>
            <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 me-1">
                  <i className="mdi mdi-menu-up">12 </i>
                </span>
                <span className="text-muted ms-2">Compared with last month</span>
              </div>
            </CardBody>
          </Card>
        </div>
        {userRole === 'admin' ? (
          <div className="col-12 col-sm-6 col-lg-3">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">Total Revenue</p>
                    <h4 className="mb-0">₹ 12,25,300</h4>
                  </div>
                  <div className="text-primary">
                    <i className="ri-wallet-fill" style={{ fontSize: '40px' }}></i>
                  </div>
                </div>
              </CardBody>
              <CardBody className="border-top py-3">
                <div className="text-truncate">
                  <span className="badge badge-soft-success font-size-11 me-1">
                    <i className="mdi mdi-menu-up">12 </i>
                  </span>
                  <span className="text-muted ms-2">Compared with last month</span>
                </div>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div className="col-12 col-sm-6 col-lg-3">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">Latest Events</p>
                    <h6 className="mb-0">{latestEvent}</h6>
                  </div>
                  <div className="text-primary">
                    <i className="ri-alarm-fill" style={{ fontSize: '40px' }}></i>
                  </div>
                </div>
              </CardBody>
              <CardBody className="border-top py-3">
                <div className="text-truncate">
                  <span className="badge badge-soft-success font-size-11 me-1">
                    <i className="mdi mdi-menu-up">12 </i>
                  </span>
                  <span className="text-muted ms-2">Compared with last month</span>
                </div>
              </CardBody>
            </Card>
          </div>
        )}
        <div className="col-12 col-sm-6 col-lg-3">
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-1 overflow-hidden">
                  <p className="text-truncate font-size-14 mb-2">Total Speaker</p>
                  <h4 className="mb-0">{speakerCount}</h4>
                </div>
                <div className="text-primary">
                  <i className="ri-user-voice-fill" style={{ fontSize: '40px' }}></i>
                </div>
              </div>
            </CardBody>
            <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 me-1">
                  <i className="mdi mdi-menu-up">12 </i>
                </span>
                <span className="text-muted ms-2">Compared with last month</span>
              </div>
            </CardBody>
          </Card>
        </div>
        {userRole === 'admin' ? (
          <div className="col-12 col-sm-6 col-lg-3">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">Total Users</p>
                    <h4 className="mb-0">{userCount}</h4>
                  </div>
                  <div className="text-primary">
                    <i className="ri-team-fill" style={{ fontSize: '40px' }}></i>
                  </div>
                </div>
              </CardBody>
              <CardBody className="border-top py-3">
                <div className="text-truncate">
                  <span className="badge badge-soft-success font-size-11 me-1">
                    <i className="mdi mdi-menu-up">12 </i>
                  </span>
                  <span className="text-muted ms-2">Compared with last month</span>
                </div>
              </CardBody>
            </Card>
          </div>
        ) : (
          <div className="col-12 col-sm-6 col-lg-3">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-1 overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">Total Sponsors</p>
                    <h4 className="mb-0">{sponsorCount}</h4>
                  </div>
                  <div className="text-primary">
                    <i className="ri-team-fill" style={{ fontSize: '40px' }}></i>
                  </div>
                </div>
              </CardBody>
              <CardBody className="border-top py-3">
                <div className="text-truncate">
                  <span className="badge badge-soft-success font-size-11 me-1">
                    <i className="mdi mdi-menu-up">12 </i>
                  </span>
                  <span className="text-muted ms-2">Compared with last month</span>
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MiniWidgets;
