import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../components/Common/AppConfig';
import { useSelector } from 'react-redux';

const AdminBarChart = () => {
  const [userData, setUserData] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userRole = useSelector((state) => state.roleReducer.userRole);
  const userId = useSelector((state) => state.roleReducer.userId);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1 to get the actual month
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    fetchEventCount();
  }, []);

  const fetchEventCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user-graph`);
      const data = await response.json();

      if (response.ok) {
        setUserData(data.data.eventDetails);
        console.log(data.data);
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const labels = userData.map((item) => item._id);
  const userCounts = userData.map((item) => item.userCount);

  const formattedLabels = labels.map((label) => {
    const [year, month] = label.split('-');
    const monthName = new Date(`${year}-${month}-01`).toLocaleDateString(undefined, {
      month: 'short',
    });
    return `${monthName} ${year}`;
  });

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: formattedLabels,
    },
    stroke: {
      width: [0, 3],
      curve: 'smooth',
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: '#004A99', // Color for the bars
            },
            {
              from: 101,
              to: 200,
              color: '#004A99', // Color for the bars
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: 'User Count',
      type: 'bar',
      data: userCounts,
    },
  ];

  const totalUserCount = userData.reduce((total, item) => total + item.userCount, 0);

  const eventCountThisMonth = userData.find((item) => {
    const [year, month] = item._id.split('-').map(Number);
    return year === currentYear && month === currentMonth;
  })?.userCount || 0;

  console.log(eventCountThisMonth);

  // Calculate the eventCount for the previous month
  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

  const eventCountPreviousMonth = userData.find((item) => {
    const [year, month] = item._id.split('-').map(Number);
    return year === previousYear && month === previousMonth;
  })?.userCount || 0;

  console.log(eventCountPreviousMonth);

  // Calculate percentage increase

  // Calculate percentage change for this year
  const eventCountThisYear = userData
    .filter((item) => {
      const [year] = item._id.split('-').map(Number);
      return year === currentYear;
    })
    .reduce((total, item) => total + item.userCount, 0);
  const percentageIncrease = ((eventCountPreviousMonth - eventCountThisYear) / eventCountThisYear) * 100;

  const percentageChanged = ((eventCountThisMonth - eventCountPreviousMonth) / eventCountPreviousMonth) * 100;

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Registered User</h4>
          <div>
            <div id="line-column-chart" className="apex-charts" dir="ltr">
              <ReactApexChart options={options} series={series} type="line" height="280" />
            </div>
          </div>
        </CardBody>
        <CardBody className="border-top text-center">
          <Row>
            <Col sm={4}>
              <div className="d-inline-flex">
                <h5 className="me-2">{eventCountPreviousMonth}</h5>
                <div className={percentageIncrease >= 0 ? 'text-success' : 'text-danger'}>
                  <i className={percentageIncrease >= 0 ? 'mdi mdi-menu-up font-size-14' : 'mdi mdi-menu-down font-size-14'}> </i>
                  {Math.abs(percentageIncrease).toFixed(1)} %
                </div>
              </div>
              <p className="text-muted text-truncate mb-0">Previous Month </p>
            </Col>

            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Month :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0 me-2">{eventCountThisMonth}</h5>
                  <div className={percentageChanged >= 0 ? 'text-success' : 'text-danger'}>
                    <i className={percentageChanged >= 0 ? 'mdi mdi-menu-up font-size-14' : 'mdi mdi-menu-down font-size-14'}> </i>
                    {Math.abs(percentageChanged).toFixed(1)} %
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-success font-size-10 me-1"></i> This Year
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0">{totalUserCount}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AdminBarChart;
