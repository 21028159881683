import React from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <div className="text-center text-sm-end d-block d-sm-none">
                {new Date().getFullYear()} © RadarSoft Technologies.
              </div>
              <div className="text-sm-end d-none d-sm-block">
                {new Date().getFullYear()} © RadarSoft Technologies.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
