import React from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// Import Routes
import { authProtectedRoutes, publicRoutes, authProtectedUserRoutes } from "./routes/";
import AppRoute from "./routes/route";

// Import VerticalLayout
import VerticalLayout from "./components/VerticalLayout/";

// Import scss
import "./assets/scss/theme.scss";

import LoginAuthentication from "./pages/Authentication/Login";
import { useSelector } from 'react-redux';
function App() {
  const Layout = VerticalLayout;
  const Login = LoginAuthentication;

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const sessionToken = sessionStorage.getItem('sessionToken');
    const sessionExpiration = sessionStorage.getItem('sessionExpiration');
    const currentTime = new Date().getTime();
    const userRole = useSelector((state) => state.roleReducer.userRole);

    if (sessionToken && sessionExpiration && currentTime < sessionExpiration) {
      const allowedRoutes = userRole === 'admin' ? authProtectedRoutes : authProtectedUserRoutes;
      const isRouteAllowed = allowedRoutes.some((route) => route.path === rest.path);

      return isRouteAllowed ? (
        <Route {...rest} render={(props) => <Layout><Component {...props} /></Layout>} />
      ) : (
        <Redirect to="/dashboard" />
      );
    } else {
      // Session is not active, redirect to the login page
      return <Redirect to="/" replace />;
    }
  };

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {/* Public routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              exact
              component={Login} 
            />
          ))}

          {/* Protected routes */}
          {authProtectedRoutes.map((route, idx) => (
            <PrivateRoute key={idx} path={route.path} exact component={route.component} />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
