import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

function UserTagMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

    const [formData, setFormData] = useState({
        uTags: '',
        pollTag: '',
        agendaTag: '',
    });


    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            uTags: '',
            pollTag: '',
            agendaTag: '',
        });
        toggleAddModal();
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            setSubmitting(true);
            const requestBody = {
                uTags: formData.uTags,
                pollTag: formData.pollTag,
                agendaTag: formData.agendaTag,

            };
            const response = await fetch(`${BASE_URL}/usertags`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    categoryName: '',
                });
                toggleAddModal();
                fetchUserTagsDetails();
                history.push('/usertags');
                setAlertMessage({ success: 'User Tags added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the use rtags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the user tags.' });
        } finally {
            setSubmitting(false);
        }
    };


    useEffect(() => {
        fetchUserTagsDetails();
    }, []);

    const fetchUserTagsDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/usertags`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch user tags details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching user tags data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        const updatedRow = { ...row, dropdownOpen: !row.dropdownOpen };
        setUserDetails((prevUserDetails) =>
            prevUserDetails.map((user) => (user._id === row._id ? updatedRow : user))
        );
    };

    const styles = {
        dataTable: {
            zIndex: 1,
            overflow: "visible",
            transform: "translate3d((-287px, -288px, 0px)", // Add the transform property here
        },

    };

    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/usertags/${_id}`;
        handleDelete(_id, Page_Url, fetchUserTagsDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/usertags/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Category Successfully';
        const errorMessageProp = 'Failed to Category Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchUserTagsDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/usertags/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Category Successfully';
        const errorMessageProp = 'Failed to Deactivate Category';
        handleStatusChange(isActive, _id, Page_Url, fetchUserTagsDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, uTags, eventDate, endTime, startTime, fname, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((uTags) && (uTags).toLowerCase().includes(lowercasedSearchText)) ||
            ((fname && lname) && (fname && lname).toLowerCase().includes(lowercasedSearchText)) ||
            (eventDate && eventDate.toLowerCase().includes(lowercasedSearchText)) ||
            (startTime && startTime.toLowerCase().includes(lowercasedSearchText)) ||
            (endTime && endTime.toLowerCase().includes(lowercasedSearchText))
        );
    });

  
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'User Tag', selector: (row) => row.uTags, sortable: true },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        let selectedValue = value;
        if (e.target.nodeName === 'SELECT') {
            const selectedOption = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOption.length > 0 ? selectedOption : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        try {
            setSubmitting(true);
            const requestBody = {
                uTags: selectedUser.uTags,
                createdDate: selectedUser.createdDate,
            };
            const response = await fetch(`${BASE_URL}/usertags/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                fetchUserTagsDetails();
                toggleEditModal();
                setAlertMessage({ success: 'User Tags Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the user tags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the user tags.' });
        } finally {
            setSubmitting(false);
        }
    };

    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">User Tags Details</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-4'>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='UserTagData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />

                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Agenda</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>

                        <div className="form-group mb-2">
                            <label htmlFor="uTags">User Tag </label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="uTags"
                                placeholder="User Tag Name"
                                value={formData.uTags || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                }}
                            />
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit User Tags</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>

                            <div className="form-group mb-2">
                                <label htmlFor="uTags">User Tag</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="uTags"
                                    placeholder="User Tag"
                                    value={selectedUser.uTags || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                    }}
                                />
                            </div>

                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}

        </div>
    );
}

export default UserTagMaster;
