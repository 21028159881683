import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Table,Button, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import Select from "react-select";
function WelcomeMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [isModalOpenUser, setIsModalOpenUser] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);
    const token=useJwtToken();
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        location: '',
    });

    const handleOpenModalUser = (row) => {
        setSelectedUser(row);
        setIsModalOpenUser(true);
    };
    const handleCloseModalUser = () => {
        setIsModalOpenUser(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            name: '',
        phone: '',
        email: '',
        location: '',
        });
        toggleAddModal();
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            setSubmitting(true);
            const requestBody = {
                eventMaster:formData.eventMaster,
                name: formData.name,
                phone: formData.phone,
                email: formData.email,
                location: formData.location,

            };
            const response = await fetch(`${BASE_URL}/welcome`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    categoryName: '',
                });
                toggleAddModal();
                fetchWelcomeMaster();
                history.push('/welcome');
                setAlertMessage({ success: 'User added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the use rtags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the user tags.' });
        } finally {
            setSubmitting(false);
        }
    };


    useEffect(() => {
        fetchWelcomeMaster();
        fetchEventData();
    }, []);

    const fetchWelcomeMaster = async () => {
        try {
            const response = await fetch(`${BASE_URL}/welcome`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch users details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching user tags data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        const updatedRow = { ...row, dropdownOpen: !row.dropdownOpen };
        setUserDetails((prevUserDetails) =>
            prevUserDetails.map((user) => (user._id === row._id ? updatedRow : user))
        );
    };

    const styles = {
        dataTable: {
            zIndex: 1,
            overflow: "visible",
            transform: "translate3d((-287px, -288px, 0px)", // Add the transform property here
        },

    };

    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/welcome/${_id}`;
        handleDelete(_id, Page_Url, fetchWelcomeMaster, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/welcome/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated User Successfully';
        const errorMessageProp = 'Failed to activate user';
        handleStatusChange(isActive, _id, Page_Url, fetchWelcomeMaster, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/welcome/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated User Successfully';
        const errorMessageProp = 'Failed to Deactivate User';
        handleStatusChange(isActive, _id, Page_Url, fetchWelcomeMaster, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, name, email, phone, location,  } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((name) && (name).toLowerCase().includes(lowercasedSearchText)) ||
            ((email) && (email).toLowerCase().includes(lowercasedSearchText)) ||
            (phone && phone.toLowerCase().includes(lowercasedSearchText)) ||
            (location && location.toLowerCase().includes(lowercasedSearchText)) 
          
        );
    });

  
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Name', selector: (row) => row.name, sortable: true },
        { name: 'Phone', selector: (row) => row.phone, sortable: true },
        
        {
            name: 'User Details',
            cell: (row) => (
              <button className='btn btn-primary' onClick={() => handleOpenModalUser(row)}>Details</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '150px',
            style: {
              textAlign: 'center', 
            },
          },
          {
            name: 'Send Message',
            cell: (row) => (
              <button className='btn btn-primary' onClick={() => handleSendMessage(row)}>Send</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '150px',
            style: {
              textAlign: 'center', 
            },
          },
       
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const handleSendMessage=async (row)=>{
        try {
            setSubmitting(true);
           const eventDetails=eventData.filter((event)=>row.eventMaster===event._id);
    const requestBody = {
                phone: row.phone,
                eventMaster:eventDetails[0].eventName,
                name:row.name,

            };
            const response = await fetch(`${BASE_URL}/welcome-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setAlertMessage({ success: 'Message Sent successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while sending Message.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the user tags.' });
        } finally {
            setSubmitting(false);
        }
    }
    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        let selectedValue = value;
        if (e.target.nodeName === 'SELECT') {
            const selectedOption = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOption.length > 0 ? selectedOption : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        try {
            setSubmitting(true);
            const requestBody = {
                eventMaster:selectedUser.eventMaster,
                name: selectedUser.name,
                phone: selectedUser.phone,
                email: selectedUser.email,
                location: selectedUser.location,
            };
            const response = await fetch(`${BASE_URL}/welcome/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                fetchWelcomeMaster();
                toggleEditModal();
                setAlertMessage({ success: 'User details updated successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the user tags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the user tags.' });
        } finally {
            setSubmitting(false);
        }
    };

    
    const fetchEventData = async () => {

        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
              };
            const response = await fetch(`${BASE_URL}/event`, {
                method: 'GET',
                headers:headers,
            });
            if (response.ok) {
                const data = await response.json();

                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    setEventData(filteredData);
                } else {
                    setEventData(data.data);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    function handleContactNumberKeyDown(event) {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
        const { key } = event;

        if (!allowedKeys.includes(key) && !/\d/.test(key)) {
            event.preventDefault();
        }

        const { value } = event.target;
        if (value.length >= 11 && !allowedKeys.includes(key)) {
            event.preventDefault();
        }
    }

    const isUserSelected = Object.keys(selectedUser).length > 0;
    const isSelectedUser = Object.keys(selectedUser).length > 0;
    const selectedEvent = eventData.find((event) => event._id === selectedUser.eventMaster);
    const labelToDisplay = selectedEvent ? selectedEvent.eventName : '';
    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">User Registration</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-4'>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='UserTagData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />

                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add User</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                    <div className="form-group mb-2">
                            <label htmlFor="eventMaster">Event Name</label>
                            <Select
                                value={formData.eventMaster ? formData.eventMaster.value : null}
                                id="eventMaster"
                                name="eventMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                }}
                                options={eventData
                                    .filter((event) => event.isActive === 1)
                                    .map((event) => ({
                                        value: event._id,
                                        label: event.eventName,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                                className={isFormSubmitted && !formData.eventMaster ? 'is-invalid' : ''}
                            />
                            {isFormSubmitted && !formData.eventMaster && (
                                <div className="invalid-feedback">Please select an event type</div>
                            )}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="uTags">Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="User Name"
                                value={formData.name || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Name' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="phone">Phone</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="phone"
                                placeholder="User Phone"
                                value={formData.phone || ''}
                                onChange={handleInputChange}
                                onKeyDown={handleContactNumberKeyDown}
                                pattern="\d*"
                                maxLength="11"
                                minLength="10"
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Phone' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="email">Email</label>
                            <AvField
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={formData.email || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your email' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="location">Location</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="location"
                                placeholder="Location"
                                value={formData.location || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Location' },
                                }}
                            />
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit User Tags</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>
                        <div className="form-group mb-2">
                                <label htmlFor="eventMaster">Event Name</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="eventMaster"
                                    placeholder="Select Role"
                                    value={{ value: selectedUser.eventMaster, label: labelToDisplay }}
                                    options={eventData
                                        .filter((event) => event.isActive === 1)
                                        .map((event) => ({
                                            value: event._id,
                                            label: event.eventName,
                                        }))
                                    }
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, eventMaster: selectedOption ? selectedOption.value : '' });
                                        setSelectedUser({ ...selectedUser, eventMaster: selectedOption ? selectedOption.value : '' });
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="name">Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    placeholder="Name"
                                    value={selectedUser.name || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Name' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="name">Phone</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    placeholder="Phone"
                                    value={selectedUser.phone || ''}
                                    onKeyDown={handleContactNumberKeyDown}
                                    pattern="\d*"
                                    maxLength="11"
                                    minLength="10"
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Phone' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email">Email</label>
                                <AvField
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    value={selectedUser.email || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Email' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="location">Location</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="location"
                                    placeholder="Location"
                                    value={selectedUser.location || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Location' },
                                    }}
                                />
                            </div>

                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}
   {isSelectedUser && (
  <Modal isOpen={isModalOpenUser} toggle={handleCloseModalUser}>
    <ModalHeader toggle={handleCloseModalUser}>User Details</ModalHeader>
    <ModalBody>
      <div className="form-group  mb-2">
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table striped className="mb-0">
                <tbody> <tr>
                    <th>Event Name</th>
                    <td>{labelToDisplay|| 'N/A'}</td>
                  </tr>
                 
                  <tr>
                    <th>Name</th>
                    <td>{selectedUser.name || 'N/A'}</td>
                  </tr>
                  
                  <tr>
                    <th>Phone</th>
                    <td>{selectedUser.phone || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{selectedUser.email || 'N/A'}</td>
                  </tr>
                  
                  <tr>
                    <th>Location</th>
                    <td>{selectedUser.location || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Registration Date</th>
                    <td>{selectedUser.createdDate || 'N/A'}</td>
                  </tr>
                 
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={handleCloseModalUser}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalBody>
  </Modal>
)}
        </div>
    );
}

export default WelcomeMaster;
