import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Flatpickr from "react-flatpickr"
import { CSVLink } from 'react-csv';
import Select from "react-select";
function UserMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [userTagDetails, setUserTagDetails] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenUser, setIsModalOpenUser] = useState(false);
    const [errorMessageModel, setErrorMessageModel] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const token = useJwtToken();
    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            fname: '',
            lname: '',
            phone: '',
            email: '',
            password: '',
            interest_tags: [],
            workExperience: [
                {
                    companyName: '',
                    companyLogo: '',
                    position: '',
                    startDate: '',
                    endDate: '',
                    shortDesc: '',
                },
            ],
            expertise_tags: [],
            address: '',
            linkedAccounts: [
                {
                    type: '',
                    url: ''
                }
            ],
            bio: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        interest_tags: [],
        workExperience: [
            {
                companyName: '',
                companyLogo: '',
                position: '',
                startDate: '',
                endDate: '',
                shortDesc: '',
            },
        ],
        expertise_tags: [],
        address: '',
        linkedAccounts: [
            {
                type: '',
                url: ''
            }
        ],
        bio: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleAdd = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        setFormSubmitted(true);
        formData.workExperience.forEach((experience, index) => {
            validateStartDate(experience.startDate);
            validateEndDate(experience.endDate, experience.startDate);
        });

        if (!selectedUser.u_photo) {
            setErrorMessageModel('Please upload a file.');
            setSubmitting(false);
            return;
        }
        try {
            const form = new FormData();
            form.append('fname', formData.fname);
            form.append('lname', formData.lname);
            form.append('phone', formData.phone);
            form.append('email', formData.email);
            form.append('interest_tags', JSON.stringify(formData.interest_tags));
            form.append('workExperience', JSON.stringify(formData.workExperience));
            form.append('linkedAccounts', JSON.stringify(formData.linkedAccounts));
            form.append('expertise_tags', JSON.stringify(formData.expertise_tags));
            form.append('address', formData.address);
            form.append('bio', formData.bio);
            form.append('u_photo', selectedUser.u_photo);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB

            if (!allowedTypes.includes(selectedUser.u_photo.type)) {
                setAlertMessage({ success: 'Invalid file type. Please upload a PNG, JPG, or JPEG file.', error: '' });
                return;
            }

            if (selectedUser.u_photo.size > maxFileSize) {
                setAlertMessage({ success: 'File size limit exceeded. Please upload a file up to 2MB.', error: '' });
                return;
            }

            const headers = {
                'Authorization': token,

            };

            const response = await fetch(`${BASE_URL}/user`, {
                method: 'POST',
                body: form,
                headers: headers
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    fname: '',
                    lname: '',
                    phone: '',
                    email: '',
                    password: '',
                    interest_tags: [],
                    workExperience: [
                        {
                            companyName: '',
                            companyLogo: '',
                            position: '',
                            startDate: '',
                            endDate: '',
                            shortDesc: '',
                        },
                    ],
                    expertise_tags: [],
                    address: '',
                    linkedAccounts: [
                        {
                            type: '',
                            url: ''
                        }
                    ],
                    bio: '',
                });
                toggleAddModal();
                fetchUserDetails();
                history.push('/usermaster');
                setSubmitting(false);
                setAlertMessage({ success: 'User Added Successfully', error: '' });
            } else if (response.status === 500) {
                setAlertMessage({ success: '', error: data.data }); // Show the message returned by the server
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the user.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the user.' });
        } finally {
            setSubmitting(false);
        }
    };


    const handleU_photoChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB

        setSelectedUser((prevSelectedUser) => ({
            ...prevSelectedUser,
            u_photo: file,
            imageName: file ? file.name : '',
        }));

        if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
            console.log('Valid file:', file);
            setAlertMessage({ success: '', error: '' });
        } else {
            console.log('Invalid file');
            setAlertMessage({ success: 'Invalid file. Please upload a PNG, JPG, or JPEG file within 2MB.', error: '' });
        }
    };


    useEffect(() => {
        fetchUserDetails();
        fetchUserTags();
    }, []);

    const fetchUserTags = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };

            const response = await fetch(`${BASE_URL}/usertags`, {
                method: 'GET',
                headers: headers,
            });
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserTagDetails(dataWithSerialNo);

            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch organiser details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching organiser data' });
            console.error(error);
        }
    };


    const fetchUserDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/user`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);

            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch User details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching user data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    const handleDeleteClick = (_id) => {
        const Page_Url = `${BASE_URL}/user/${_id}`;
        handleDelete(_id, Page_Url, fetchUserDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/user/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Category Successfully';
        const errorMessageProp = 'Failed to Category Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchUserDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/user/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Category Successfully';
        const errorMessageProp = 'Failed to Deactivate Category';
        handleStatusChange(isActive, _id, Page_Url, fetchUserDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );


    const styles = {
        dataTable: {
            zIndex: 1,
            overflow: "visible",
            transform: "translate3d((-287px, -288px, 0px)", // Add the transform property here
        },

    };

    const filteredData = userDetails.filter((user) => {
        const { _id, fname, lname, username, phone, email } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((fname && lname) && (fname + lname).toLowerCase().includes(lowercasedSearchText)) ||
            (username && username.toLowerCase().includes(lowercasedSearchText)) ||
            (phone && phone.toLowerCase().includes(lowercasedSearchText)) ||
            (email && email.toLowerCase().includes(lowercasedSearchText))
        );
    });

   
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Name', selector: (row) => row.fname + " " + row.lname, sortable: true, },
        { name: 'Phone', selector: (row) => row.phone, sortable: true },

        { name: 'Registration Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'More Details',
            cell: (row) => (
                <button className='btn btn-primary' onClick={() => handleOpenModalUser(row)}>Details</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '150px',
            style: {
                textAlign: 'center',
            },
        },

        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },

        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];



    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };


    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            const matchingTags = selectedUser.interest_tags.map(tagId => {
                const matchingTag = userTagDetails.find(tag => tag._id === tagId);
                return matchingTag ? {
                    tag_id: matchingTag._id,
                    uTags: matchingTag.uTags
                } : null;
            }).filter(tag => tag !== null);

            const matchingTags1 = selectedUser.expertise_tags.map(tagId => {
                const matchingTag1 = userTagDetails.find(tag => tag._id === tagId);
                return matchingTag1 ? {
                    expertise_id: matchingTag1._id,
                    uTags: matchingTag1.uTags
                } : null;
            }).filter(tag => tag !== null);

            const form = new FormData();
            form.append('fname', selectedUser.fname);
            form.append('lname', selectedUser.lname);
            form.append('phone', selectedUser.phone);
            form.append('email', selectedUser.email);
            form.append('password', selectedUser.password);
            form.append('interest_tags', JSON.stringify(matchingTags));
            form.append('workExperience', JSON.stringify(selectedUser.workExperience));
            form.append('expertise_tags', JSON.stringify(matchingTags1));
            form.append('address', selectedUser.address);
            form.append('bio', selectedUser.bio);
            form.append('linkedAccounts', JSON.stringify(selectedUser.linkedAccounts));
            if (selectedUser.u_photo) {
                if (selectedUser.u_photo !== null) {
                    form.append('u_photo', selectedUser.u_photo);
                } else {
                    form.append('u_photo', selectedUser.u_photo);
                }
            }
            const response = await fetch(`${BASE_URL}/user/${selectedUser._id}`, {
                method: 'PATCH',
                body: form,
            });
            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchUserDetails();
                toggleEditModal();
                setAlertMessage({ success: 'User Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit user.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing user.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };


    const handleAddWorkExperience = () => {
        setFormData((prevData) => ({
            ...prevData,
            workExperience: [
                ...prevData.workExperience,
                {
                    companyName: '',
                    companyLogo: '',
                    position: '',
                    startDate: '',
                    endDate: '',
                    shortDesc: '',
                },
            ],
        }));
    };

    const handleRemoveWorkExperience = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            workExperience: prevData.workExperience.filter((_, i) => i !== index),
        }));
    };

    const handleRemoveLinkedAccount = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            linkedAccounts: prevData.linkedAccounts.filter((_, i) => i !== index),
        }));
    };

    // Define state variables
    const [isInvalidStartDate, setIsInvalidStartDate] = useState(false);
    const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
    const handleStartDateChange = (index, selectedDate) => {
        const formattedStartDate = new Date(selectedDate[0]).toISOString().split('T')[0];

        setFormData((prevData) => {
            const updatedWorkExperience = [...prevData.workExperience];
            if (updatedWorkExperience[index]) { // Check if the element at the given index exists
                updatedWorkExperience[index].startDate = formattedStartDate;
            }
            return { ...prevData, workExperience: updatedWorkExperience };
        });

        validateStartDate(formattedStartDate);
    };

    const validateStartDate = (startDate) => {

        if (!startDate || new Date(startDate) > new Date()) {
            setIsInvalidStartDate(true);
        } else {
            setIsInvalidStartDate(false);
        }
    };

    const handleEndDateChange = (index, selectedDate) => {
        const formattedEndDate = new Date(selectedDate[0]).toISOString().split('T')[0];

        setFormData((prevData) => {
            const updatedWorkExperience = [...prevData.workExperience];
            console.log('updatedWorkExperience:', updatedWorkExperience);
            console.log('index:', index);

            if (updatedWorkExperience[index]) {
                updatedWorkExperience[index].endDate = formattedEndDate;
            } else {
                console.log('updatedWorkExperience[index] is undefined.');
            }
            return { ...prevData, workExperience: updatedWorkExperience };
        });

        validateEndDate(formattedEndDate, formData.workExperience[index]?.startDate);
    };




    const validateEndDate = (endDate, startDate) => {
        if (endDate === 'Present') {
            setIsInvalidEndDate(false);
            setIsInvalidStartDate(false); // Reset start date validation when 'Present' is selected
        } else if (!endDate || new Date(endDate) > new Date()) {
            setIsInvalidEndDate(true);
        } else if (startDate && new Date(endDate) < new Date(startDate)) { // Check if startDate is defined before comparing
            setIsInvalidEndDate(true);
        } else {
            setIsInvalidEndDate(false);
        }
    };



    const handleSocialAccountChange = (index, field, value) => {
        const updatedFormData = { ...formData };
        const updatedLinkedAccounts = [...updatedFormData.linkedAccounts];
        const socialAccount = updatedLinkedAccounts[index] || {};
        socialAccount[field] = value;
        updatedLinkedAccounts[index] = socialAccount;
        updatedFormData.linkedAccounts = updatedLinkedAccounts;
        setFormData(updatedFormData);

    };

    const addSocialAccountField = () => {
        const updatedFormData = { ...formData };
        updatedFormData.linkedAccounts.push({ type: '', url: '' });
        setFormData(updatedFormData);
    };

    // Function to handle changes in the edit model
    const handleEditSocialAccountChange = (index, field, value) => {
        const updatedUser = { ...selectedUser }; // Use selectedUser for editing
        updatedUser.linkedAccounts = updatedUser.linkedAccounts || [];
        const socialAccount = updatedUser.linkedAccounts[index] || {};
        socialAccount[field] = value;
        updatedUser.linkedAccounts[index] = socialAccount;
        setSelectedUser(updatedUser);
    };

    // Function to add a social account in the edit model
    const addEditSocialAccountField = () => {
        const updatedUser = { ...selectedUser };
        updatedUser.linkedAccounts = updatedUser.linkedAccounts || [];
        updatedUser.linkedAccounts.push({ type: '', url: '' });
        setSelectedUser(updatedUser);
    };

    const handleEditWorkExperience = (index, updatedExperience) => {
        console.log('Updated Experience:', updatedExperience);
        setSelectedUser((prevData) => {
            const updatedWorkExperience = [...prevData.workExperience];
            updatedWorkExperience[index] = updatedExperience;
            console.log('Updated Work Experience:', updatedWorkExperience);
            return { ...prevData, workExperience: updatedWorkExperience };
        });
    };

    const handleAddWorkExperienceEdit = () => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            workExperience: [
                ...prevUser.workExperience,
                {
                    companyName: '',
                    companyLogo: '',
                    position: '',
                    startDate: '',
                    endDate: '',
                    shortDesc: '',
                },
            ],
        }));
    };

    const handleRemoveWorkExperienceEdit = (index) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            workExperience: prevUser.workExperience.filter((_, i) => i !== index),
        }));
    };


    const handleRemoveLinkedAccountEdit = (index) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            linkedAccounts: prevUser.linkedAccounts.filter((_, i) => i !== index),
        }));
    };

    function handleContactNumberKeyDown(event) {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
        const { key } = event;

        if (!allowedKeys.includes(key) && !/\d/.test(key)) {
            event.preventDefault();
        }

        const { value } = event.target;
        if (value.length >= 11 && !allowedKeys.includes(key)) {
            event.preventDefault();
        }
    }



    const handleOpenModalUser = (row) => {
        setSelectedUser(row);
        setIsModalOpenUser(true);
    };
    const handleCloseModalUser = () => {
        setIsModalOpenUser(false);
    };


    const isUserSelected = Object.keys(selectedUser).length > 0;


    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">User Details</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-4'>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='RegisterUserData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal} backdrop="static">
                <ModalHeader toggle={toggleAddModal}>Add User</ModalHeader>
                <ModalBody>
                    <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="fname">First Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="fname"
                                        placeholder="Name"
                                        value={formData.fname}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your first name' },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="lname">Last Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="lname"
                                        placeholder="Name"
                                        value={formData.lname}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your last name' },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="phone">Phone</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onKeyDown={handleContactNumberKeyDown}
                                pattern="\d*"
                                maxLength="11"
                                minLength="10"
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your phone number' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="email">Email</label>
                            <AvField
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your email address' },
                                    email: { errorMessage: 'Invalid email address' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="interest_tags">Interest Tags</label>
                            <Select
                                value={Array.isArray(formData.interest_tags) ? formData.interest_tags.map(tag => ({
                                    value: tag.tag_id,
                                    label: tag.uTags
                                })) : []}
                                id="interest_tags"
                                name="interest_tags"
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map(option => ({ tag_id: option.value, uTags: option.label })) : [];
                                    setFormData(prevFormData => ({ ...prevFormData, interest_tags: selectedValues }));
                                }}
                                options={userTagDetails
                                    .filter((tags) => tags.isActive === 1)
                                    .map((tags) => ({
                                        value: tags._id,
                                        label: tags.uTags,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                                isMulti
                            />
                        </div>

                        <div className="form-group mt-4 d-flex flex-column align-items-center justify-content-center">
                            <label className="work-experience-label">Work Experience</label>
                        </div>
                        {Array.isArray(formData.workExperience) && formData.workExperience.map((experience, index) => (
                            <div key={index}>
                                <div className="form-group mb-2">

                                    <AvField
                                        type="text"
                                        name={`companyName-${index}`} // Unique name for each field
                                        value={experience.companyName}
                                        onChange={(e) =>
                                            setFormData((prevData) => {
                                                const updatedWorkExperience = [...prevData.workExperience];
                                                updatedWorkExperience[index].companyName = e.target.value;
                                                return { ...prevData, workExperience: updatedWorkExperience };
                                            })
                                        }
                                        label="Company Name"
                                        placeholder="Enter Company Name"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-2">

                                    <AvField
                                        type="url"
                                        name={`companyLogo-${index}`} // Unique name for each field
                                        value={experience.companyLogo}
                                        onChange={(e) =>
                                            setFormData((prevData) => {
                                                const updatedWorkExperience = [...prevData.workExperience];
                                                updatedWorkExperience[index].companyLogo = e.target.value;
                                                return { ...prevData, workExperience: updatedWorkExperience };
                                            })
                                        }
                                        label="Company Logo"
                                        placeholder="Enter Company Logo URL"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-2">

                                    <AvField
                                        type="text"
                                        name={`position-${index}`} // Unique name for each field
                                        value={experience.position}
                                        onChange={(e) =>
                                            setFormData((prevData) => {
                                                const updatedWorkExperience = [...prevData.workExperience];
                                                updatedWorkExperience[index].position = e.target.value;
                                                return { ...prevData, workExperience: updatedWorkExperience };
                                            })
                                        }
                                        label="Position"
                                        placeholder="Enter your position"
                                        required
                                    />
                                </div>
                                <div className="row">
                                    <div className="form-group mb-2 col-5">
                                        <label htmlFor={`startDate${index}`}>Start Date</label>
                                        <Flatpickr
                                            className={`form-control ${isInvalidStartDate ? 'is-invalid' : ''}`}
                                            value={experience.startDate}
                                            onChange={(value) => {
                                                handleStartDateChange(index, value);
                                            }}
                                            options={{
                                                dateFormat: 'Y-m-d',
                                            }}
                                            placeholder="Start Date"
                                        />
                                        {isInvalidStartDate && (
                                            <div className="invalid-feedback">Please select a valid Start Date.</div>
                                        )}
                                    </div>
                                    <div className="form-group mb-2 col-5">
                                        <label htmlFor={`endDate${index}`}>End Date</label>
                                        {experience.endDate === 'Present' ? (
                                            <input
                                                className={`form-control ${isInvalidEndDate ? 'is-invalid' : ''}`}
                                                type="text"
                                                name={`endDate-${index}`}
                                                placeholder="End Date"
                                                value="Present"
                                                onChange={(e) => {
                                                    const updatedWorkExperience = [...formData.workExperience];
                                                    updatedWorkExperience[index].endDate = e.target.value;
                                                    setFormData({ ...formData, workExperience: updatedWorkExperience });
                                                    validateEndDate('Present', experience.startDate);
                                                }}
                                                disabled
                                            />
                                        ) : (
                                            <Flatpickr
                                                className={`form-control ${isInvalidEndDate ? 'is-invalid' : ''}`}
                                                value={experience.endDate}
                                                onChange={(value) => {
                                                    handleEndDateChange(index, value);

                                                }}
                                                options={{
                                                    dateFormat: 'Y-m-d',
                                                }}
                                                placeholder="End Date"
                                            />
                                        )}
                                        {isInvalidEndDate && (
                                            <div className="invalid-feedback">Please select a valid End Date.</div>
                                        )}
                                    </div>
                                    <div className="col-2">
                                        <label htmlFor={`presentCheckbox${index}`} className="text-center">Present</label>
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <input
                                                className="form-check-input text-center"
                                                type="checkbox"
                                                checked={experience.endDate === 'Present'}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    setFormData((prevData) => {
                                                        const updatedWorkExperience = [...prevData.workExperience];
                                                        updatedWorkExperience[index].endDate = isChecked ? 'Present' : '';
                                                        return { ...prevData, workExperience: updatedWorkExperience };
                                                    });

                                                    // Reset validation when Present checkbox is checked
                                                    setIsInvalidStartDate(false);
                                                    setIsInvalidEndDate(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-2">

                                    <AvField
                                        type="textarea"
                                        name={`shortDesc-${index}`} // Unique name for each field
                                        value={experience.shortDesc}
                                        onChange={(e) =>
                                            setFormData((prevData) => {
                                                const updatedWorkExperience = [...prevData.workExperience];
                                                updatedWorkExperience[index].shortDesc = e.target.value;
                                                return { ...prevData, workExperience: updatedWorkExperience };
                                            })
                                        }
                                        label="Short Description"
                                        placeholder="Enter Short Description"
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-secondary form-group mt-2 mb-2" onClick={() => handleRemoveWorkExperience(index)}>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary form-group mb-2 mt-2" type="button" onClick={handleAddWorkExperience}>
                                Add Work Experience
                            </button>
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="expertise_tags">Expertise Tags</label>
                            <Select
                                value={Array.isArray(formData.expertise_tags) ? formData.expertise_tags.map(tag => ({
                                    value: tag.tag_id,
                                    label: tag.uTags
                                })) : []}
                                id="expertise_tags"
                                name="expertise_tags"
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions ? selectedOptions.map(option => ({ expertise_id: option.value, uTags: option.label })) : [];
                                    setFormData(prevFormData => ({ ...prevFormData, expertise_tags: selectedValues }));
                                }}
                                options={userTagDetails
                                    .filter((tags) => tags.isActive === 1)
                                    .map((tags) => ({
                                        value: tags._id,
                                        label: tags.uTags,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                                isMulti
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="address">Address</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="address"
                                placeholder="Address"
                                value={formData.address}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Address' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="bio">Bio</label>
                            <AvField
                                type="textarea"
                                className="form-control"
                                name="bio"
                                placeholder="Bio"
                                value={formData.bio}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Bio' },
                                }}
                            />
                        </div>
                        <div className="form-group mt-4 d-flex flex-column align-items-center justify-content-center">
                            <label className="work-experience-label">Social Accounts</label>
                        </div>
                        {Array.isArray(formData.linkedAccounts) && formData.linkedAccounts.map((social, index) => (
                            <div key={index} className="form-group mb-2">
                                <div className="form-group mb-2">
                                    <label htmlFor={`socialType${index}`}>Social Type</label>
                                    <Select
                                        id={`socialType${index}`}
                                        name={`socialType${index}`}
                                        value={{ value: social.type, label: social.type }}
                                        onChange={(selectedOption) =>
                                            handleSocialAccountChange(index, 'type', selectedOption.value)
                                        }
                                        options={[
                                            { value: 'Facebook', label: 'Facebook' },
                                            { value: 'Twitter', label: 'Twitter' },
                                            { value: 'Instagram', label: 'Instagram' },
                                            { value: 'Linkedin', label: 'Linkedin' },
                                            { value: 'Google+', label: 'Google+' },
                                            { value: 'Others', label: 'Others' },
                                        ]}
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor={`socialLink${index}`}>Social Link</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`socialLink${index}`}
                                        name={`socialLink${index}`}
                                        placeholder="Social Link"
                                        value={social.url}
                                        onChange={(e) => handleSocialAccountChange(index, 'url', e.target.value)}
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-secondary form-group mt-2" onClick={() => handleRemoveLinkedAccount(index)}>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="mt-3 mb-2 d-flex justify-content-center">

                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={addSocialAccountField}
                            >
                                Add Social Account
                            </button>
                        </div>

                        <div className="form-group mt-4 mb-2">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <div className="prev-image-container">
                                        <img
                                            src="static/media/avatar-4.jpg"
                                            style={{ width: '50px', height: '70px' }}
                                            alt="Profile"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-2">
                                    <label htmlFor="u_photo" className="custom-file-label">
                                        Profile Picture
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="u_photo"
                                            name="u_photo"
                                            onChange={handleU_photoChange}
                                            style={{ width: '100%' }}

                                            required={formSubmitted && !selectedUser.u_photo}
                                        />
                                        {formSubmitted && !selectedUser.u_photo && (
                                            <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                        )}
                                    </label>
                                    {selectedUser.u_photo && (
                                        <div className="text-success">File uploaded successfully.</div>
                                    )}
                                    <small className="form-text text-muted mt-2">
                                        Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                        image icon size: 300x300px.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal} backdrop="static">
                    <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="fname">First Name</label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="fname"
                                            placeholder="Name"
                                            value={selectedUser.fname}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your first name' },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="lname">Last Name</label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="lname"
                                            placeholder="Last Name"
                                            value={selectedUser.lname}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your last name' },
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="phone">Phone</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    placeholder="Phone"
                                    value={selectedUser.phone}
                                    onKeyDown={handleContactNumberKeyDown}
                                    pattern="\d*"
                                    maxLength="11"
                                    minLength="10"
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your phone number' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email">Email</label>
                                <AvField
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    value={selectedUser.email}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your email address' },
                                        email: { errorMessage: 'Invalid email address' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="interest_tags">Interest Tags</label>
                                <Select
                                    value={Array.isArray(selectedUser.interest_tags) ? selectedUser.interest_tags.map(tagId => ({
                                        value: tagId,
                                        label: userTagDetails.find(tag => tag._id === tagId)?.uTags || ''
                                    })) : []}
                                    id="interest_tags"
                                    name="interest_tags"
                                    onChange={(selectedOptions) => {
                                        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                        setFormData(prevFormData => ({ ...prevFormData, interest_tags: selectedValues }));
                                        setSelectedUser(prevSelectedUser => ({
                                            ...prevSelectedUser,
                                            interest_tags: selectedValues,
                                        }));
                                    }}
                                    options={userTagDetails
                                        .filter((tags) => tags.isActive === 1)
                                        .map((tags) => ({
                                            value: tags._id,
                                            label: tags.uTags,
                                        }))
                                    }
                                    getOptionLabel={(option) => option.label}
                                    isMulti={true}
                                />
                            </div>

                            <div className="form-group mt-4 d-flex flex-column align-items-center justify-content-center">
                                <label className="work-experience-label">Work Experience</label>
                            </div>
                            {Array.isArray(selectedUser.workExperience) && selectedUser.workExperience.map((experience, index) => (
                                <div key={index}>
                                    <div className="form-group mb-2">

                                        <AvField
                                            type="text"
                                            name={`companyName-${index}`}
                                            value={experience.companyName}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const updatedExperience = {
                                                    ...experience,
                                                    companyName: newValue,
                                                };
                                                handleEditWorkExperience(index, updatedExperience);
                                            }}
                                            label="Company Name"
                                            placeholder="Enter Company Name"
                                            required
                                        />


                                    </div>
                                    <div className="form-group mb-2">

                                        <AvField
                                            type="url"
                                            name={`companyLogo-${index}`}
                                            value={experience.companyLogo}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const updatedExperience = {
                                                    ...experience,
                                                    companyLogo: newValue,
                                                };
                                                handleEditWorkExperience(index, updatedExperience);
                                            }}
                                            label="Company Logo"
                                            placeholder="Enter Company Logo URL"
                                            required
                                        />

                                    </div>
                                    <div className="form-group mb-2">

                                        <AvField
                                            type="text"
                                            name={`position-${index}`}
                                            value={experience.position}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const updatedExperience = {
                                                    ...experience,
                                                    position: newValue,
                                                };
                                                handleEditWorkExperience(index, updatedExperience);
                                            }}
                                            label="Position"
                                            placeholder="Enter your position"
                                            required
                                        />

                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-2 col-5">
                                            <label htmlFor={`startDate${index}`}>Start Date</label>
                                            <Flatpickr
                                                className={`form-control ${isInvalidStartDate ? 'is-invalid' : ''}`}
                                                value={experience.startDate}
                                                onChange={(value) => {
                                                    handleStartDateChange(index, value);
                                                }}
                                                options={{
                                                    dateFormat: 'Y-m-d',
                                                }}
                                                placeholder="Start Date"
                                            />
                                            {isInvalidStartDate && (
                                                <div className="invalid-feedback">Please select a valid Start Date.</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-2 col-5">
                                            <label htmlFor={`endDate${index}`}>End Date</label>
                                            {experience.endDate === 'Present' ? (
                                                <input
                                                    className={`form-control ${isInvalidEndDate ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    name={`endDate-${index}`}
                                                    placeholder="End Date"
                                                    value="Present"
                                                    onChange={(e) => {
                                                        const updatedWorkExperience = [...formData.workExperience];
                                                        updatedWorkExperience[index].endDate = e.target.value;
                                                        setFormData({ ...formData, workExperience: updatedWorkExperience });
                                                        validateEndDate('Present', experience.startDate);
                                                    }}
                                                    disabled
                                                />
                                            ) : (
                                                <Flatpickr
                                                    className={`form-control ${isInvalidEndDate ? 'is-invalid' : ''}`}
                                                    value={experience.endDate}
                                                    onChange={(value) => {
                                                        handleEndDateChange(index, value);

                                                    }}
                                                    options={{
                                                        dateFormat: 'Y-m-d',
                                                    }}
                                                    placeholder="End Date"
                                                />
                                            )}
                                            {isInvalidEndDate && (
                                                <div className="invalid-feedback">Please select a valid End Date.</div>
                                            )}
                                        </div>
                                        <div className="col-2">
                                            <label htmlFor={`presentCheckbox${index}`} className="text-center">Present</label>
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <input
                                                    className="form-check-input text-center"
                                                    type="checkbox"
                                                    checked={selectedUser.workExperience[index]?.endDate === 'Present'}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        const updatedUser = { ...selectedUser };
                                                        updatedUser.workExperience[index].endDate = isChecked ? 'Present' : '';
                                                        setSelectedUser(updatedUser);
                                                        setIsInvalidStartDate(false);
                                                        setIsInvalidEndDate(false);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group mb-2">

                                        <AvField
                                            type="textarea"
                                            name={`shortDesc-${index}`}
                                            value={experience.shortDesc}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const updatedExperience = {
                                                    ...experience,
                                                    shortDesc: newValue,
                                                };
                                                handleEditWorkExperience(index, updatedExperience);
                                            }}
                                            label="Short Description"
                                            placeholder="Enter Short Description"
                                            required
                                        />

                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="button" className="btn btn-secondary form-group mt-2 mb-2" onClick={() => handleRemoveWorkExperienceEdit(index)}>
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary form-group mb-2 mt-2" type="button" onClick={handleAddWorkExperienceEdit}>
                                    Add Work Experience
                                </button>
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="expertise_tags">Expertise Tags</label>
                                <Select
                                    value={Array.isArray(selectedUser.expertise_tags) ? selectedUser.expertise_tags.map(tagId => ({
                                        value: tagId,
                                        label: userTagDetails.find(tag => tag._id === tagId)?.uTags || ''
                                    })) : []}
                                    id="expertise_tags"
                                    name="expertise_tags"
                                    onChange={(selectedOptions) => {
                                        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                        setFormData(prevFormData => ({ ...prevFormData, expertise_tags: selectedValues }));
                                        setSelectedUser(prevSelectedUser => ({
                                            ...prevSelectedUser,
                                            expertise_tags: selectedValues,
                                        }));
                                    }}
                                    options={userTagDetails
                                        .filter((tags) => tags.isActive === 1)
                                        .map((tags) => ({
                                            value: tags._id,
                                            label: tags.uTags,
                                        }))
                                    }
                                    getOptionLabel={(option) => option.label}
                                    isMulti={true}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="address">Address</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    placeholder="Address"
                                    value={selectedUser.address}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Address' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="bio">Bio</label>
                                <AvField
                                    type="textarea"
                                    className="form-control"
                                    name="bio"
                                    placeholder="Bio"
                                    value={selectedUser.bio}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Bio' },
                                    }}
                                />
                            </div>
                            <div className="form-group mt-4 d-flex flex-column align-items-center justify-content-center">
                                <label className="work-experience-label">Social Accounts</label>
                            </div>
                            {Array.isArray(selectedUser.linkedAccounts) && selectedUser.linkedAccounts.map((social, index) => (
                                <div key={index} className="form-group mb-2">
                                    <div className="form-group mb-2">
                                        <label htmlFor={`socialType${index}`}>Social Type</label>
                                        <Select
                                            id={`socialType${index}`}
                                            name={`socialType${index}`}
                                            value={{ value: social.type || '', label: social.type || '' }}
                                            onChange={(selectedOption) =>
                                                handleEditSocialAccountChange(index, 'type', selectedOption.value)
                                            }
                                            options={[
                                                { value: 'Facebook', label: 'Facebook' },
                                                { value: 'Twitter', label: 'Twitter' },
                                                { value: 'Instagram', label: 'Instagram' },
                                                { value: 'Linkedin', label: 'Linkedin' },
                                                { value: 'Google+', label: 'Google+' },
                                                { value: 'Others', label: 'Others' },
                                            ]}
                                        />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor={`socialLink${index}`}>Social Link</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={`socialLink${index}`}
                                            name={`socialLink${index}`}
                                            placeholder="Social Link"
                                            value={social.url || ''}
                                            onChange={(e) => handleEditSocialAccountChange(index, 'url', e.target.value)}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="button" className="btn btn-secondary form-group mt-2 mb-2" onClick={() => handleRemoveLinkedAccountEdit(index)}>
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}

                            <div className="mt-3 mb-2 d-flex justify-content-center">

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={addEditSocialAccountField}
                                >
                                    Add Social Account
                                </button>
                            </div>

                            <div className="form-group mb-2 mt-4 ">
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                                        <div className="prev-image-container">
                                            <img
                                                src="static/media/avatar-4.jpg"
                                                style={{ width: '50px', height: '70px' }}
                                                alt="Profile"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-9 mb-2">
                                        <label htmlFor="u_photo" className="custom-file-label">
                                            Profile Picture
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="u_photo"
                                                name="u_photo"
                                                onChange={handleU_photoChange}
                                                style={{ width: '100%' }}

                                                required={formSubmitted && !selectedUser.u_photo}
                                            />
                                            {formSubmitted && !selectedUser.u_photo && (
                                                <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                            )}
                                        </label>
                                        {selectedUser.u_photo && (
                                            <div className="text-success">File uploaded successfully.</div>
                                        )}
                                        <small className="form-text text-muted mt-2">
                                            Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                            image icon size: 300x300px.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <ModalFooter>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}



            {isUserSelected && (
                <Modal isOpen={isModalOpenUser} toggle={handleCloseModalUser}>
                    <ModalHeader toggle={handleCloseModalUser}>User Details</ModalHeader>
                    <ModalBody>
                        <div className="form-group  mb-2">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table striped className="mb-0">
                                            <tbody>

                                                <tr>
                                                    <th>First Name</th>
                                                    <td>
                                                        {selectedUser.fname || 'N/A'}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Last Name</th>
                                                    <td>{selectedUser.lname || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <td>{selectedUser.phone || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{selectedUser.email || 'N/A'}</td>
                                                </tr>
                                              
                                                <tr>
                                                    <th>Interest</th>
                                                    <td>
                                                        {selectedUser.interest_tags && selectedUser.interest_tags.length > 0 ? (
                                                            selectedUser.interest_tags.map((tagId, index) => {
                                                                const matchingTag = userTagDetails.find((tag) => tag._id === tagId);
                                                                if (matchingTag) {
                                                                    return (
                                                                        <div key={index}>
                                                                            {matchingTag.uTags}
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th>Work Experience</th>
                                                    <td>
                                                        {selectedUser.workExperience && selectedUser.workExperience.length > 0 ? (
                                                            selectedUser.workExperience.map((exp, index) => (
                                                                <div key={index}>
                                                                    {`${exp.position} at ${exp.companyName}`}
                                                                    <br />
                                                                    {`(${exp.startDate} - ${exp.endDate})`}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>Expertise</th>
                                                    <td>
                                                        {selectedUser.expertise_tags && selectedUser.expertise_tags.length > 0 ? (
                                                            selectedUser.expertise_tags.map((tagId, index) => {
                                                                const matchingTag = userTagDetails.find((tag) => tag._id === tagId);
                                                                if (matchingTag) {
                                                                    return (
                                                                        <div key={index}>
                                                                            {matchingTag.uTags}
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <td>{selectedUser.address || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Bio</th>
                                                    <td>{selectedUser.bio || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Registration Date</th>
                                                    <td>{selectedUser.createdDate || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ verticalAlign: 'start' }}>Profile Photo</th>
                                                    <td style={{ textAlign: 'center', verticalAlign: 'start' }}>
                                                        {selectedUser.u_photo && (
                                                            <div>
                                                                <a
                                                                    href={selectedUser.u_photo}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <img
                                                                        src={selectedUser.u_photo}
                                                                        alt="Profile Picture"
                                                                        style={{
                                                                            width: '50px',
                                                                            margin: '5px',
                                                                            float: 'left',
                                                                        }}
                                                                    />
                                                                </a>

                                                            </div>
                                                        )}
                                                        {!selectedUser?.u_photo && 'N/A'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModalUser}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}

export default UserMaster;
