import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import RevenueAnalytics from "./RevenueAnalytics";
import MiniWidgets from "./MiniWidgets";
import SalesAnalytics from "./SalesAnalytics";
import { useSelector } from "react-redux";
import AdminBarChart from "./AdminBarChart";
import AdminRadioChart from "./AdminRadioChart";
const Dashboard = () => {
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);

  const breadcrumbItems = [
    { title: "Gathrr", link: "/" },
    { title: "Dashboard", link: "/dashboard" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <div className="row">
            <div className="col-12">
              <MiniWidgets />
            </div>
            <div className="col-12 col-sm-12 col-lg-8">
             {userRole==="admin" ?(
                <AdminBarChart/>
             ):(
                <RevenueAnalytics/>

             )}             </div>
            <div className="col-12 col-sm-12 col-lg-4">
            {userRole==="admin" ?( 
                    <AdminRadioChart/>
                ):(
                    <SalesAnalytics />
                )}
              
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
