import { Card, CardHeader, CardBody, CardTitle, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import { AvForm, AvField, AVFormGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { handleStatusChange, handleDelete, handleCheckedInStausChange } from '../../components/Common/ActiveStatusChange';
import { FontAwesomeIcon } from '@fortawesome/fontawesome-free'


import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import React, { useEffect, useState } from 'react';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import Select from "react-select";
import { readExcelFile } from 'react-excel-workbook';
import CSVReader from 'react-csv-reader';
import { useHistory } from 'react-router-dom';
import ActionsDropdown from '../../components/Common/DropDown';
function AttendeesMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenUser, setIsModalOpenUser] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [selectedEventName, setSelectedEventName] = useState('');
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);
    const token = useJwtToken();
    const [importedData, setImportedData] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const [formData, setFormData] = useState({});
    const [eventData, setEventData] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModalUser = (row) => {
        setSelectedUser(row);
        setIsModalOpenUser(true);
    };
    const handleCloseModalUser = () => {
        setIsModalOpenUser(false);
    };

    useEffect(() => {
        fetchAttendeeDetails();
        fetchEventData();
    }, []);

    const fetchAttendeeDetails = async () => {
        try {

            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/attendees`, {
                method: 'GET',
                headers: headers,
            });
            const data = await response.json();
            if (response.ok) {
                if (userRole === 'user') {
                    console.log(data.data)
                    const filteredData = data.data.filter(attendee => {
                        return attendee.eventDetails.some(event => event.organiserMaster === userId);
                    });
                    const dataWithSerialNo = filteredData.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                    const extractedData = filteredData.map(item => ({
                        Event_Name: item.eventDetails[0].eventName,
                        First_Name: item.userDetails[0].fname,
                        Last_Name: item.userDetails[0].lname,
                        Phone: item.userDetails[0].phone,
                        Email: item.userDetails[0].email,
                        createdDate: item.createdDate,
                    }));
                    setExcelData(extractedData);
                } else {
                    const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    const fetchEventData = async () => {

        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/event`, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();

                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    setEventData(filteredData);
                } else {
                    setEventData(data.data);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };
    const handleEventNameChange = (selectedOption) => {
        setSelectedEventName(selectedOption ? selectedOption.value : '');
    };

    const filteredData = userDetails.filter((user) => {
        const { _id } = user;
        const lowercasedEventName = selectedEventName.toLowerCase();

        return (
            (!selectedEventName || user.eventDetails.some(event => event.eventName.toLowerCase().includes(lowercasedEventName)))
        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Attendee Name',
            cell: (row) => (
              <div className="clickable-row" onClick={() => handleOpenModalUser(row)} style={{cursor:'pointer'}}>
                <span>{(row.userDetails[0]?.fname || ' ') + ' ' + (row.userDetails[0]?.lname || ' ') + ' '}</span>
               
              </div>
            ),
            sortable: true,
            width:'250px',
           
          },
   
          
          { name: 'Event Name', selector: (row) => row.eventDetails[0]?.eventName || ' ', sortable: true , width:'250px'},
          { name: 'Phone', selector: (row) => row.userDetails[0]?.phone || ' ', sortable: true , width:'150px'},
          {
            name: 'More Details',
            cell: (row) => (
              <button className='btn btn-primary' onClick={() => handleOpenModalUser(row)}>Details</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '150px',
            style: {
              textAlign: 'center', 
            },
          },
        
          { name: 'Registration Date', selector: (row) => row.createdDate || 'null', sortable: true, width: '175px' },
          {
            name: 'Checked-In',
            cell: (row) => (
              <span style={{ color: row.isScanned === 1 ? 'green' : 'red' }}>
                {row.isScanned === 1 ? 'Yes' : 'No'}
              </span>
            ),
            sortable: true,
          },
          {
            name: 'Active',
            cell: (row) => (
              <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                {row.isActive === 1 ? 'Active' : 'Inactive'}
              </span>
            ),
            sortable: true,
          },
          {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ];

    const getUniqueEventNames = () => {
        const uniqueEventNames = userDetails.reduce((uniqueNames, user) => {
            user.eventDetails.forEach(event => {
                if (!uniqueNames.includes(event.eventName)) {
                    uniqueNames.push(event.eventName);
                }
            });
            return uniqueNames;
        }, []);
        return uniqueEventNames.map(eventName => ({ value: eventName, label: eventName }));
    };


    const handleFileUpload = async (e) => {
        try {
            const uploadedFile = e.target.files[0];
            if (!uploadedFile) return;

            if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || uploadedFile.type === 'application/vnd.ms-excel') {
                const workbookData = await readExcelFile(uploadedFile);
                setImportedData(workbookData);
            } else if (uploadedFile.type === 'text/csv') {
                const csvData = await readCSVFile(uploadedFile);
                setImportedData(csvData);
            } else {
                console.error('Unsupported file format');
            }
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };
    const readCSVFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                const rows = content.split(/\r\n|\n/);
                const data = rows.map(row => row.split(','));
                resolve(data);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    const handleSendValues = async (e) => { 
        e.preventDefault();
        setSubmitting(true);
        try {
           const headerRow = importedData[0];
            const dataRows = importedData.slice(1);
            const transformedData = dataRows.map((row) => {
                const rowData = headerRow.reduce((acc, column, i) => {
                    if (row[i] !== undefined && column !== undefined) {
                        acc[column.replace(/"/g, '')] = row[i].replace(/"/g, ''); 
                    }
                    return acc;
                }, {});
                return rowData;
            });
            const requestData = {
                eventMaster: formData.eventMaster, // Include the selected event name
                importedData: transformedData,
            };
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/event-registration/imported-data`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(requestData),
            });
            const data = await response.json();
            if (response.ok) {
                history.push('/attendees');
                setAlertMessage({ success: 'Event added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the event.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the event.' });
        } finally {
            setSubmitting(false);
        }
    };

    const dummy = [
        { First_Name: 'John', Last_Name: 'Doe', Phone: '123456789', Email: 'john@example.com' },
        { First_Name: 'Jane', Last_Name: 'Smith', Phone: '987154877', Email: 'jane@example.com' },
        { First_Name: 'Alice', Last_Name: 'Johnson', Phone: '5554578454', Email: 'alice@example.com' },
        { First_Name: 'Bob', Last_Name: 'Brown', Phone: '7745789545', Email: 'bob@example.com' },
        { First_Name: 'Eve', Last_Name: 'Evans', Phone: '8884578612', Email: 'eve@example.com' },
    ];


    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/event-registration/${_id}`;
        handleDelete(_id, Page_Url, fetchAttendeeDetails, setAlertMessage, token);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/event-registration/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Event Successfully';
        const errorMessageProp = 'Failed to Event Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchAttendeeDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/event-registration/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Event Successfully';
        const errorMessageProp = 'Failed to Deactivate Event';
        handleStatusChange(isActive, _id, Page_Url, fetchAttendeeDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleCheckedIn = (_id) => {
        const Page_Url = `${BASE_URL}/event-registration/${_id}`;
        const isScanned = 1;
        const successMessageProp = 'User Checked-In Successfully';
        const errorMessageProp = 'User Checked-Out Successfully';
        handleCheckedInStausChange(isScanned, _id, Page_Url, fetchAttendeeDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    }

    const handleCheckedOut = (_id) => {
        const Page_Url = `${BASE_URL}/event-registration/${_id}`;
        const isScanned = 0;
        const successMessageProp = 'User Checked-Out Successfully';
        const errorMessageProp = 'User Checked-In Successfully';
        handleCheckedInStausChange(isScanned, _id, Page_Url, fetchAttendeeDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    }

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'checkedin') {
            handleCheckedIn(row._id);
        } else if (action.toLowerCase() === 'checkedout') {
            handleCheckedOut(row._id);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={false}
            showCheckedIn={true}
            isScanned={row.isScanned}

        />
    );

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const isSelectedUser = Object.keys(selectedUser).length > 0;
  

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Attendees Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6" className="mb-3">
                                    <Row>
                                        <Col md="4" className='d-flex justify-content-end'>
                                            <CSVLink data={excelData} filename='SponsorData' className='btn btn-primary'>Export to Excel</CSVLink>
                                        </Col>
                                        <Col md="4">
                                            <button className='btn btn-primary ' onClick={handleOpenModal}>Import to Excel</button>
                                        </Col>
                                    </Row>
                                </Col>


                                <Col md="6" className="d-flex justify-content-end mb-3">
                                    <div style={{ width: '100%', maxWidth: '500px' }}>
                                        <Select
                                            options={[
                                                { value: "", label: "All Events" },
                                                ...getUniqueEventNames(),
                                            ]}
                                            value={{ value: selectedEventName, label: selectedEventName }}
                                            onChange={handleEventNameChange}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: '20px',
                                                }),
                                                menu: (provided) => ({ ...provided, width: '100%', maxWidth: '500px', position: 'absolute' }), // Set max width to 500px
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>


                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Import Data</ModalHeader>
                <ModalBody>
                    <div className="form-group mb-2">
                        <label htmlFor="eventMaster">Event Name</label>
                        <Select
                            value={formData.eventMaster ? formData.eventMaster.value : null}
                            id="eventMaster"
                            name="eventMaster"
                            onChange={(selectedOption) => {
                                const selectedValue = selectedOption ? selectedOption.value : '';
                                handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                            }}
                            options={eventData
                                .filter((event) => event.isActive === 1)
                                .map((event) => ({
                                    value: event._id,
                                    label: event.eventName,
                                }))
                            }
                            getOptionLabel={(option) => option.label}
                            groupBy={(option) => option.value}
                        />

                    </div>

                    <div className="form-group mt-4 mb-2">
                        <label htmlFor="data">Upload Excel</label>
                        <input
                            type="file"
                            className="form-control"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleFileUpload}
                            style={{ width: '100%' }} 
                        />
                    </div>
                    <div className="form-group mt-4 mb-2">
                        <Card>
                            <CardBody>
                                <label htmlFor="data">Example for reference</label>
                                <div className="table-responsive">
                                    <Table striped className=" mb-0">

                                        <thead>
                                            <tr>
                                                <th>First_Name</th>
                                                <th>Last_Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dummy.map((entry, index) => (
                                                <tr key={index}>
                                                    <td>{entry.First_Name}</td>
                                                    <td>{entry.Last_Name}</td>
                                                    <td>{entry.Phone}</td>
                                                    <td>{entry.Email}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
                    <Button color="primary" onClick={handleSendValues}>Submit</Button>

                </ModalFooter>
            </Modal>

            {isSelectedUser && (
  <Modal isOpen={isModalOpenUser} toggle={handleCloseModalUser}>
    <ModalHeader toggle={handleCloseModalUser}>Attendee Details</ModalHeader>
    <ModalBody>
      <div className="form-group  mb-2">
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table striped className="mb-0">
                <tbody>
                  <tr>
                    <th>Event Name</th>
                    <td>{selectedUser.eventDetails[0]?.eventName || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>First Name</th>
                    <td>{selectedUser.userDetails[0]?.fname || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{selectedUser.userDetails[0]?.lname || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{selectedUser.userDetails[0]?.phone || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{selectedUser.userDetails[0]?.email || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Registration Date</th>
                    <td>{selectedUser.createdDate || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Event Amount</th>
                    <td>{selectedUser.payment_amount || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th>Amount Paid</th>
                    <td>{selectedUser.isPaid ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr>
                    <th>Checked-In</th>
                    <td>{selectedUser.isScanned ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr>
                    <th style={{ verticalAlign: 'middle' }}>Profile Photo</th>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      {selectedUser.userDetails[0]?.u_photo && (
                        <div>
                          <a
                            href={selectedUser.userDetails[0].u_photo}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={selectedUser.userDetails[0].u_photo}
                              alt="Profile Picture"
                              style={{
                                width: '50px',
                                margin: '5px',
                                float: 'left',
                              }}
                            />
                          </a>
                          <p style={{ fontSize: '12px', marginTop: '5px', textAlign: 'center' }}>
                            {selectedUser.userDetails[0].u_photo.split('/').pop()}
                          </p>
                        </div>
                      )}
                      {!selectedUser.userDetails[0]?.u_photo && 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
      <ModalFooter>
        <Button color="secondary" onClick={handleCloseModalUser}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalBody>
  </Modal>
)}

        </div>
    );
}

export default AttendeesMaster;

