import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CSVLink } from 'react-csv';

function CategoryMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            categoryName: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({

        categoryName: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, categoryName: e.target.value });
    };


    const handleAdd = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setSubmitting(true);
        try {
            const form = new FormData();
            form.append('categoryName', formData.categoryName);
            const response = await fetch(`${BASE_URL}/category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ categoryName: formData.categoryName }),
            });
            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    categoryName: '',
                });
                toggleAddModal();
                fetchCategoryDetails();
                history.push('/category');
                setAlertMessage({ success: 'Category added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the category.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the category.' });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchCategoryDetails();
    }, []);

    const fetchCategoryDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/category`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch category details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching category data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            categoryName: value,
        }));
    };

    const handleDeleteClick = (_id) => {
       const Page_Url = `${BASE_URL}/category/${_id}`;
        handleDelete(_id, Page_Url, fetchCategoryDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/category/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Category Successfully';
        const errorMessageProp = 'Failed to Category Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchCategoryDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/category/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Category Successfully';
        const errorMessageProp = 'Failed to Deactivate Category';
        handleStatusChange(isActive, _id, Page_Url, fetchCategoryDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, categoryName } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((categoryName) && (categoryName).toLowerCase().includes(lowercasedSearchText))

        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Category Name', selector: (row) => row.categoryName, sortable: true },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const data = {
                categoryName: selectedUser.categoryName,
            };
            const response = await fetch(`${BASE_URL}/category/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.text();
            if (response.ok) {
                fetchCategoryDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Category Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the category.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the category.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id);
            setSelectedUser(null);
            toggleEditModal();
        }
    };
    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Organiser Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='CategoryData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add User</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="form-group mb-2">
                            <label htmlFor="categoryName">Category Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="categoryName" // Update the name to "categoryName"
                                placeholder="Category Name"
                                value={formData.categoryName}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your category name' },
                                }}
                            />
                        </div>

                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>
                            <div className="form-group mb-2">
                                <label htmlFor="categoryName">Category Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="categoryName"
                                    placeholder="Category Name"
                                    value={selectedUser.categoryName || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your category name' },
                                    }}
                                />
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}

        </div>
    );
}

export default CategoryMaster;
