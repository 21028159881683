import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import Select from "react-select";

function SponsorMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [speakerDetails, setSpeakerDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageModel, setErrorMessageModel] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [eventData, setEventData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenlink, setIsModalOpenlink] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [selectedRowDescription, setSelectedRowDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selectedRowlink, setSelectedRowlink] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);
    const token=useJwtToken();
  
    const handleOpenModal = (row) => {
        setSelectedRowDescription(row.description);
        setIsModalOpen(true);
    };

    const handleOpenModallink = (row) => {
        setSelectedRowlink(row.link);
        setIsModalOpenlink(true);
    };

    const handleCloseModallink = () => {
        setIsModalOpenlink(false);
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            eventMaster: '',
            sponsorName: '',
            link: '',
            companyLogo: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        eventMaster: '',
        sponsorName: '',
        link: '',
        companyLogo: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const form = new FormData();
            form.append('eventMaster', formData.eventMaster);
            form.append('sponsorName', formData.sponsorName);
            form.append('link', formData.link);
            form.append('companyLogo', formData.companyLogo);

            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB

            const file = form.get('companyLogo');

            if (!allowedTypes.includes(file.type)) {
                setAlertMessage({ success: 'Invalid file type. Please upload a PNG, JPG, or JPEG file.', error: '' });
                return;
            }

            if (file.size > maxFileSize) {
                setAlertMessage({ success: 'File size limit exceeded. Please upload a file up to 2MB.', error: '' });
                return;
            }
            const headers = {
                'Authorization': token,
              };

            const response = await fetch(`${BASE_URL}/sponsor`, {
                method: 'POST',
                body: form,
                headers:headers,
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    eventMaster: '',
                    sponsorName: '',
                    link: '',
                    companyLogo: '',
                });
                toggleAddModal();
                fetchSponsorDetails();
                history.push('/sponsor');
                setAlertMessage({ success: 'Sponsor added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the sponsor.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the sponsor.' });
        } finally {
            setSubmitting(false);
        }
    };

    const fetchEventData = async () => {

        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
              };
            const response = await fetch(`${BASE_URL}/event`, {
                method: 'GET',
                headers:headers,
            });
            if (response.ok) {
                const data = await response.json();

                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    setEventData(filteredData);
                } else {
                    setEventData(data.data);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    useEffect(() => {
        fetchSponsorDetails();
        fetchEventData();
    }, []);

    const fetchSponsorDetails = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
              };
            const response = await fetch(`${BASE_URL}/sponsor`, {
                method: 'GET',
                headers:headers,
            });
            const data = await response.json();
            if (response.ok) {
                
                if (userRole === 'user') {

                    const filteredData = data.data.filter(sponsor => {
                        return sponsor.eventDetails.some(event => event.organiserMaster === userId);
                    });
                    const dataWithSerialNo = filteredData.map((item, index) => ({ ...item, srNo: index + 1 }));
          
                    setUserDetails(dataWithSerialNo);
                }else{
                    const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
          
                    setUserDetails(dataWithSerialNo);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch sponsor details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching sponsor data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
        setSpeakerDetails([...speakerDetails]);
    };

    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/sponsor/${_id}`;
        handleDelete(_id, Page_Url, fetchSponsorDetails, setAlertMessage, token);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/sponsor/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Sponsor Successfully';
        const errorMessageProp = 'Failed to Activate Sponsor';
        handleStatusChange(isActive, _id, Page_Url, fetchSponsorDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/sponsor/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Sponsor Successfully';
        const errorMessageProp = 'Failed to Deactivate Sponsor';
        handleStatusChange(isActive, _id, Page_Url, fetchSponsorDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    console.log(userDetails)
    const filteredData = userDetails.filter((user) => {
        const { _id, eventDetails, sponsorName, link, companyLogo } = user;
        const lowercasedSearchText = searchText.toLowerCase();
        const eventName = eventDetails && eventDetails[0] && eventDetails[0].eventName;
        const eventNameLowercased = eventName ? eventName.toLowerCase().trim() : '';
      
        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            (eventNameLowercased && eventNameLowercased.includes(lowercasedSearchText)) ||
            ((sponsorName && sponsorName) && (sponsorName && sponsorName).toLowerCase().includes(lowercasedSearchText)) ||
            (link && link.toLowerCase().includes(lowercasedSearchText))
        );
    });
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Event Name',
            selector: (row) => {
                const event = eventData.find((event) => event._id === row.eventMaster);
                return event ? event.eventName : '';
            },
            sortable: true,
            width:'250px'
        },


        { name: 'Sponsor Name', selector: (row) => row.sponsorName, sortable: true, width:'250px' },
        {
            name: 'link',
            cell: (row) => (
                <button className='btn btn-primary btn-sm' onClick={() => handleOpenModallink(row)}>View link</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

        {
            name: 'Company Logo',
            cell: (row) => {
                const imageUrl = row.companyLogo;
                const imageName = imageUrl ? imageUrl.split('/').pop() : '';
                return (
                    <div>
                        {imageUrl && (
                            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                                <img src={imageUrl} alt="Company Logo" style={{ width: '50px', margin: '5px' }} />
                            </a>
                        )}
                       
                    </div>
                );
            },
            sortable: true,
        },

        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true, width:'175px' },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };


    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };
    const handleEditInputChange = (e) => {
        const { name, value, type } = e.target;

        let selectedValue = value;
        if (type === 'select-multiple') {
            const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOptions.length > 0 ? selectedOptions : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    };





    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const form = new FormData();
            form.append('eventMaster', selectedUser.eventMaster);
            form.append('sponsorName', selectedUser.sponsorName);
            form.append('link', selectedUser.link);
            form.append('companyLogo', selectedUser.companyLogo);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB
            const file = form.get('companyLogo');
            if (!allowedTypes.includes(file.type)) {
                setErrorMessage('Invalid file type. Please upload a PNG, JPG, or JPEG file.');
                return;
            }
            if (file.size > maxFileSize) {
                setErrorMessage('File size limit exceeded. Please upload a file up to 2MB.');
                return;
            }
            const headers = {
                'Authorization': token,
              };
            const response = await fetch(`${BASE_URL}/sponsor/${selectedUser._id}`, {
                method: 'PUT',
                body: form,
                headers:headers,
            });

            if (response.ok) {
                fetchSponsorDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Sponsor Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the sponsor.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the sponsor.' });
        } finally {
            setSubmitting(false);
        }
    };


    const handleSponsorBannerChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB

        setFormData((prevFormData) => ({
            ...prevFormData,
            companyLogo: file,
        }));

        if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
            console.log('Valid file:', file);
            setAlertMessage({ success: '', error: '' });
        } else {
            setAlertMessage({ success: 'Invalid file. Please upload a PNG, JPG, or JPEG file within 2MB.', error: '' });
        }
    };

    const handleEditSponsorBannerChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB

        if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
            console.log('Valid file:', file);
            setErrorMessage('');
            setSelectedUser((prevUser) => ({
                ...prevUser,
                companyLogo: file,
            }));
        } else {
            setAlertMessage({ success: 'Invalid file. Please upload a PNG, JPG, or JPEG file within 2MB.', error: '' });
        }
    };



    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Sponsor Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='SponsorData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Sponsor</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="form-group mb-2">
                            <label htmlFor="eventMaster">Event Name</label>
                            <Select
                                value={formData.eventMaster ? formData.eventMaster.value : null}
                                id="eventMaster"
                                name="eventMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                }}
                                options={eventData
                                    .filter((event) => event.isActive === 1)
                                    .map((event) => ({
                                        value: event._id,
                                        label: event.eventName,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                                className={isFormSubmitted && !formData.eventMaster ? 'is-invalid' : ''}
                            />
                            {isFormSubmitted && !formData.eventMaster && (
                                <div className="invalid-feedback">Please select an event type</div>
                            )}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="sponsorName">Sponsor Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="sponsorName"
                                placeholder="Sponsor Name"
                                value={formData.sponsorName}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="link">link</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="link"
                                placeholder="link"
                                value={formData.link}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your link' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <div className="prev-image-container">
                                        <img
                                            src="static/media/avatar-4.jpg"
                                            style={{ width: '50px', height: '70px' }}
                                            alt="Profile"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-2 mt-4">
                                    <label htmlFor="companyLogo" className="custom-file-label">
                                        Company Logo
                                        <input
                                            type="file"
                                            className=" form-control"
                                           
                                            id="companyLogo"
                                            name="companyLogo"
                                            onChange={handleSponsorBannerChange}
                                            style={{ width: '100%' }}
                                            required={formSubmitted && !formData.companyLogo}
                                        />

                                        {formSubmitted && !selectedUser.companyLogo && (
                                            <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                        )}
                                    </label>
                                    {selectedUser.companyLogo && (
                                        <div className="text-success">File uploaded successfully.</div>
                                    )}
                                    <small className="form-text text-muted mt-2">
                                        Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                        image icon size: 300x300px.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Sponsor</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>

                            <div className="form-group mb-2">
                                <label htmlFor="eventMaster">Event Name</label>
                                <Select
                                    value={selectedUser.eventMaster ? selectedUser.eventMaster.value : null}
                                    id="eventMaster"
                                    name="eventMaster"
                                    onChange={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : '';
                                        handleEditInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                    }}
                                    options={eventData
                                        .filter((event) => event.isActive === 1)
                                        .map((event) => ({
                                            value: event._id,
                                            label: event.eventName,
                                        }))
                                    }
                                    getOptionLabel={(option) => option.label}
                                    className={isFormSubmitted && !formData.eventMaster ? 'is-invalid' : ''}
                                />
                                {isFormSubmitted && !formData.eventMaster && (
                                    <div className="invalid-feedback">Please select an event type</div>
                                )}
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="sponsorName">Sponsor Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="sponsorName"
                                    placeholder="Sponsor Name"
                                    value={selectedUser.sponsorName}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                    }}
                                />
                            </div>


                            <div className="form-group mb-2">
                                <label htmlFor="link">link</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="link"
                                    placeholder="link"
                                    value={selectedUser.link}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your link' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                                        <div className="prev-image-container">
                                            <img
                                                src="static/media/avatar-4.jpg"
                                                style={{ width: '50px', height: '70px' }}
                                                alt="Profile"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-9 mb-2 mt-4">
                                        <label htmlFor="companyLogo" className="custom-file-label">
                                            Company Logo
                                            <input
                                                type="file"
                                                className=" form-control"
                                                id="companyLogo"
                                                name="companyLogo"
                                                onChange={handleEditSponsorBannerChange}
                                                style={{ width: '100%' }}
                                                required={formSubmitted && !formData.companyLogo}
                                            />

                                            {formSubmitted && !selectedUser.companyLogo && (
                                                <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                            )}
                                        </label>
                                        {selectedUser.companyLogo && (
                                            <div className="text-success">File uploaded successfully.</div>
                                        )}
                                        <small className="form-text text-muted mt-2">
                                            Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                            image icon size: 300x300px.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>

                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Description</ModalHeader>
                <ModalBody>
                    <p>{selectedRowDescription}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpenlink} toggle={handleCloseModallink}>
                <ModalHeader toggle={handleCloseModallink}>Link</ModalHeader>
                <ModalBody>
                    <p>{selectedRowlink}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModallink}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default SponsorMaster;
