import React, { useState, useEffect } from 'react';
import { Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../components/Common/AppConfig';
import { useSelector } from 'react-redux';

const AdminRadioChart = () => {
  const [eventData, setEventData] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const userRole = useSelector((state) => state.roleReducer.userRole);
  const userId = useSelector((state) => state.roleReducer.userId);

  useEffect(() => {
    fetchEventCount();
  }, []);

  const fetchEventCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/admin-radiochart`);
      const data = await response.json();

      if (response.ok) {
        if (userRole === 'user') {
          const userEvent = data.data.eventDetails.find((event) => event._id === userId);
          if (userEvent) {
            setEventData(userEvent.event);
          } else {
            setEventData(data.data.eventDetails);
          }
        } else {
          setEventData(data.data.eventDetails);
        }
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const userCounts = eventData.map((event) => event.categoryCount); // Extract user counts
  const eventNames = eventData.map((event) => event._id); // Extract event names

  // Check if there's no data
  const isNoData = userCounts.length === 0;

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const categoryColors = {};
  const seriesColors = [];
  eventNames.forEach((eventName, index) => {
    const color = getRandomColor();
    categoryColors[eventName] = color;
    seriesColors.push(color);
  });

  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w) {
              // Calculate the total user count from the userCounts array
              const totalUserCount = userCounts.reduce((acc, count) => acc + count, 0);
              return totalUserCount;
            },
          },
        },
      },
    },
    labels: eventNames,
    colors: seriesColors, // Use the same dynamic colors for series
  };

  return (
    <React.Fragment>
      <Card style={{ minHeight: '470px' }}>
        <CardBody>
          <h4 className="card-title mb-4">Event Overview</h4>
          {isNoData ? (
            <div className="text-center">No Data</div>
          ) : (
            <div id="donut-chart" className="apex-charts">
              <ReactApexChart options={options} series={userCounts} type="radialBar" height="350" />
              {/* Use userCounts directly as the series */}
            </div>
          )}
          {isNoData ? null : (
            <div
              style={{
                maxHeight: '150px',
                overflowY: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {eventNames.map((category, index) => (
                <div key={index} style={{ flexBasis: '25%', textAlign: 'center', paddingLeft: '3px' }}>
                  <p className="mb-2 text-truncate">
                    <i
                      className={`mdi mdi-circle font-size-10 me-1`}
                      style={{ color: categoryColors[category] }}
                    ></i>
                    {category}
                  </p>
                </div>
              ))}
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AdminRadioChart;
