import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField, AVFormGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";


import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-multi-date-picker";
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import Select from "react-select";
import moment from 'moment';

function EventMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [errorMessageModel, setErrorMessageModel] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
    const [selectedEndDateTime, setSelectedEndDateTime] = useState(null);
    const [categoryData, setCategoryData] = useState([]);
    const [organiserData, setOrganiserData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedRowDescription, setSelectedRowDescription] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);
    const token = useJwtToken();


    const handleOpenModal = (row) => {
        setSelectedRowDescription(row.description);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            eventName: '',
            categoryMaster: '',
            startDate: null,
            endDate: null,
            customBooking: '',
            generalInfo: '',
            amount: '',
            location: '',
            venue: '',
            organiserMaster: '',
            locationMapLink: '',
            bookingMaxLimit: '',
            description: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        eventName: '',
        categoryMaster: '',
        startDate: null,
        endDate: null,
        customBooking: '',
        generalInfo: '',
        amount: '',
        location: '',
        venue: '',
        organiserMaster: '',
        locationMapLink: '',
        bookingMaxLimit: '',
        description: '',

    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === "radio" ? (checked ? value : "") : value;

        if (name === "paymentOption" && inputValue === "Paid") {
            setFormData({ ...formData, [name]: "Paid", amount: "" });
        } else {
            setFormData({ ...formData, [name]: inputValue });
        }
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setSubmitting(true);
        if (!selectedUser.eventBanner) {
            setAlertMessage({ success: '', error: 'Please upload a file.' });
            setSubmitting(false);
            return;
        }
        if (!selectedStartDateTime || !selectedEndDateTime) {
            setAlertMessage({ success: '', error: 'Please select both Start Date and End Date.' });
            setSubmitting(false);
            return;
        }
        try {
            const form = new FormData();
            form.append('eventName', formData.eventName);
            form.append('categoryMaster', formData.categoryMaster);
            form.append('startDate', selectedStartDateTime);
            form.append('endDate', selectedEndDateTime);
            form.append('customBooking', formData.customBooking);
            form.append('generalInfo', formData.paymentOption);
            form.append('amount', formData.amount);
            form.append('location', formData.location);
            form.append('venue', formData.venue);
            form.append('organiserMaster', formData.organiserMaster);
            form.append('locationMapLink', formData.locationMapLink);
            form.append('bookingMaxLimit', formData.bookingMaxLimit);
            form.append('description', formData.description);
            form.append('eventBanner', selectedUser.eventBanner);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB
            if (!allowedTypes.includes(selectedUser.eventBanner.type)) {
                setAlertMessage({ success: 'Invalid file type. Please upload a PNG, JPG, or JPEG file.', error: '' });
                return;
            }

            if (selectedUser.eventBanner.size > maxFileSize) {
                setAlertMessage({ success: 'File size limit exceeded. Please upload a file up to 2MB.', error: '' });
                return;
            }
            const headers = {
                'Authorization': token,
            };

            const response = await fetch(`${BASE_URL}/event`, {
                method: 'POST',
                body: form,
                headers: headers,

            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    eventName: '',
                    categoryName: '',
                    startDate: '',
                    endDate: '',
                    customBooking: '',
                    generalInfo: '',
                    amount: '',
                    location: '',
                    locationMapLink: '',
                    bookingMaxLink: '',
                    description: '',
                });
                toggleAddModal();
                fetchEventDetails();
                history.push('/events');
                setAlertMessage({ success: 'Event added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the event.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the event.' });
        } finally {
            setSubmitting(false);
        }
    };


    const fetchCategoryData = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/category`, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();
                setCategoryData(data.data);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch category details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching category data' });
            console.error(error);
        }
    };

    const fetchOrganiserData = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/organiser`, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();
                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event._id === userId);
                    setOrganiserData(filteredData);
                } else {
                    setOrganiserData(data.data);
                    console.log(data.data)
                }

            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch organiser details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching organiser data' });
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEventDetails();
        fetchCategoryData();
        fetchOrganiserData();

    }, []);

    const fetchEventDetails = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/event`, {
                method: 'GET',
                headers: headers,
            });
            const data = await response.json();
            if (response.ok) {
                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    const dataWithSerialNo = filteredData.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
               } else {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                }

            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/event/${_id}`;
        handleDelete(_id, Page_Url, fetchEventDetails, setAlertMessage, token);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/event/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Event Successfully';
        const errorMessageProp = 'Failed to Event Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchEventDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/event/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Event Successfully';
        const errorMessageProp = 'Failed to Deactivate Event';
        handleStatusChange(isActive, _id, Page_Url, fetchEventDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const filteredData = userDetails.filter((user) => {
        const { _id, eventName, startDate, endDate, customBooking, generalInfo } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((eventName) && (eventName).toLowerCase().includes(lowercasedSearchText)) ||
            (startDate && startDate.toLowerCase().includes(lowercasedSearchText)) ||
            (endDate && endDate.toLowerCase().includes(lowercasedSearchText)) ||
            (customBooking && customBooking.toLowerCase().includes(lowercasedSearchText)) ||
            (generalInfo && generalInfo.toLowerCase().includes(lowercasedSearchText))
        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Name', selector: (row) => row.eventName, sortable: true, width: '250px' },
        {
            name: 'Category Name',
            selector: (row) => {
                const category = categoryData.find((category) => category._id === row.categoryMaster);
                return category ? category.categoryName : '';
            },
            sortable: true,
            width: '150px'
        },
        { name: 'Start Date', selector: (row) => row.startDate, width: '170px', sortable: true },
        { name: 'End Date', selector: (row) => row.endDate, width: '170px', sortable: true },
        { name: 'Custom Booking', selector: (row) => row.customBooking, sortable: true },
        {
            name: 'General Info',
            selector: (row) => {
                if (row.amount === null) {
                    return row.generalInfo;
                } else {
                    return `${row.generalInfo} Rs ${row.amount}`;
                }
            },
            sortable: true,
            width: '150px'
        },

        {
            name: 'Event Banner',
            cell: (row) => {
                const imageUrl = row.eventBanner;
                const imageName = imageUrl ? imageUrl.split('/').pop() : '';
                return (
                    <div>
                        {imageUrl && (
                            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                                <img src={imageUrl} alt="Profile Picture" style={{ width: '50px', margin: '5px' }} />
                            </a>
                        )}
                       
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: 'Description',
            cell: (row) => (
                <button className='btn btn-primary' onClick={() => handleOpenModal(row)}>View Description</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        { name: 'Created Date', selector: (row) => row.createdDate || '', width: '170px', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };


    const handleEventBannerChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB

        setSelectedUser((prevSelectedUser) => ({
            ...prevSelectedUser,
            eventBanner: file,
            imageName: file ? file.name : '',
        }));

        if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
            setAlertMessage({ success: '', error: '' });
        } else {
            setAlertMessage({ success: 'Invalid file. Please upload a PNG, JPG, or JPEG file within 2MB.', error: '' });
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };
    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const form = new FormData(); // Initialize formData
            form.append('eventName', selectedUser.eventName);
            form.append('categoryMaster', selectedUser.categoryMaster);
            form.append('startDate', selectedStartDateTime);
            form.append('endDate', selectedEndDateTime);
            form.append('customBooking', selectedUser.customBooking);
            form.append('generalInfo', selectedUser.paymentOption); // Update the correct field
            form.append('amount', selectedUser.amount);
            form.append('location', selectedUser.location);
            form.append('venue', selectedUser.venue);
            form.append('organiserMaster', formData.organiserMaster);
            form.append('locationMapLink', selectedUser.locationMapLink);
            form.append('bookingMaxLimit', selectedUser.bookingMaxLimit);
            form.append('description', selectedUser.description);
            form.append('eventBanner', selectedUser.eventBanner);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB

            if (!selectedUser.eventBanner || !allowedTypes.includes(selectedUser.eventBanner.type) || selectedUser.eventBanner.size > maxFileSize) {
                console.log('Invalid file');
                return;
            }
            const headers = {
                'Authorization': token,
            };

            const response = await fetch(`${BASE_URL}/event/${selectedUser._id}`, {
                method: 'PUT',
                body: form,
                headers: headers
            });
            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchEventDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Event Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the event.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the event.' });
        } finally {
            setSubmitting(false);
        }
    };
    const handleStartDateChange = (value) => {
        setSelectedStartDateTime(value);
        const startDatePicker = document.getElementById('startDate');
        startDatePicker.blur();
        const formattedDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
    };

    const handleEndDateChange = (value) => {
        setSelectedEndDateTime(value);
        const endDatePicker = document.getElementById('endDate');
        endDatePicker.blur();
        const formattedDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
    };
    const selectedCategory = categoryData.find((category) => category._id === selectedUser.categoryMaster);
   const labelToDisplay = selectedCategory ? selectedCategory.categoryName : '';

   const selectedOrganiser = organiserData.find((organiser) => organiser._id === selectedUser.organiserMaster);
   const labelToDisplay1 = selectedOrganiser ? selectedOrganiser.fname + ' '+ selectedOrganiser.lname : '';

    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Event Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='EventData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            {!addModalOpen || !editModalOpen && (
                                <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            )}
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Event</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="form-group mb-2">
                            <label htmlFor="eventName">Event Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="eventName"
                                placeholder="Event Name"
                                value={formData.eventName}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your first name' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="categoryMaster">Category Name</label>
                            <Select
                                value={formData.categoryMaster ? formData.categoryMaster.value : null}
                                id="categoryMaster"
                                name="categoryMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'categoryMaster', value: selectedValue } });
                                }}
                                options={categoryData
                                    .filter((category) => category.isActive === 1)
                                    .map((category) => ({
                                        value: category._id,
                                        label: category.categoryName,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                            />

                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="startDate">Start Date</label>
                                    <div className="row">
                                        <div className="col-12">
                                            <DatePicker
                                                className="rmdp-input1 w-100"
                                                format="YYYY-MM-DD HH:mm:ss" // Change the format to 'YYYY-MM-DD HH:mm:ss'
                                                select={false}
                                                plugins={[
                                                    <TimePicker position="bottom" use12hours="false" />, // Use 24-hour format
                                                    <DatePanel markFocused />,
                                                ]}
                                                style={{ height: '35px', maxWidth: '250px' }}
                                                wrapperStyle={{ position: 'relative' }}
                                                inputStyle={{
                                                    height: '100%',
                                                    borderRadius: '4px',
                                                }}
                                                id="startDate"
                                                name="startDate"
                                                placeholder="Start Date"
                                                value={selectedStartDateTime}
                                                onChange={handleStartDateChange}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Please select a start date and time' },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="endDate">End Date</label>
                                    <div className="row">
                                        <div className="col-12">
                                            <DatePicker
                                                className="rmdp-input1 w-100"
                                                format="YYYY-MM-DD HH:mm:ss" // Change the format to 'YYYY-MM-DD HH:mm:ss'
                                                select={false}
                                                plugins={[
                                                    <TimePicker position="bottom" use12hours="false" />, // Use 24-hour format
                                                    <DatePanel markFocused />,
                                                ]}
                                                style={{ height: '35px', maxWidth: '250px' }}
                                                wrapperStyle={{ position: 'relative' }}
                                                inputStyle={{
                                                    height: '100%',
                                                    borderRadius: '4px',
                                                }}
                                                id="endDate"
                                                name="endDate"
                                                placeholder="End Date"
                                                value={selectedEndDateTime}
                                                onChange={handleEndDateChange}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Please select an end date and time' },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label className="col-lg-12 col-form-label" htmlFor="customBooking">
                                        Custom Booking
                                    </label>
                                    <div className="col-lg-10">
                                        <AvRadioGroup name="customBooking" value={formData.customBooking} onChange={handleInputChange} validate={{ required: { value: true, errorMessage: "Custom Booking is required" } }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <AvRadio label="Yes" value="Yes" />
                                                </div>
                                                <div className="col-6">
                                                    <AvRadio label="No" value="No" />
                                                </div>
                                            </div>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label className="col-lg-12 col-form-label" htmlFor="customBooking">
                                        General Info
                                    </label>
                                    <div className="col-lg-10">
                                        <AvRadioGroup name="paymentOption" onChange={handleInputChange} value={formData.paymentOption} validate={{ required: { value: true, errorMessage: "Payment Option is required" } }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <AvRadio label="Free" value="Free" />
                                                </div>
                                                <div className="col-6">
                                                    <AvRadio label="Paid" value="Paid" />
                                                </div>
                                            </div>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {formData.paymentOption === "Paid" && (
                            <div className="col-lg-12">
                                <div className="form-group mb-2">
                                    <label htmlFor="amount">Amount</label>
                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="amount"
                                        placeholder="Amount"
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: "Amount is required" },
                                            min: { value: 1, errorMessage: "Amount must be greater than 0" },
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group mb-2">
                            <label htmlFor="location">Location</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="location"
                                placeholder="location "
                                value={formData.location}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your location' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="venue">Venue</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="venue"
                                placeholder="venue"
                                value={formData.venue}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your venue' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="organiserMaster">Organiser Name</label>
                            <Select
                                value={formData.organiserMaster ? formData.organiserMaster.value : null}
                                id="organiserMaster"
                                name="organiserMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'organiserMaster', value: selectedValue } });
                                }}
                                options={organiserData
                                    .filter((organiser) => organiser.isActive === 1)
                                    .map((organiser) => ({
                                        value: organiser._id,
                                        label: organiser.fname+' '+ organiser.lname,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                            />

                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="locationMapLink">Location Map Link</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="locationMapLink"
                                placeholder="locationMapLink "
                                value={formData.locationMapLink}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Location Map Link' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="bookingMaxLimit ">Booking Max Limit </label>
                            <AvField
                                type="number"
                                className="form-control"
                                name="bookingMaxLimit"
                                placeholder="bookingMaxLimit "
                                value={formData.bookingMaxLimit}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Booking Max Limit' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="description">Description</label>
                            <AvField
                                type="textarea"
                                className="form-control"
                                name="description"
                                placeholder="description"
                                maxLength="1200"
                                rows="3"

                                value={formData.description}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Description Map Link' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <div className="prev-image-container">
                                        <img
                                            src="static/media/avatar-4.jpg"
                                            style={{ width: '50px', height: '70px' }}
                                            alt="Profile"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-2 mt-4">
                                    <label htmlFor="eventBanner" >
                                        Profile Picture
                                        <input
                                            type="file"
                                            className=" form-control"
                                            id="eventBanner"
                                            name="eventBanner"
                                            onChange={handleEventBannerChange}
                                            style={{ width: '100%' }}

                                            required={formSubmitted && !selectedUser.eventBanner}
                                        />
                                        {formSubmitted && !selectedUser.eventBanner && (
                                            <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                        )}
                                    </label>
                                    {selectedUser.eventBanner && (
                                        <div className="text-success">File uploaded successfully.</div>
                                    )}
                                    <small className="form-text text-muted mt-2">
                                        Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                        image icon size: 300x300px.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Event</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>
                            <div className="form-group mb-2">
                                <label htmlFor="eventName">Event Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="eventName"
                                    placeholder="Event Name"
                                    value={selectedUser.eventName || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your first name' },
                                    }}
                                />
                            </div>
                            
                        <div className="form-group mb-2">
                                <label htmlFor="categoryMaster">Category Name</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="categoryMaster"
                                    placeholder="Select Role"
                                    value={{ value: selectedUser.categoryMaster, label: labelToDisplay }}
                                    options={categoryData
                                        .filter((category) => category.isActive === 1)
                                        .map((category) => ({
                                            value: category._id,
                                            label: category.categoryName,
                                        }))
                                    }
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, categoryMaster: selectedOption ? selectedOption.value : '' });
                                        setSelectedUser({ ...selectedUser, categoryMaster: selectedOption ? selectedOption.value : '' });
                                    }}
                                />
                            </div>
                            
                          
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="startDate">Start Date</label>
                                        <div className="row">
                                            <div className="col-12">
                                                <DatePicker
                                                    className="rmdp-input1 w-100"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    select={false}
                                                    plugins={[
                                                        <TimePicker position="bottom" use12hours="false" />,
                                                        <DatePanel markFocused />,
                                                    ]}
                                                    style={{ height: '35px', maxWidth: '250px' }}
                                                    wrapperStyle={{ position: 'relative' }}
                                                    inputStyle={{
                                                        height: '100%',
                                                        borderRadius: '4px',
                                                    }}
                                                    id="startDate"
                                                    name="startDate"
                                                    placeholder="Start Date"
                                                    value={selectedStartDateTime}
                                                    onChange={handleStartDateChange}
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Please select a start date and time' },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="endDate">End Date</label>
                                        <div className="row">
                                            <div className="col-12">
                                                <DatePicker
                                                    className="rmdp-input1 w-100"
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    select={false}
                                                    plugins={[
                                                        <TimePicker position="bottom" use12hours="false" />,
                                                        <DatePanel markFocused />,
                                                    ]}
                                                    style={{ height: '35px', maxWidth: '250px' }}
                                                    wrapperStyle={{ position: 'relative' }}
                                                    inputStyle={{
                                                        height: '100%',
                                                        borderRadius: '4px',
                                                    }}
                                                    id="endDate"
                                                    name="endDate"
                                                    placeholder="End Date"
                                                    value={selectedEndDateTime}
                                                    onChange={handleEndDateChange}
                                                    validate={{
                                                        required: { value: true, errorMessage: 'Please select an end date and time' },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label className="col-lg-12 col-form-label" htmlFor="customBooking">
                                            Custom Booking
                                        </label>
                                        <div className="col-lg-10">
                                            <AvRadioGroup name="customBooking" value={selectedUser.customBooking} onChange={handleEditInputChange} validate={{ required: { value: true, errorMessage: "Custom Booking is required" } }}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <AvRadio label="Yes" value="Yes" />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvRadio label="No" value="No" />
                                                    </div>
                                                </div>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label className="col-lg-12 col-form-label" htmlFor="generalInfo">
                                            General Info
                                        </label>
                                        <div className="col-lg-10">
                                            <AvRadioGroup name="paymentOption" onChange={handleEditInputChange} value={selectedUser.paymentOption} validate={{ required: { value: true, errorMessage: "Payment Option is required" } }}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <AvRadio label="Free" value="Free" />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvRadio label="Paid" value="Paid" />
                                                    </div>
                                                </div>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {formData.paymentOption === "Paid" && (
                                <div className="col-lg-12">
                                    <div className="form-group mb-2">
                                        <label htmlFor="amount">Amount</label>
                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="amount"
                                            placeholder="Amount"
                                            value={selectedUser.amount}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: "Amount is required" },
                                                min: { value: 1, errorMessage: "Amount must be greater than 0" },
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-group mb-2">
                                <label htmlFor="location">Location</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="location"
                                    placeholder="location "
                                    value={selectedUser.location || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your location' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="venue">Venue</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="venue"
                                    placeholder="venue"
                                    value={selectedUser.venue || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your venue' },
                                    }}
                                />
                            </div>
                            
                                            
                            <div className="form-group mb-2">
                                <label htmlFor="organiserMaster">Organiser Name</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="organiserMaster"
                                    placeholder="Select Role"
                                    value={{ value: selectedUser.organiserMaster, label: labelToDisplay1 }}
                                    options={organiserData
                                        .filter((organiser) => organiser.isActive === 1)
                                        .map((organiser) => ({
                                            value: organiser._id,
                                            label: organiser.fname+' '+ organiser.lname,
                                        }))
                                    }
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, organiserMaster: selectedOption ? selectedOption.value : '' });
                                        setSelectedUser({ ...selectedUser, organiserMaster: selectedOption ? selectedOption.value : '' });
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="locationMapLink">Location Map Link</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="locationMapLink"
                                    placeholder="locationMapLink "
                                    value={selectedUser.locationMapLink || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Location Map Link' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="bookingMaxLimit ">Booking Max Limit </label>
                                <AvField
                                    type="number"
                                    className="form-control"
                                    name="bookingMaxLimit"
                                    placeholder="bookingMaxLimit "
                                    value={selectedUser.bookingMaxLimit || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Booking Max Limit' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="description">Description</label>
                                <AvField
                                    type="textarea"
                                    className="form-control"
                                    name="description"
                                    placeholder="description"
                                    maxLength="1200"
                                    rows="3"

                                    value={selectedUser.description || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Description Map Link' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                                        <div className="prev-image-container">
                                            <img src="static/media/avatar-4.jpg" style={{ width: "50px", height: "70px" }} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 mb-2 mt-4">
                                        <label htmlFor="eventBanner" className="custom-file-label">
                                            Profile Picture
                                            <input
                                                type="file"
                                                className=" form-control"
                                                id="eventBanner"
                                                name="eventBanner"
                                                onChange={handleEventBannerChange}
                                                style={{ width: "100%" }}
                                            />
                                            {formSubmitted && !selectedUser.eventBanner && (
                                                <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                            )}
                                        </label>
                                        {selectedUser.eventBanner && (
                                            <div className="text-success">File uploaded successfully.</div>
                                        )}
                                        <small className="form-text text-muted mt-2">
                                            Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy image icon size: 300x300px.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />

                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>

                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>

                            </ModalFooter>
                        </AvForm>
                    </ModalBody>

                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Description</ModalHeader>
                <ModalBody>
                    <p>{selectedRowDescription}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default EventMaster;
