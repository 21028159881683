import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate

import { Provider } from 'react-redux';
import {store, persistor} from './store';

const userRole=localStorage.getItem('userRole')
const app = (
    <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <App userRole={userRole}/>
        </BrowserRouter>
        </PersistGate>
    </Provider>
);


ReactDOM.render(app, document.getElementById('root'));
