import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Logout extends Component {

  
    render() {
        return (
            <React.Fragment>
               <h1>Logout</h1>
            </React.Fragment>
        );
    }
}

export default withRouter((Logout));

