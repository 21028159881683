import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
const SidebarContent = ({ t, location }) => {
    const [isCondensed, setIsCondensed] = useState(window.innerWidth <= 767);
    const userRole = useSelector((state) => state.roleReducer.userRole);



    useEffect(() => {

        initMenu();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (location && location.pathname) {
            const matchingMenuItem = findMatchingMenuItem(location.pathname);
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
    }, [location]);

    const handleResize = () => {
        setIsCondensed(window.innerWidth <= 767);
    };

    const initMenu = () => {
        new MetisMenu("#side-menu");
    };

    const findMatchingMenuItem = (pathname) => {
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (pathname === items[i].pathname) {
                return items[i];
            }
        }
        return null;
    };

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active");
                    parent3.childNodes[0].classList.add("mm-active");
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
        }
    };

    const sidebarMenuStyle = isCondensed
        ? {
            marginTop: "70px", // Add margin from the top if the sidebar is in condensed view
        }
        : {};

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }}>

                <div id="sidebar-menu" style={sidebarMenuStyle}>
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{t('')}</li>
                        {userRole === "admin" ? (
                            <>
                                <li>
                                    <Link to="/dashboard" className="waves-effect">
                                        <i className="ri-dashboard-line"></i>
                                        {!isCondensed && <span className="ms-1">{t('Dashboard')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/usermaster" className=" waves-effect">
                                        <i className=" fas fa-users"></i>

                                        {!isCondensed && <span className="ms-1">{t('User Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/organiser" className=" waves-effect">
                                        <i className=" fas fa-user"></i>

                                        {!isCondensed && <span className="ms-1">{t('Organiser Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/usermaster" className=" waves-effect">
                                        <i className=" fas fa-user-tie"></i>

                                        {!isCondensed && <span className="ss-1">{t('Exhibition Master')}</span>}
                                    </Link>
                                </li>


                                <li>
                                    <Link to="/category" className=" waves-effect">
                                        <i className="far fa-calendar-plus"></i>

                                        {!isCondensed && <span className="ms-1">{t('Category Master')}</span>}
                                    </Link>
                                </li>


                                <li>
                                    <Link to="/events" className=" waves-effect">
                                        <i className="ri-calendar-2-line"></i>

                                        {!isCondensed && <span className="ms-1">{t('Event Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/attendees" className=" waves-effect">
                                        <i className=" fas ri-qr-code-line"></i>

                                        {!isCondensed && <span className="ms-1">{t('Attendee Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/speaker" className=" waves-effect">
                                        <i className=" fas fa-chalkboard-teacher"></i>

                                        {!isCondensed && <span className="ms-1">{t('Speaker Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/agenda" className=" waves-effect">
                                        <i className=" fas fa-file-signature"></i>

                                        {!isCondensed && <span className="ms-1">{t('Agenda Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/sponsor" className=" waves-effect">
                                        <i className="fas fa-handshake"></i>

                                        {!isCondensed && <span className="ms-1">{t('Sponsor Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/tags" className=" waves-effect">
                                        <i className="fas fa-tag"></i>

                                        {!isCondensed && <span className="ms-1">{t('Tag Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/usertags" className=" waves-effect">
                                        <i className="fas fa-user-tag"></i>

                                        {!isCondensed && <span className="ms-1">{t('User Tag Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/usermaster" className=" waves-effect">
                                        <i className=" fas fa-user-friends"></i>

                                        {!isCondensed && <span className="ms-1">{t('Network Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/usermaster" className=" waves-effect">
                                        <i className=" fas fa-book-reader"></i>

                                        {!isCondensed && <span className="ms-1">{t('Plan Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/poll" className=" waves-effect">
                                        <i className=" fas fa-chart-bar"></i>

                                        {!isCondensed && <span className="ms-1">{t('Poll Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/notification" className=" waves-effect">
                                        <i className=" fas fa-comment"></i>
                                        {!isCondensed && <span className="ms-1">{t('Notification Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/enquries" className=" waves-effect">
                                        <i className="fas fa-bell"></i>

                                        {!isCondensed && <span className="ms-1">{t('All Enquries')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/welcome" className=" waves-effect">
                                        <i className=" fas ri-whatsapp-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Welcome Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/dynamicform" className=" waves-effect">
                                        <i className=" fas  ri-file-user-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Create Form')}</span>}
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/chat" className=" waves-effect">
                                        <i className=" ri-chat-3-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Chat Master')}</span>}
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/password" className=" waves-effect">
                                        <i className=" fas fa-users-cog"></i>

                                        {!isCondensed && <span className="ms-1">{t('Change Password')}</span>}
                                    </Link>
                                </li></>
                        ) : (

                            <>
                                <li>
                                    <Link to="/dashboard" className="waves-effect">
                                        <i className="ri-dashboard-line"></i>
                                        {!isCondensed && <span className="ms-1">{t('Dashboard')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/attendees" className=" waves-effect">
                                        <i className=" fas ri-qr-code-line"></i>

                                        {!isCondensed && <span className="ms-1">{t('Attendee Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/events" className=" waves-effect">
                                        <i className="ri-calendar-2-line"></i>

                                        {!isCondensed && <span className="ms-1">{t('Event Master')}</span>}
                                    </Link>
                                </li>


                                <li>
                                    <Link to="/speaker" className=" waves-effect">
                                        <i className=" fas fa-chalkboard-teacher"></i>

                                        {!isCondensed && <span className="ms-1">{t('Speaker Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/agenda" className=" waves-effect">
                                        <i className=" fas fa-file-signature"></i>

                                        {!isCondensed && <span className="ms-1">{t('Agenda Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/sponsor" className=" waves-effect">
                                        <i className="fas fa-handshake"></i>

                                        {!isCondensed && <span className="ms-1">{t('Sponsor Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/poll" className=" waves-effect">
                                        <i className=" fas fa-chart-bar"></i>

                                        {!isCondensed && <span className="ms-1">{t('Poll Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/notification" className=" waves-effect">
                                        <i className=" fas fa-comment"></i>

                                        {!isCondensed && <span className="ms-1">{t('Notification Master')}</span>}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/welcome" className=" waves-effect">
                                        <i className=" fas ri-whatsapp-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Welcome Master')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/dynamicform" className=" waves-effect">
                                        <i className=" fas ri-file-user-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Create Form')}</span>}
                                    </Link>
                                </li>
            
                                {/* <li>
                                    <Link to="/chat" className=" waves-effect">
                                        <i className="ri-chat-3-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Chat Master')}</span>}
                                    </Link>
                                </li> */}
                         
                                <li>
                                    <Link to="/password" className=" waves-effect">
                                        <i className=" fas fa-users-cog"></i>

                                        {!isCondensed && <span className="ms-1">{t('Change Password')}</span>}
                                    </Link>
                                </li>
                            </>


                        )}

                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
}




export default withRouter(withNamespaces()(SidebarContent));