import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button} from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function PasswordMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);



    useEffect(() => {
        fetchOrganiserDetails();
    }, []);

    const fetchOrganiserDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/organiser`);
            const data = await response.json();
            if (response.ok) {
                if (userRole === 'user') {
                    const filteredData = data.data.filter(organiser => organiser._id === userId);
                    setUserDetails(filteredData);
                    console.log(filteredData);
                } else {
                    setUserDetails(data.data);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch organiser details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching organiser data' });
            console.error(error);
        }
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
            setValidationMessage('Please fill in all fields.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setValidationMessage('New password and confirm password must match.');
            return;
        }

        try {
            const requestBody = {
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
            };

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            };
            const response = await fetch(`${BASE_URL}/organiser/password/${userId}`, requestOptions);

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.status === false) {
                    console.log('responseData.error:', responseData.error);
                    setAlertMessage({ success: 'wrong password', error: responseData.error });
                } else {
                    fetchOrganiserDetails();
                    setAlertMessage({ success: 'Organiser Password updated successfully!', error: '' });
                }
            } else {
                setAlertMessage({ success: 'Old password is incorrect' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while updating the organiser.' });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="content ">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Change Password</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-4'>
                            <Row>
                                <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />

                            </Row>
                            <Row className='d-flex justify-content-center align-items-center mt-5 mb-5'>
                                <Col md="4">
                                    <AvForm onValidSubmit={handleEditUser}>
                                        <div className="form-group mb-2">

                                            <AvField
                                                type="password"
                                                name="oldPassword"
                                                label="Old Password"
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mb-2">

                                            <AvField
                                                type="password"
                                                name="newPassword"
                                                label="New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mb-2">

                                            <AvField
                                                type="password"
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mt-3">

                                            <Button color="primary" type="submit" disabled={submitting}>
                                                {submitting ? "Changing Pasword..." : "Change Password"}
                                            </Button>
                                        </div>
                                        {validationMessage && (
                                            <div className="text-danger mt-2">{validationMessage}</div>
                                        )}
                                    </AvForm>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Col>
            </Row>

        </div>
    );
}

export default PasswordMaster;
