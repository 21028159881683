import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useHistory } from 'react-router-dom';

import { CSVLink } from 'react-csv';

function TagsMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [speakerDetails, setSpeakerDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenlink, setIsModalOpenlink] = useState(false);
    const [selectedRowDescription, setSelectedRowDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selectedRowlink, setSelectedRowlink] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

    const handleOpenModal = (row) => {
        setSelectedRowDescription(row.description);
        setIsModalOpen(true);
    };

    const handleOpenModallink = (row) => {
        setSelectedRowlink(row.link);
        setIsModalOpenlink(true);
    };

    const handleCloseModallink = () => {
        setIsModalOpenlink(false);
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            eventTag: '',
            pollTag: '',
            agendaTag: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        eventTag: '',
        pollTag: '',
        agendaTag: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const requestBody = {
                eventTag: formData.eventTag,
                pollTag: formData.pollTag,
                agendaTag: formData.agendaTag,

            };
            const response = await fetch(`${BASE_URL}/tags`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    categoryName: '',
                });
                toggleAddModal();
                fetchTagsDetails();
                history.push('/tags');
                setAlertMessage({ success: 'Tags added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the tags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the tags.' });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchTagsDetails();
    }, []);

    const fetchTagsDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/tags`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch tags details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching tags data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
        setSpeakerDetails([...speakerDetails]);
    };

    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/tags/${_id}`;
        handleDelete(_id, Page_Url, fetchTagsDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/tags/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Tags Successfully';
        const errorMessageProp = 'Failed to Tags Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchTagsDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/Tags/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Tags Successfully';
        const errorMessageProp = 'Failed to Deactivate Tags';
        handleStatusChange(isActive, _id, Page_Url, fetchTagsDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, title, eventDate, endTime, startTime, fname, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((title) && (title).toLowerCase().includes(lowercasedSearchText)) ||
            ((fname && lname) && (fname && lname).toLowerCase().includes(lowercasedSearchText)) ||
            (eventDate && eventDate.toLowerCase().includes(lowercasedSearchText)) ||
            (startTime && startTime.toLowerCase().includes(lowercasedSearchText)) ||
            (endTime && endTime.toLowerCase().includes(lowercasedSearchText))
        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Event Tag', selector: (row) => row.eventTag, sortable: true },
        { name: 'Poll Tag', selector: (row) => row.pollTag, sortable: true },
        { name: 'Agenda Tag', selector: (row) => row.agendaTag, sortable: true },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        let selectedValue = value;
        if (e.target.nodeName === 'SELECT') {
            const selectedOption = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOption.length > 0 ? selectedOption : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const requestBody = {
                eventTag: selectedUser.eventTag,
                pollTag: selectedUser.pollTag,
                agendaTag: selectedUser.agendaTag,
                createdDate: selectedUser.createdDate, // Include the createdDate field
            };
            const response = await fetch(`${BASE_URL}/tags/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            if (response.ok) {
                fetchTagsDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Tags Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the tags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the tags.' });
        } finally {
            setSubmitting(false);
        }
    };



    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Tags Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='SponsorData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Agenda</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>

                        <div className="form-group mb-2">
                            <label htmlFor="eventTag">Sponsor Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="eventTag"
                                placeholder="Sponsor Name"
                                value={formData.eventTag || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="pollTag">Poll Tag</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="pollTag"
                                placeholder="Poll Tag"
                                value={formData.pollTag || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your pollTag' },
                                }}
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="agendaTag">Agenda Tag</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="agendaTag"
                                placeholder="Agenda Tag"
                                value={formData.agendaTag || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Agenda Tag' },
                                }}
                            />
                        </div>


                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Tags</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>

                            <div className="form-group mb-2">
                                <label htmlFor="eventTag">Sponsor Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="eventTag"
                                    placeholder="Sponsor Name"
                                    value={selectedUser.eventTag || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Sponsor Name' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="pollTag">Poll Tag</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="pollTag"
                                    placeholder="Poll Tag"
                                    value={selectedUser.pollTag || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your pollTag' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="agendaTag">Agenda Tag</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="agendaTag"
                                    placeholder="Agenda Tag"
                                    value={selectedUser.agendaTag || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Agenda Tag' },
                                    }}
                                />
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Description</ModalHeader>
                <ModalBody>
                    <p>{selectedRowDescription}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpenlink} toggle={handleCloseModallink}>
                <ModalHeader toggle={handleCloseModallink}>Link</ModalHeader>
                <ModalBody>
                    <p>{selectedRowlink}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModallink}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default TagsMaster;
