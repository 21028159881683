import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// i18n
import { withNamespaces } from "react-i18next";
import { useSelector } from 'react-redux';
// users
import avatar2 from '../../../assets/images/users/avatar-4.jpg';

const ProfileMenu = ({ t }) => {

    const userPhoto = useSelector((state) => state.roleReducer.o_photo);
    const userName = useSelector((state) => state.roleReducer.userName);
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu((prevMenu) => !prevMenu);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("sessionToken");
    sessionStorage.removeItem("sessionExpiration");
    window.location.href = "/"; // Replace "/" with the desired URL
  };

  let username = userName;
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const uNm = obj.email.split("@")[0];
      username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
        <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
          <img className="rounded-circle header-profile-user me-1" src={userPhoto} alt="Header Avatar" />
          <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem divider /> */}
          <DropdownItem className="text-danger" onClick={handleLogout}><i className="ri-shut-down-line align-middle me-1 text-danger"></i> {t('Logout')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withNamespaces()(ProfileMenu);
