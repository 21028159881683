import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import { AvForm, AvField } from "availity-reactstrap-validation";
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Select from "react-select";

import { useSelector } from 'react-redux';

function NotificationMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageModel, setErrorMessageModel] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [selectedRowAboutUs, setSelectedRowAboutUs] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);

    const handleOpenModal = (row) => {
        setSelectedRowAboutUs(row.message);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            eventMaster: '',
            title: '',
            type: '',
            custom: '',
            message: '',
            organiserMaster: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        eventMaster: '',
        title: '',
        type: '',
        custom: '',
        message: '',
        organiserMaster:''
    });


    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setSubmitting(true);
        if (!selectedUser.notificationImage) {
            setErrorMessageModel('Please upload a file.');
            return;
        }
        try {
            const form = new FormData();
            form.append('title', formData.title);
            form.append('type', formData.type);
            form.append('custom', formData.custom);
            form.append('eventMaster', formData.eventMaster);
            form.append('message', formData.message);
            form.append('notificationImage', selectedUser.notificationImage);
            form.append('organiserMaster', userId);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB
          if (!allowedTypes.includes(selectedUser.notificationImage.type)) {
                setAlertMessage({ success: 'Invalid file type. Please upload a PNG, JPG, or JPEG file.', error: '' });
                return;
            }

            if (selectedUser.notificationImage.size > maxFileSize) {
                setAlertMessage({ success: 'File size limit exceeded. Please upload a file up to 2MB.', error: '' });
                return;
            }

            const response = await fetch(`${BASE_URL}/notification`, {
                method: 'POST',
                body: form,
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    eventMaster: '',
                    title: '',
                    type: '',
                    custom: '',
                    message: '',
                });
                toggleAddModal();
                fetchNotificationDetails();
                history.push('/notification');
                setAlertMessage({ success: 'Notification added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the notification.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the notification.' });
        } finally {
            setSubmitting(false);
        }
    };

  
    const fetchEventData = async () => {
        try {
          const response = await fetch(`${BASE_URL}/event`);
          if (response.ok) {
            const data = await response.json();
            if (userRole === 'user') {
              const filteredData = data.data.filter(event => event.organiserMaster === userId);
              setEventData(filteredData);
              console.log("Event Data:", filteredData);
            } else {
              setEventData(data.data);
            }
          } else {
            setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
          }
        } catch (error) {
          setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
          console.error(error);
        }
      };
      
    useEffect(() => {
        fetchNotificationDetails();
       
        fetchEventData();
    }, []);

    const fetchNotificationDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/notification`);
            const data = await response.json();
            if (response.ok) {
              
                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    const dataWithSerialNo = filteredData.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                } else {
                    const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                    setUserDetails(dataWithSerialNo);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch notification details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching notification data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const handleDeleteClick = (_id) => {
        const Page_Url = `${BASE_URL}/notification/${_id}`;
        handleDelete(_id, Page_Url, fetchNotificationDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/notification/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Event Successfully';
        const errorMessageProp = 'Failed to Event Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchNotificationDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/notification/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Event Successfully';
        const errorMessageProp = 'Failed to Deactivate Event';
        handleStatusChange(isActive, _id, Page_Url, fetchNotificationDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, eventDetails, title, type, custom, } = user;
        const lowercasedSearchText = searchText.toLowerCase();
        const eventName = eventDetails && eventDetails[0] && eventDetails[0].eventName;
        const eventNameLowercased = eventName ? eventName.toLowerCase().trim() : '';
      
        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            (eventNameLowercased && eventNameLowercased.includes(lowercasedSearchText)) ||
            ((title && type) && (title && type).toLowerCase().includes(lowercasedSearchText)) ||
            (custom && custom.toLowerCase().includes(lowercasedSearchText))
        );
    });

    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        { name: 'Title', selector: (row) => row.title, sortable: true ,  width:'200px'},
        { name: 'Type', selector: (row) => row.type, sortable: true },
        {
            name: 'Name',
            selector: (row) => {
              if (row.type === "Event") {
                const event = eventData.find((event) => event._id === row.eventMaster);
                return event ? event.eventName : '';
              } else {
                return row.custom || '';
              }
            },
            sortable: true,
            width:'250px'
          },
          {
            name: 'Message',
            cell: (row) => (
                <button className='btn btn-primary' onClick={() => handleOpenModal(row)}>Message</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Notification Image',
            cell: (row) => {
                const imageUrl = row.notificationImage;
                const imageName = imageUrl ? imageUrl.split('/').pop() : '';
                return (
                    <div>
                        {imageUrl && (
                            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                                <img src={imageUrl} alt="Profile Picture" style={{ width: '50px', margin: '5px' }} />
                            </a>
                        )}
                       
                    </div>
                );
            },
            sortable: true,
        },
     
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Send',
            cell: (row) => renderActionsDropdown1(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
          
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const renderActionsDropdown1 = (row) => (
        <Button color="primary" onClick={() => handleNotification(row._id)}>Send</Button>
    );
    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id);
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const handleNotificationChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB

        setSelectedUser((prevSelectedUser) => ({
            ...prevSelectedUser,
            notificationImage: file,
            imageName: file ? file.name : '',
        }));

        if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
            console.log('Valid file:', file);
            setAlertMessage({ success: '', error: '' });
        } else {
            console.log('Invalid file');
            setAlertMessage({ success: 'Invalid file. Please upload a PNG, JPG, or JPEG file within 2MB.', error: '' });
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };
    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const form = new FormData();
            form.append('title', selectedUser.title);
            form.append('type', selectedUser.type);
            form.append('custom', selectedUser.custom);
            form.append('eventMaster', selectedUser.eventMaster);
            form.append('message', selectedUser.message);
            form.append('notificationImage', selectedUser.notificationImage);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB

            if (!selectedUser.notificationImage || !allowedTypes.includes(selectedUser.notificationImage.type) || selectedUser.notificationImage.size > maxFileSize) {
                console.log('Invalid file');
                return;
            }

            const response = await fetch(`${BASE_URL}/notification/${selectedUser._id}`, {
                method: 'PUT',
                body: form,
            });
            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchNotificationDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Notification Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the notification.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the notification.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleNotification = async (_id) => {
        setSubmitting(true);
        try {
            const selectedUser = userDetails.find((user) => user._id === _id);
            const data1 = {
              title: selectedUser.title,
              eventMaster:selectedUser.eventMaster,
              type:selectedUser.type,
              custom:selectedUser.custom,
              message: selectedUser.message,
            };
            console.log(data1)
            const response = await fetch(`${BASE_URL}/fcm/eventMaster`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the correct content type
                },
                body: JSON.stringify(data1), // Convert data1 to JSON
            });
            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchNotificationDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Notification Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the notification.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the notification.' });
        } finally {
            setSubmitting(false);
        }
    };

    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Notification Master</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='NotificationData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />

                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Notification</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
          <div className="form-group mb-2">
                            <label htmlFor="title">Title</label>
                            <div className="row">
                                <div className="col-12">
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        placeholder="Title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter title' },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="type">Type</label>
                            <Select
                                value={formData.type ? formData.type.value : null}
                                id="type"
                                name="type"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'type', value: selectedValue } });
                                }}
                                options={[
                                    { value: '', label: 'Select a Type' },
                                    { value: 'Event', label: 'Event' },
                                    { value: 'Custom', label: 'Custom' },
                                ]}
                                getOptionLabel={(option) => option.label}
                            />
                        </div>

                        {formData.type === 'Event' && (
                            <div className="form-group mb-2">
                                <label htmlFor="eventMaster">Event Name</label>
                                <Select
                                    value={formData.eventMaster ? formData.eventMaster.value : null}
                                    id="eventMaster"
                                    name="eventMaster"
                                    onChange={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : '';
                                        handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                    }}
                                    options={eventData
                                        .filter((event) => event.isActive === 1)
                                        .map((event) => ({
                                            value: event._id,
                                            label: event.eventName,
                                        }))
                                    }
                                    getOptionLabel={(option) => option.label}
                                />
                            </div>
                        )}

                        {formData.type === 'Custom' && (
                            <div className="form-group mb-2">
                                <label htmlFor="custom">Custom</label>
                                <div className="row">
                                    <div className="col-12">

                                        <AvField
                                            type="textarea"
                                            className="form-control"
                                            name="custom"
                                            placeholder="custom"
                                            value={formData.custom}
                                            onChange={handleInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your custom' },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="form-group mb-2">
                            <label htmlFor="message">Message</label>
                            <div className="row">
                                <div className="col-12">
                                    <AvField
                                        type="textarea"
                                        className="form-control"
                                        name="message"
                                        placeholder="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your message' },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <div className="prev-image-container">
                                        <img
                                            src="static/media/avatar-4.jpg"
                                            style={{ width: '50px', height: '70px' }}
                                            alt="Profile"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-2 mt-4">
                                    <label htmlFor="notificationImage" className="custom-file-label">
                                        Profile Picture
                                        <input
                                            type="file"
                                            className=" form-control"
                                           
                                            id="notificationImage"
                                            name="notificationImage"
                                            onChange={handleNotificationChange}
                                            style={{ width: '100%' }}

                                            required={formSubmitted && !selectedUser.notificationImage}
                                        />
                                        {formSubmitted && !selectedUser.notificationImage && (
                                            <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                        )}
                                    </label>
                                    {selectedUser.notificationImage && (
                                        <div className="text-success">File uploaded successfully.</div>
                                    )}
                                    <small className="form-text text-muted mt-2">
                                        Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                        image icon size: 300x300px.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>
                            <div className="form-group mb-2">
                                <label htmlFor="title">Title</label>
                                <div className="row">
                                    <div className="col-12">
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            placeholder="Title"
                                            value={selectedUser.title}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter title' },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="type">Type</label>
                                <Select
                                    value={formData.type ? formData.type.value : null}
                                    id="type"
                                    name="type"
                                    onChange={(selectedOption) => {
                                        const selectedValue = selectedOption ? selectedOption.value : '';
                                        handleEditInputChange({ target: { name: 'type', value: selectedValue } });
                                    }}
                                    options={[
                                        { value: '', label: 'Select a Type' },
                                        { value: 'Event', label: 'Event' },
                                        { value: 'Custom', label: 'Custom' },
                                    ]}
                                    getOptionLabel={(option) => option.label}
                                />
                            </div>

                            {formData.type === 'Event' && (
                                <div className="form-group mb-2">
                                    <label htmlFor="eventMaster">Event Name</label>
                                    <Select
                                        value={formData.eventMaster ? formData.eventMaster.value : null}
                                        id="eventMaster"
                                        name="eventMaster"
                                        onChange={(selectedOption) => {
                                            const selectedValue = selectedOption ? selectedOption.value : '';
                                            handleEditInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                        }}
                                        options={eventData
                                            .filter((event) => event.isActive === 1)
                                            .map((event) => ({
                                                value: event._id,
                                                label: event.eventName,
                                            }))
                                        }
                                        getOptionLabel={(option) => option.label}
                                    />
                                </div>
                            )}

                            {formData.type === 'Custom' && (
                                <div className="form-group mb-2">
                                    <label htmlFor="custom">Custom</label>
                                    <div className="row">
                                        <div className="col-12">

                                            <AvField
                                                type="textarea"
                                                className="form-control"
                                                name="custom"
                                                placeholder="custom"
                                                value={selectedUser.custom}
                                                onChange={handleEditInputChange}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Please enter your custom' },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="form-group mb-2">
                                <label htmlFor="message">Message</label>
                                <div className="row">
                                    <div className="col-12">
                                        <AvField
                                            type="textarea"
                                            className="form-control"
                                            name="message"
                                            placeholder="message"
                                            value={selectedUser.message}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your message' },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                                        <div className="prev-image-container">
                                            <img
                                                src="static/media/avatar-4.jpg"
                                                style={{ width: '50px', height: '70px' }}
                                                alt="Profile"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-9 mb-2 mt-4">
                                        <label htmlFor="notificationImage" className="custom-file-label">
                                            Notification Image
                                            <input
                                                type="file"
                                                className=" form-control"
                                           
                                                id="notificationImage"
                                                name="notificationImage"
                                                onChange={handleNotificationChange}
                                                style={{ width: '100%' }}

                                                required={formSubmitted && !selectedUser.notificationImage}
                                            />
                                            {formSubmitted && !selectedUser.notificationImage && (
                                                <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                            )}
                                        </label>
                                        {selectedUser.notificationImage && (
                                            <div className="text-success">File uploaded successfully.</div>
                                        )}
                                        <small className="form-text text-muted mt-2">
                                            Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                            image icon size: 300x300px.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>

                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Message</ModalHeader>
                <ModalBody>
                    <p>{selectedRowAboutUs}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default NotificationMaster;
