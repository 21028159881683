import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { BASE_URL, useJwtToken } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import ActionsDropdown from '../../components/Common/DropDown';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Select from "react-select";
import { useSelector } from 'react-redux';
function AgendaMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [userTagDetails, setUserTagDetails] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [errorMessageModel, setErrorMessageModel] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedRowAboutUs, setSelectedRowAboutUs] = useState('');
    const [speakerData, setSpeakerData] = useState([]);
    const [selectedEventData, setSelectedEventData] = useState(null);
    const [eventStartDate, setEventStartDate] = useState(null);
    const [eventEndDate, setEventEndDate] = useState(null);
    const [isModalOpenVenue, setIsModalOpenVenue] = useState(false);
    const [selectedRowDescription, setSelectedRowDescription] = useState('');
    const [selectedRowVenue, setSelectedRowVenue] = useState('');

    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);
    const token = useJwtToken();

    const handleOpenModal = (description) => {
        setSelectedRowDescription(description);
        setIsModalOpen(true);
    };
    const handleOpenModalVenue = (row) => {
        setSelectedRowVenue(row.venue);
        setIsModalOpenVenue(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            eventMaster: '',
            agendaData: [
                {
                    title: '',
                    eventDate: '',
                    startTime: '',
                    endTime: '',
                    speakerMaster: '',
                    description: '',
                    isBookMarked: '',
                },
            ],
            venue: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        eventMaster: '',
        agendaData: [
            {
                title: '',
                eventDate: '',
                startTime: '',
                endTime: '',
                speakerMaster: '',
                description: '',
                isBookMarked: '',
            },
        ],
        venue: '',

    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleAdd = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setSubmitting(true);
        // formData.agendaData.forEach((agenda, index) => {
        //     validateeventDate(agenda.eventDate);
        //     validateEndDate(agenda.endDate, agenda.eventDate);
        // });

        try {
            const requestBody = JSON.stringify({
                eventMaster: formData.eventMaster,
                agendaData: formData.agendaData,
                venue: formData.venue,
            });

            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };

            const response = await fetch(`${BASE_URL}/agenda`, {
                method: 'POST',
                body: requestBody,
                headers: headers,
            });




            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    eventMaster: '',
                    agendaData: [
                        {
                            title: '',
                            eventDate: '',
                            startTime: '',
                            endTime: '',
                            speakerMaster: '',
                            description: '',
                            isBookMarked: '',
                        },
                    ],
                    venue: '',
                });
                toggleAddModal();
                fetchAgendaDetails();
                history.push('/agenda');
                setAlertMessage({ success: 'Speaker added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the speaker.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the speaker.' });
        } finally {
            setSubmitting(false);
        }
    };

    const fetchEventData = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/event`, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();
                if (userRole === 'user') {
                    const filteredData = data.data.filter(event => event.organiserMaster === userId);
                    setEventData(filteredData);
                } else {
                    setEventData(data.data);
                }

            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    const fetchSpeakerData = async () => {
        try {

            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/speaker`, {
                method: 'GET',
                headers: headers,
            });
            if (response.ok) {
                const data = await response.json();

                if (userRole === 'user') {
                    const filteredData = data.data.filter(speaker => {
                        return speaker.eventDetails && speaker.eventDetails.organiserMaster === userId;
                    });
                    setSpeakerData(filteredData);
                } else {
                    setSpeakerData(data.data);
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch speaker details' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching speaker data' });
            console.error(error);
        }
    };

    useEffect(() => {
        fetchAgendaDetails();
        fetchEventData();
        fetchSpeakerData();
        latestDateRange();

    }, []);
    const fetchAgendaDetails = async () => {
        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(`${BASE_URL}/agenda`, {
                method: 'GET',
                headers: headers,
            });
            const data = await response.json();
            if (response.ok) {

                if (userRole === 'user') {
                    const filteredData = data.data.filter(agenda => {
                        return agenda.eventDetails.some(event => event.organiserMaster === userId);
                    });
                    const dataWithSerialNo = filteredData.map((item, index) => ({ ...item, srNo: index + 1 }));
          
                    setUserDetails(dataWithSerialNo);
                } else {
                    const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
          
                    setUserDetails(dataWithSerialNo);
                    console.log(dataWithSerialNo)
                }
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const handleDeleteClick = (_id) => {
        const Page_Url = `${BASE_URL}/agenda/${_id}`;
        handleDelete(_id, Page_Url, fetchAgendaDetails, setAlertMessage, token);
    };


    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/agenda/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Agenda Successfully';
        const errorMessageProp = 'Failed to Activate Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchAgendaDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/agenda/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Agenda Successfully';
        const errorMessageProp = 'Failed to Deactivate Agenda';
        handleStatusChange(isActive, _id, Page_Url, fetchAgendaDetails, successMessageProp, errorMessageProp, setAlertMessage, token);
    };


    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };
    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
            showEdit={true}
        />
    );
    const filteredData = userDetails.filter((user) => {
        const { _id, eventDetails, title, eventDate, endTime, startTime, fname, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();
        const eventName = eventDetails && eventDetails[0] && eventDetails[0].eventName;
        const eventNameLowercased = eventName ? eventName.toLowerCase().trim() : '';
      
        return (
          (_id && _id.toString().includes(lowercasedSearchText)) ||
          (eventNameLowercased && eventNameLowercased.includes(lowercasedSearchText)) ||
          (title && title.toLowerCase().includes(lowercasedSearchText)) ||
          ((fname && lname) && (fname + lname).toLowerCase().includes(lowercasedSearchText)) ||
          (eventDate && eventDate.toLowerCase().includes(lowercasedSearchText)) ||
          (startTime && startTime.toLowerCase().includes(lowercasedSearchText)) ||
          (endTime && endTime.toLowerCase().includes(lowercasedSearchText))
        );
      });
      
      
      
    const styles = {
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            margin: '25px'
        },
        cell: {
            padding: '10px',
            textAlign: 'left',
            border: '1px solid #ddd',
            width: '150px'

        },
        headerCell: {
            padding: '10px',
            textAlign: 'left',
            border: '1px solid #ddd',

            fontWeight: 'bold',
        },
    };
    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },

        {
            name: 'Event Name',
            selector: (row) => {
                const event = eventData.find((event) => event._id === row.eventMaster);
                return event ? event.eventName : '';
            },
            sortable: true,
            width:'250px'
        },
        {
            name: 'Agenda',
            cell: (row) => {
                const fieldsData = row.agendaData.map((agenda) => {
                    const { title, eventDate, startTime, endTime, speakerMaster, description } = agenda;
                    const speaker = speakerData.find((speaker) => speaker._id === speakerMaster);
                    const speakerName = speaker ? speaker.fname + ' ' + speaker.lname : '';

                    return (
                        <tr key={title}>
                            <td style={{ ...styles.cell, textAlign: 'center' }}>{title}</td>
                            <td style={{ ...styles.cell, textAlign: 'center' }}>{eventDate}</td>
                            <td style={{ ...styles.cell, textAlign: 'center' }}>{startTime}</td>
                            <td style={{ ...styles.cell, textAlign: 'center' }}>{endTime}</td>
                            <td style={{ ...styles.cell, textAlign: 'center' }}>{speakerName}</td>
                            <td style={{ ...styles.cell, textAlign: 'center' }}>
                                <button
                                    className='btn btn-primary btn-sm m-2'
                                    onClick={() => handleOpenModal(agenda.description)}
                                >
                                    View Description
                                </button>

                            </td>
                        </tr>
                    );
                });

                return (
                    <div>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }} colSpan={6}>
                                        Agenda Details
                                    </th>
                                </tr>
                                <tr>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }}>Title</th>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }}>Event Date</th>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }}>Start Time</th>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }}>End Time</th>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }}>Speaker Name</th>
                                    <th style={{ ...styles.headerCell, textAlign: 'center' }}>Description</th>
                                </tr>
                            </thead>
                            <tbody>{fieldsData}</tbody>
                        </table>
                    </div>
                );
            },
            sortable: true,
            width: '1050px',
            center: true,

        },
        {
            name: 'Venue',
            cell: (row) => (
                <button className='btn btn-primary btn-sm' onClick={() => handleOpenModalVenue(row)}>View Venue</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            marginLeft: '50px'
        },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id);
            setSelectedUser(null);
            toggleEditModal();
        }
    };


    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };
    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {

            const requestBody = JSON.stringify({
                eventMaster: selectedUser.eventMaster,
                agendaData: selectedUser.agendaData,
                venue: selectedUser.venue,
            });

            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };

            const response = await fetch(`${BASE_URL}/agenda/${selectedUser._id}`, {
                method: 'PUT',
                body: requestBody,
                headers: headers,
            });


            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchAgendaDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Event Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the event.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the event.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleAddAgendaData = () => {
        setFormData((prevData) => ({
            ...prevData,
            agendaData: [
                ...prevData.agendaData,
                {
                    title: '',
                    eventDate: '',
                    startTime: '',
                    endTime: '',
                    speakerMaster: '',
                    description: '',
                    isBookMarked: '',
                },
            ],
        }));
    };

    const handleRemoveagendaData = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            agendaData: prevData.agendaData.filter((_, i) => i !== index),
        }));
    };
    const latestDateRange = async () => {
        const selectedEvent = eventData.find((event) => event._id === selectedEventData);

        if (selectedEvent) {
            setEventStartDate(selectedEvent.startDate);
            setEventEndDate(selectedEvent.endDate);
            console.log(eventStartDate)
        } else {
            // Handle the case where no matching event is found.
            // You can display an error message or take appropriate action.
        }
    }


    // Define state variables
    const [isInvalideventDate, setIsInvalideventDate] = useState(false);
    const [isInvalidStartTime, setIsInvalidStartTime] = useState(false);
    const [isInvalidEndTime, setIsInvalidEndTime] = useState(false);
    const [isInvalidEndDate, setIsInvalidEndDate] = useState(false);
    const handleEventDateChange = (index, selectedDate) => {
        const formattedEventDate = new Date(selectedDate[0]).toISOString().split('T')[0];


        setFormData((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].eventDate = formattedEventDate;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });

    };

    const handleSpeakerMasterChange = (index, speaker) => {
        const formattedSpeaker = speaker;

        setFormData((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].speakerMaster = formattedSpeaker;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });
    };


    const handleEventStartTimeChange = (index, selectedDate) => {
        const formattedEventTime = selectedDate[0].toLocaleTimeString('en-US', { hour12: false });
    
        setFormData((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].startTime = formattedEventTime;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });
    };
    
    const handleEventEndTimeChange = (index, selectedDate) => {
        const formattedEventTime = selectedDate[0].toLocaleTimeString('en-US', { hour12: false });

        setFormData((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].endTime = formattedEventTime;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });
    };



    const handleEditAgendaData = (index, updatedAgenda) => {
        console.log('Updated Experience:', updatedAgenda);
        setSelectedUser((prevData) => {
            const updatedagendaData = [...prevData.agendaData];
            updatedagendaData[index] = updatedAgenda;
            return { ...prevData, agendaData: updatedagendaData };
        });
    };

    const handleAddAgendaDataEdit = () => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            agendaData: [
                ...prevUser.agendaData,
                {
                    title: '',
                    eventDate: '',
                    startTime: '',
                    endTime: '',
                    speakerMaster: '',
                    description: '',
                    isBookMarked: '',
                },
            ],
        }));
    };

    const handleEditEventDateChange = (index, selectedDate) => {
        const formattedEventDate = new Date(selectedDate[0]).toISOString().split('T')[0];


        setSelectedUser((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].eventDate = formattedEventDate;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });

    };

    const handleEditSpeakerMasterChange = (index, speaker) => {
        const formattedSpeaker = speaker;

        setSelectedUser((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].speakerMaster = formattedSpeaker;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });
    };


    const handleEditEventStartTimeChange = (index, selectedDate) => {
        const formattedEventTime = selectedDate[0].toLocaleTimeString('en-US', { hour12: false });

        setSelectedUser((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].startTime = formattedEventTime;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });
    };

    const handleEditEventEndTimeChange = (index, selectedDate) => {
        const formattedEventTime = selectedDate[0].toLocaleTimeString('en-US', { hour12: false });
        setSelectedUser((prevData) => {
            const updatedAgendaData = [...prevData.agendaData];
            if (updatedAgendaData[index]) {
                updatedAgendaData[index].endTime = formattedEventTime;
            }
            return { ...prevData, agendaData: updatedAgendaData };
        });
    };
    const handleRemoveagendaDataEdit = (index) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            agendaData: prevUser.agendaData.filter((_, i) => i !== index),
        }));
    };




    const handleCloseModalVenue = () => {
        setIsModalOpenVenue(false);
    };
    const selectedEvent = eventData.find((event) => event._id === selectedUser.eventMaster);
    const labelToDisplay = selectedEvent ? selectedEvent.eventName : '';

    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Agenda Details</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-3'>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='SpeakerData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal} backdrop="static">
                <ModalHeader toggle={toggleAddModal}>Add Agenda</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>
                        <div className="form-group mb-2">
                            <label htmlFor="eventMaster">Event Name</label>
                            <Select
                                value={formData.eventMaster ? formData.eventMaster.value : null}
                                id="eventMaster"
                                name="eventMaster"
                                onChange={(selectedOption) => {
                                    const selectedValue = selectedOption ? selectedOption.value : '';
                                    handleInputChange({ target: { name: 'eventMaster', value: selectedValue } });
                                    setSelectedEventData(selectedValue)

                                }}
                                options={eventData
                                    .filter((event) => event.isActive === 1)
                                    .map((event) => ({
                                        value: event._id,
                                        label: event.eventName,
                                    }))
                                }
                                getOptionLabel={(option) => option.label}
                            />
                        </div>

                        <div className="form-group mt-4 d-flex flex-column align-items-center justify-content-center">
                            <label className="work-experience-label">Agenda</label>
                        </div>
                        {Array.isArray(formData.agendaData) && formData.agendaData.map((agenda, index) => (
                            <div key={index}>
                                <div className="form-group mb-2">

                                    <AvField
                                        type="text"
                                        name={`title-${index}`} // Unique name for each field
                                        value={agenda.title}
                                        onChange={(e) =>
                                            setFormData((prevData) => {
                                                const updatedagendaData = [...prevData.agendaData];
                                                updatedagendaData[index].title = e.target.value;
                                                return { ...prevData, agendaData: updatedagendaData };
                                            })
                                        }
                                        label="Title"
                                        placeholder="Enter Title"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor={`eventDate${index}`}>Start Date</label>
                                    <Flatpickr
                                        className={`form-control ${isInvalideventDate ? 'is-invalid' : ''}`}
                                        value={agenda.eventDate}
                                        onChange={(value) => {
                                            handleEventDateChange(index, value);
                                        }}
                                        options={{
                                            dateFormat: 'Y-m-d',
                                            minDate: eventStartDate,
                                            maxDate: eventEndDate,
                                        }}
                                        placeholder="Start Date"
                                    />
                                    {isInvalideventDate && (
                                        <div className="invalid-feedback">Please select a valid Start Date.</div>
                                    )}
                                </div>


                                <div className="row"><div className="col-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor={`startTime${index}`}>Event Start Time</label>
                                        <Flatpickr
                                            className={`form-control d-block  ${isInvalidStartTime ? 'is-invalid' : ''}`}
                                            options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: 'H:i:S',
                                                time_24hr: true, 
                                            }}
                                            placeholder="Event Start Time"
                                            value={agenda.startTime}
                                            onChange={(value) => {
                                                handleEventStartTimeChange(index, value);
                                            }}
                                        />
                                        {isInvalidStartTime && (
                                            <div className="invalid-feedback">Please select a valid Start Time.</div>
                                        )}
                                    </div>
                                </div>
                                    <div className="col-6">
                                        <div className="form-group mb-2">
                                            <label htmlFor={`endTime${index}`}>Event End Time</label>
                                            <Flatpickr
                                                className={`form-control d-block ${isInvalidEndTime ? 'is-invalid' : ''}`}
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: 'H:i:S',
                                                    time_24hr: true, 
                                                }}
                                                placeholder="Event End Time"
                                                value={agenda.endTime}
                                                onChange={(value) => {
                                                    handleEventEndTimeChange(index, value);
                                                }} validate={{
                                                    required: { value: true, errorMessage: 'Please select an Event end time' },
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <label htmlFor={`speakerMaster${index}`}>Speaker Name</label>
                                    <Select
                                        value={formData.speakerMaster ? formData.speakerMaster.value : null}
                                        id="speakerMaster"
                                        name="speakerMaster"
                                        onChange={(selectedOption) => {
                                            const selectedValue = selectedOption ? selectedOption.value : '';
                                            handleInputChange({ target: { name: 'speakerMaster', value: selectedValue } });
                                            handleSpeakerMasterChange(index, selectedValue);
                                        }}
                                        options={speakerData
                                            .filter((speaker) => speaker.isActive === 1)
                                            .map((speaker) => ({
                                                value: speaker._id,
                                                label: speaker.fname + ' '+ speaker.lname,
                                            }))
                                        }
                                        getOptionLabel={(option) => option.label}
                                    />
                                </div>
                                <div className="form-group mb-2">

                                    <AvField
                                        type="textarea"
                                        name={`description-${index}`} // Unique name for each field
                                        value={agenda.description}
                                        onChange={(e) =>
                                            setFormData((prevData) => {
                                                const updatedagendaData = [...prevData.agendaData];
                                                updatedagendaData[index].description = e.target.value;
                                                return { ...prevData, agendaData: updatedagendaData };
                                            })
                                        }
                                        label="Short Description"
                                        placeholder="Enter Short Description"
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-secondary form-group mt-2 mb-2" onClick={() => handleRemoveagendaData(index)}>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary form-group mb-2 mt-2" type="button" onClick={handleAddAgendaData}>
                                Add Agenda
                            </button>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="bio">Venue</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="venue"
                                placeholder="Venue"
                                value={formData.venue}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Bio' },
                                }}
                            />
                        </div>
                        <ModalFooter>

                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal} backdrop="static">
                    <ModalHeader toggle={toggleEditModal}>Edit Agenda</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>

                            <div className="form-group mb-2">
                                <label htmlFor="eventMaster">Event Name</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    name="eventMaster"
                                    placeholder="Select Role"
                                    value={{ value: selectedUser.eventMaster, label: labelToDisplay }}
                                    options={eventData
                                        .filter((event) => event.isActive === 1)
                                        .map((event) => ({
                                            value: event._id,
                                            label: event.eventName,
                                        }))
                                    }
                                    onChange={(selectedOption) => {
                                        setFormData({ ...formData, eventMaster: selectedOption ? selectedOption.value : '' });
                                        setSelectedUser({ ...selectedUser, eventMaster: selectedOption ? selectedOption.value : '' });
                                    }}
                                />
                            </div>


                            <div className="form-group mt-4 d-flex flex-column align-items-center justify-content-center">
                                <label className="work-experience-label">Agenda</label>
                            </div>
                            {Array.isArray(selectedUser.agendaData) && selectedUser.agendaData.map((agenda, index) => (
                                <div key={index}>
                                    <div className="form-group mb-2">

                                        <AvField
                                            type="text"
                                            name={`title-${index}`}
                                            value={agenda.title}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const updatedAgenda = {
                                                    ...agenda,
                                                    title: newValue,
                                                };
                                                handleEditAgendaData(index, updatedAgenda);
                                            }}
                                            label="Title"
                                            placeholder="Enter Agenda Title"
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor={`eventDate${index}`}>Start Date</label>
                                        <Flatpickr
                                            className={`form-control ${isInvalideventDate ? 'is-invalid' : ''}`}
                                            value={agenda.eventDate}
                                            onChange={(value) => {
                                                handleEditEventDateChange(index, value);
                                            }}
                                            options={{
                                                dateFormat: 'Y-m-d',
                                            }}
                                            placeholder="Start Date"
                                        />
                                        {isInvalideventDate && (
                                            <div className="invalid-feedback">Please select a valid Start Date.</div>
                                        )}
                                    </div>

                                    <div className="row"><div className="col-6">
                                        <div className="form-group mb-2">
                                            <label htmlFor={`startTime${index}`}>Event Start Time</label>
                                            <Flatpickr
                                                className={`form-control d-block  ${isInvalidStartTime ? 'is-invalid' : ''}`}
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: 'H:i:S',
                                                    time_24hr: true, 
                                                }}
                                                placeholder="Event Start Time"
                                                value={agenda.startTime}
                                                onChange={(value) => {
                                                    handleEditEventStartTimeChange(index, value);
                                                }}
                                            />
                                            {isInvalidStartTime && (
                                                <div className="invalid-feedback">Please select a valid Start Time.</div>
                                            )}
                                        </div>
                                    </div>
                                        <div className="col-6">
                                            <div className="form-group mb-2">
                                                <label htmlFor={`endTime${index}`}>Event End Time</label>
                                                <Flatpickr
                                                    className={`form-control d-block ${isInvalidEndTime ? 'is-invalid' : ''}`}
                                                    options={{
                                                        enableTime: true,
                                                        noCalendar: true,
                                                        dateFormat: 'H:i:S',
                                                        time_24hr: true, 
                                                    }}
                                                    placeholder="Event End Time"
                                                    value={agenda.endTime}
                                                    onChange={(value) => {
                                                        handleEditEventEndTimeChange(index, value);
                                                    }} validate={{
                                                        required: { value: true, errorMessage: 'Please select an Event end time' },
                                                    }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor={`speakerMaster${index}`}>Speaker Name</label>
                                        <Select
                                            value={agenda.speakerMaster ? agenda.speakerMaster.value : null}
                                            id="speakerMaster"
                                            name="speakerMaster"
                                            onChange={(selectedOption) => {
                                                const selectedValue = selectedOption ? selectedOption.value : '';
                                                handleInputChange({ target: { name: 'speakerMaster', value: selectedValue } });
                                                handleEditSpeakerMasterChange(index, selectedValue);
                                            }}
                                            options={speakerData
                                                .filter((speaker) => speaker.isActive === 1)
                                                .map((speaker) => ({
                                                    value: speaker._id,
                                                    label: speaker.fname +' ' +speaker.lname,
                                                }))
                                            }
                                            getOptionLabel={(option) => option.label}
                                        />
                                    </div>

                                    <div className="form-group mb-2">

                                        <AvField
                                            type="text"
                                            name={`description-${index}`}
                                            value={agenda.description}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                const updatedAgenda = {
                                                    ...agenda,
                                                    description: newValue,
                                                };
                                                handleEditAgendaData(index, updatedAgenda);
                                            }}
                                            label="Description"
                                            placeholder="Enter Agenda Description"
                                            required
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="button" className="btn btn-secondary form-group mt-2 mb-2" onClick={() => handleRemoveagendaDataEdit(index)}>
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary form-group mb-2 mt-2" type="button" onClick={handleAddAgendaDataEdit}>
                                    Add Agenda
                                </button>
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="bio">Venue</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="venue"
                                    placeholder="Venue"
                                    value={selectedUser.venue}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Bio' },
                                    }}
                                />
                            </div>
                            <ModalFooter>
                                <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />

                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>

                </Modal>
            )}

            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Description</ModalHeader>
                <ModalBody>
                    <p>{selectedRowDescription}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>

            <Modal isOpen={isModalOpenVenue} toggle={handleCloseModalVenue}>
                <ModalHeader toggle={handleCloseModalVenue}>Description</ModalHeader>
                <ModalBody>
                    <p>{selectedRowVenue}</p>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModalVenue}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default AgendaMaster;
